import img1 from '../../../assets/images/imersao-jose/jose-3.png'

const CtaSection = () => {
    return(
        <div className="container-fluid ptb--50" id="cta-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h3 className='title-imersao'>Faça sua inscrição!</h3>
                        <p className='text-dark'>Não perca esta oportunidade de elevar suas habilidades de comunicação e impulsionar sua carreira! Inscreva-se agora na Imersão "Os 4 Segredos de uma Comunicação de Sucesso".</p>
                        <a href='https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/4-segredos-de-uma-comunicacao-de-sucesso-com-jose-bergossi'>
                            <div className='cta-btn text-center'>INSCREVA-SE AGORA</div>
                        </a>
                    </div>
                    <div className='col-12 col-md-6 d-none d-md-grid'>
                        <div className='img-box'>
                            <img src={img1} alt='imagem cta' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CtaSection;