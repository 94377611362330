import { Link } from "react-router-dom";
import img1 from '../../assets/images/icons/case.svg'
import img2 from '../../assets/images/icons/bullseye.svg'
import img3 from '../../assets/images/icons/ranking.svg'
import img4 from '../../assets/images/icons/seta-r.svg'

export default function CallToActionThree(){
  return(
    <div className="container">
      <div className="row d-flex justify-content-md-between justify-content-center  my-3">

        <Link to={process.env.PUBLIC_URL + "/para-seu-intercambio"} className="cta-btn-three row col-12 mb-3 col-md-3 bg-primary" >
          <div className="col-3 h-100 d-flex align-items-center justify-content-center">
            <img src={img1} alt="icon" />
          </div>
          <div className="col h-100 d-flex flex-column align-items-center justify-content-center">
            <p className="text-white fs-4 fw-light mb-0">Academy para</p>
            <h6 className="text-white mb-0">Intercâmbio</h6>
          </div>
          <div className="col-2 h-100 d-flex align-items-center justify-content-center">
            <img src={img4} alt="icon" />
          </div>
        </Link>

        <Link to={process.env.PUBLIC_URL + "/para-sua-carreira"} className="cta-btn-three row col-12 mb-3 col-md-3 bg-primary" >
          <div className="col-3 h-100 d-flex align-items-center justify-content-center">
            <img src={img2} alt="icon" />
          </div>
          <div className="col h-100 d-flex flex-column align-items-center justify-content-center">
            <p className="text-white fs-4 fw-light mb-0">Academy para</p>
            <h6 className="text-white mb-0">Minha carreira</h6>
          </div>
          <div className="col-2 h-100 d-flex align-items-center justify-content-center">
            <img src={img4} alt="icon" />
          </div>

        </Link>

        <Link to={process.env.PUBLIC_URL + "/para-seu-negocio"} className="cta-btn-three row col-12 mb-3 col-md-3 bg-primary" >
          <div className="col-3 h-100 d-flex align-items-center justify-content-center">
            <img src={img3} alt="icon" />
          </div>
          <div className="col h-100 d-flex flex-column align-items-center justify-content-center">
            <p className="text-white fs-4 fw-light mb-0">Academy para</p>
            <h6 className="text-white mb-0">Meu negócio</h6>
          </div>
          <div className="col-2 h-100 d-flex align-items-center justify-content-center">
            <img src={img4} alt="icon" />
          </div>
        </Link>
      </div>
    </div>
  )
}