import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import CourseTypeOne from './CourseTypeOne';
import CourseData from '../../data/course/CourseData.json';
import axios from 'axios';
import CourseTypeOneAcademy from './CourseTypeOneAcademy';
import CardMentor from './CardMentor';
import EventOne from '../../components/event/EventOne';
import EventData from '../../data/event/EventData.json';
import EventOneIntercambio from '../event/EventOneIntercambio';
import { MultiSelect } from "react-multi-select-component";

const EditaisFilter = ( { itemToShow, showLoadMore, incrementPerClick, cursos } ) => {
    const FilterControls = [...new Set( CourseData.map( item => item.filterParam ) ) ];
    FilterControls.unshift( 'All' );

    const options = [
        { label: "Grapes 🍇", value: "grapes" },
        { label: "Mango 🥭", value: "mango" },
        { label: "Strawberry 🍓", value: "strawberry", disabled: true },
      ];

    const EventItems = EventData.slice(0, 6);
    
    const [especialidade_filter, setEspecialidadeFilter] = useState([]);
    const [categoria_filter, setCategoriaFilter] = useState([]);
    const [instituicao_filter, setInstituicaoFilter] = useState([]);
    const [pais_filter, setPaisFilter] = useState([]);
    const [activeFilter, setActiveFilter] = useState( 'all' );
    const [visibleItems, setVisibleItems] = useState( [] );
    const [dataRaw, setDataRaw] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [paises, setPaises] = useState([]);
    const [instituicoes, setInstituicoes] = useState([]);

    useEffect( () => {
      
        axios.get('https://api.academyabroad.com.br/api/site/intercambios')
            .then(response => {
                setVisibleItems(response.data.data);
                setDataRaw(response.data.data);
                let especialidades = [];
                let categorias = [];
                let instituicoes = [];
                let paises = [];

                for (let i = 0; i < response.data.data.length; i++) {
                    const element = response.data.data[i];
                    if(especialidades.find( ( cat ) => cat.id == element.especialidade ) == undefined && element.especialidade != null){
                        especialidades.push({id: element.especialidade, nome: element.especialidade, label: element.especialidade, value: element.especialidade});
                    }
                    if(instituicoes.find( ( cat ) => cat.id == element.instituicao ) == undefined && element.instituicao != null){
                        instituicoes.push({id: element.instituicao, nome: element.instituicao, label: element.instituicao, value: element.instituicao});
                    }
                    if(paises.find( ( cat ) => cat.id == element.pais ) == undefined && element.pais != null){
                        paises.push({id: element.pais, nome: element.pais, label: element.pais, value: element.pais});
                    }
                    for (let index = 0; index < element.tags.length; index++) {
                        const tag = element.tags[index];
                        console.log(tag)
                        if(categorias.find( ( cat ) => cat.id == tag ) == undefined && tag != null){
                            categorias.push({id: tag, nome: tag, label: tag, value: tag});
                        }
                    }
                }
                setCategorias(categorias);
                setEspecialidades(especialidades);
                setInstituicoes(instituicoes);
                setPaises(paises);
            })
            .catch(error => {
                console.error(error);
            });

    }, [] );

    useEffect( () => {
        let data = dataRaw;

        if(instituicao_filter.length){
            data = data.filter( ( item ) => {
                return instituicao_filter.find( ( cat ) => cat.id == item.instituicao );
            } );
        }

        if(categoria_filter.length){
            data = data.filter( ( item ) => {
                return categoria_filter.find( ( cat ) => item.tags.includes(cat.id) );
            } );
        }

        if(especialidade_filter.length){
            data = data.filter( ( item ) => {
                return especialidade_filter.find( ( cat ) => cat.id == item.especialidade );
            } );
        }

        if(pais_filter.length){
            data = data.filter( ( item ) => {
                return pais_filter.find( ( cat ) => cat.id == item.pais );
            } );
        }

        setVisibleItems( data );
        
    }, [
        categoria_filter,
        especialidade_filter,
        instituicao_filter,
        pais_filter
    ] );



    return (
        <>
            <div className="row g-5 align-items-center mb--45 mb--30 mt_md--30 mt_sm--30">
                <div className="col-lg-5">
                    <div className="section-title text-start">
                        {/* <span className="pre-title">Mentores</span> */}
                        <h3 className="title">Editais de intercâmbio</h3>
                    </div>
                </div>
                {/* <div className="col-lg-7" >
                    <div className="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end">
                        <button
                            onClick={()=>{handleChange('all')}}
                            className={
                                'all' === activeFilter
                                ? "is-checked"
                                : " "
                            }
                        >
                            Todos
                        </button>
                        {categorias.map( ( filter, i ) => (
                            <button
                                onClick={()=>{handleChange(filter.id)}}
                                key={i}
                                className={
                                    filter.id === activeFilter
                                    ? "is-checked"
                                    : " "
                                }
                            >
                                {filter.nome}
                            </button>
                        ))}
                    </div>
                </div> */}
                
            </div>

            <div className="row g-5">
                <div className="col-lg-9" >
                { 
                    visibleItems.map((item,i ) => (
                        <EventOneIntercambio className="mb-4" data={item} key={i} />
                    ) )
                }
                </div>
                <div className="col-lg-3">
                    <aside className="edu-course-sidebar">
                        <form onSubmit={ (e) => e.preventDefault() }>

                            <div className="edu-course-widget widget-shortby" style={{padding:'14px'}}>
                                <div className="inner">
                                    <h5 className="widget-title" style={{marginBottom: '12px', paddingBottom: '12px'}}>Categoria</h5>
                                    <div className="content" style={{marginTop:'12px'}}>
                                        <div>
                                            <MultiSelect
                                                options={categorias}
                                                value={categoria_filter}
                                                hasSelectAll={false}
                                                overrideStrings={{
                                                "allItemsAreSelected": "Todos os itens estão selecionados.",
                                                "clearSearch": "Limpar pesquisa",
                                                "clearSelected": "Limpar selecionados",
                                                "noOptions": "Nenhuma opção",
                                                "search": "Buscar",
                                                "selectAll": "Selecionar Todos",
                                                "selectAllFiltered": "Selecionar todos filtrados",
                                                "selectSomeItems": "Filtrar",
                                                "create": "Criar",
                                                }}
                                                onChange={(e)=>{setCategoriaFilter(e)}}
                                                labelledBy="Categorias"
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="edu-course-widget widget-shortby" style={{padding:'14px', marginTop:'28px'}}>
                                <div className="inner">
                                    <h5 className="widget-title" style={{marginBottom: '12px', paddingBottom: '12px'}}>Instituição</h5>
                                    <div className="content" style={{marginTop:'12px'}}>
                                        <div>
                                            <MultiSelect
                                                options={instituicoes}
                                                value={instituicao_filter}
                                                hasSelectAll={false}
                                                overrideStrings={{
                                                "allItemsAreSelected": "Todos os itens estão selecionados.",
                                                "clearSearch": "Limpar pesquisa",
                                                "clearSelected": "Limpar selecionados",
                                                "noOptions": "Nenhuma opção",
                                                "search": "Buscar",
                                                "selectAll": "Selecionar Todos",
                                                "selectAllFiltered": "Selecionar todos filtrados",
                                                "selectSomeItems": "Filtrar",
                                                "create": "Criar",
                                                }}
                                                onChange={(e)=>{setInstituicaoFilter(e)}}
                                                labelledBy="Instituições"
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="edu-course-widget widget-shortby" style={{padding:'14px', marginTop:'28px'}}>
                                <div className="inner">
                                    <h5 className="widget-title" style={{marginBottom: '12px', paddingBottom: '12px'}}>Especialidade</h5>
                                    <div className="content" style={{marginTop:'12px'}}>
                                        <div>
                                            <MultiSelect
                                                options={especialidades}
                                                value={especialidade_filter}
                                                hasSelectAll={false}
                                                overrideStrings={{
                                                "allItemsAreSelected": "Todos os itens estão selecionados.",
                                                "clearSearch": "Limpar pesquisa",
                                                "clearSelected": "Limpar selecionados",
                                                "noOptions": "Nenhuma opção",
                                                "search": "Buscar",
                                                "selectAll": "Selecionar Todos",
                                                "selectAllFiltered": "Selecionar todos filtrados",
                                                "selectSomeItems": "Filtrar",
                                                "create": "Criar",
                                                }}
                                                onChange={(e)=>{setEspecialidadeFilter(e)}}
                                                labelledBy="Especialidades"
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="edu-course-widget widget-shortby" style={{padding:'14px', marginTop:'28px'}}>
                                <div className="inner">
                                    <h5 className="widget-title" style={{marginBottom: '12px', paddingBottom: '12px'}}>País</h5>
                                    <div className="content" style={{marginTop:'12px'}}>
                                        <div>
                                            <MultiSelect
                                                options={paises}
                                                value={pais_filter}
                                                hasSelectAll={false}
                                                overrideStrings={{
                                                "allItemsAreSelected": "Todos os itens estão selecionados.",
                                                "clearSearch": "Limpar pesquisa",
                                                "clearSelected": "Limpar selecionados",
                                                "noOptions": "Nenhuma opção",
                                                "search": "Buscar",
                                                "selectAll": "Selecionar Todos",
                                                "selectAllFiltered": "Selecionar todos filtrados",
                                                "selectSomeItems": "Filtrar",
                                                "create": "Criar",
                                                }}
                                                onChange={(e)=>{setPaisFilter(e)}}
                                                labelledBy="Países"
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                        </form>

                        
                    </aside>
                </div>
            </div>

          
        </>
    )
}

export default EditaisFilter;