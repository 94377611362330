import React from 'react';
import { Link } from 'react-router-dom';
import PostData from '../../../data/blog/PostData.json';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';

const LatestPostOne = ( props ) => {

    const styleLink = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        minHeight: '59px',
        lineHeight: '31px'
    };

    const [posts, setPosts] = useState([]);

    useEffect( () => {
        axios.get('https://api.academyabroad.com.br/api/site/artigos')
            .then(response => {

                setPosts(response.data.data.sort((a,b) =>{
                    const dataA = new Date(a.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                    const dataB = new Date(b.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                    return dataB - dataA
                }).filter(item=>item.uuid !== props.uuid));

                console.log(posts)
            })
            .catch(error => {
                console.error(error);
            });
    },[props.uuid]);

    

    return (
        <div className={`edu-blog-widget${ props.style2 === 'enable' ? '-2' : '' } widget-latest-post ${ props.extraClass || '' }`}>
            <div className="inner">
                <h5 className="widget-title">Últimos posts</h5>
                <div className="content latest-post-list">
                    {
                        posts.slice( 0, 3 ).map( ( item, index ) => {
                            return(
                                <div className="latest-post" key={ index }>
                                    <div className="thumbnail">
                                        <Link className="d-block" to={process.env.PUBLIC_URL + `/blog/${item.slug}`}>
                                            <img src={`${item.foto_url}`} alt="Blog Thumb" style={{objectFit:'cover', maxWidth: '54px'}} />
                                        </Link>
                                    </div>
                                    <div className="post-content">
                                        <ul className="blog-meta">
                                            <li>{item.dt_post}</li>
                                        </ul>
                                        <h6 className="title">
                                            <Link  to={process.env.PUBLIC_URL + `/blog/${item.slug}`} style={styleLink}>{item.titulo}</Link>
                                        </h6>
                                    </div>
                                </div>
                            )
                        } )
                    }
                </div>
            </div>
        </div>
    )
}

export default LatestPostOne;