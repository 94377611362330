import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import {formatToBRL} from 'brazilian-values';
import handlePrice from '../../reducers/handlePrice'
const CourseTypeOneAcademy = ({ data, classes }) => {

    const styleLink = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        height: '66px',
        lineHeight: '31px'
    };

    

    const excerpt = data.descricao.substring(0, 142) + "...";
    return (
        <div className={`edu-card card-type-3 radius-small ${ classes ? classes : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/curso/${data.slug}`}>
                        <img className="w-100" src={data.foto_principal_url} alt="Course Thumb" style={{maxHeight: '256px', minHeight: '254px', objectFit:'cover'}} />
                    </Link>
                    {/* <div className="wishlist-top-right">
                        <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                    </div> */}
                    { data.tags.length >= 1 && <div className="top-position status-group left-bottom">
                        <Link className="eduvibe-status status-03" to={process.env.PUBLIC_URL + `/cursos?tag=${ slugify( data.tags.slice( 0, 1 ) ) }`}>
                            {data.tags.slice(0, 1)}
                        </Link>
                    </div> }
                </div>
                <div className="content">
                    <div className="card-top">
                        <div className="author-meta">
                            <div className="author-thumb">
                                {/* <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( data.instructor ) }`}>
                                    <img src={`${process.env.PUBLIC_URL}/images/instructor/instructor-small/${instructorThumb}`} alt="Author Thumb" />
                                    <span className="author-title">{ data.instructor }</span>
                                </Link> */}
                            </div>
                        </div>
                        <ul className="edu-meta meta-02" style={{height:"66px"}}>
                            <li><i className="icon-file-list-3-line"></i>{data.licoes} Lições</li>
                            <li><i className="icon-time-line"></i>{data.duracao}</li>
                            <li><i className="icon-group-line"></i>{data.alunos}</li>
                        </ul>
                    </div>
                    <h6 className="title">
    
                        <Link style={styleLink} to={process.env.PUBLIC_URL + `/curso/${data.slug}`}>{data.nome}</Link>
                    </h6>
                    <div className="card-bottom">
                        <div className="price-list price-style-02" style={{height:'55px'}}>
                            {
                                data.preco == null ?
                                    <div className="price current-price">Grátis</div>
                                :
                                    <div className="price current-price">
                                        <p className='fs-5 fw-light mb-0'>12x de </p>
                                        {formatToBRL(handlePrice(data.preco.valor))}</div>
                            }
                        </div>
                        <div className="edu-rating rating-default">
                            <div className="rating eduvibe-course-rating-stars">
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                            </div>
                            <span className="rating-count">{data.nota}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-hover-action">
                <div className="hover-content">
                    <div className="content-top">
                        { data.tags.length >=1 && <div className="top-status-bar">
                            <Link className="eduvibe-status status-03" to={process.env.PUBLIC_URL + `/curso/${ data.slug }`}>
                                {data.tags.slice(0, 1)}
                            </Link>
                        </div>}
                        {/* <div className="top-wishlist-bar">
                            <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                        </div> */}
                    </div>

                    <h6 className="title">
                        <Link style={styleLink} to={process.env.PUBLIC_URL + `/curso/${data.slug}`}>{data.nome}</Link>
                    </h6>

                    <p className="description">{ excerpt }</p>

                    <div className="price-list price-style-02" style={{height:'55px'}}>
                        {
                            data.preco == null ?
                                <div className="price current-price">Grátis</div>
                            :
                                <div className="price current-price">
                                <p className='fs-5 fw-light mb-0 text-white'>12x de </p>
                                {formatToBRL(handlePrice(data.preco.valor))}</div>
                        }
                    </div>

                    <div className="hover-bottom-content">
                        {/* <div className="author-meta">
                            <div className="author-thumb">
                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( data.instructor ) }`}>
                                    <img src={`${process.env.PUBLIC_URL}/images/instructor/instructor-small/${instructorThumb}`} alt="Author Thumb" />
                                    <span className="author-title">{ data.instructor }</span>
                                </Link>
                            </div>
                        </div> */}
                        <ul className="edu-meta meta-02">
                            <li><i className="icon-file-list-3-line"></i>{data.licoes} Lições</li>
                            <li><i className="icon-time-line"></i>{data.duracao}</li>
                            <li><i className="icon-group-line"></i>{data.alunos}</li>
                        </ul>
                    </div>
                    <div className="read-more-btn">
                        <Link className="edu-btn btn-medium btn-white" to={process.env.PUBLIC_URL + `/curso/${data.slug}`}>
                            { data.preco == null ? 'Inscreva-se Agora' : 'Comprar Agora' }
                            <i className="icon-arrow-right-line-right"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CourseTypeOneAcademy;