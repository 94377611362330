
const items = [
  {
    id: 1,
    number: "+5.464",
    text: "alunos cadastrados"
  },
  {
    id: 2,
    number: "+60",
    text: "mentores prontos para te ajudar"
  },
  {
    id: 3,
    number: "+500",
    text: "horas de mentoria"
  }
]

export default function FunFacts(){

  return(
    <div className="container-fluid fun-facts-container bg-color-secondary-alt mb-5">
      <div className="row d-flex justify-content-evenly ptb--120">
        {items.map((item) => (
          <div className="col-12 col-lg-2 text-center" key={item.id} style={{height:'70px'}}>
            <h3 className="text-primary mb-0">{item.number}</h3>
            <p className="fs-3 mb-0">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}