import icon1 from '../../assets/images/icons/intercambio.svg'
import { FaArrowRight } from "react-icons/fa";

const CallToActionSix = () => {
  return(
    <div className="container-fluid d-flex flex-column align-items-center justify-content-center pt--50 pb--100" style={{backgroundColor:'#ECEEFF'}}>
      <div className="container row d-flex align-items-start">
        <div className="col-1">
          <img src={icon1} alt='icon' className='mb-5' style={{width:'74px', height:'74px'}} />
        </div>
        <div className="col ms-3">
          <h4 className='text-primary mb-0'>Conheça nossas oportunidades</h4>
          <p>Clique aqui e conheça as melhores oportunidades para estudar no exterior.</p>
        </div>
        <div className="col-3">
          <a className="edu-btn mt-1" href="https://academyabroad.com.br/editais-intercambio">Cadastre-se  <FaArrowRight /></a>
        </div>
      </div>
      
    </div>
  )
}

export default CallToActionSix