import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import EventData from '../../data/event/EventData.json';
import axios from 'axios';

const IntercambioDetails = () => {
    const { uuid } = useParams();

    const eventId = 1;
    const data = EventData.filter(event => event.id === eventId);
    const eventItem = data[0];

    const [intercambio, setIntercambio] = useState({
        nome: '',
    });

    useEffect( () => {
        axios.get(`https://api.academyabroad.com.br/api/site/intercambios/${uuid}`)
            .then(response => {
                setIntercambio(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [uuid] );


    return (
        <>
            <SEO title={ intercambio.nome } />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Event Details"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Event Details"
                /> */}
                <div className="edu-event-details-area  edu-section-gap bg-color-white eduvibe-home-five-cats">
                    <div className="container eduvibe-animated-shape">
                        {/* <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="thumbnail">
                                    <img src={intercambio.foto_principal_url} alt="Event Thumb" />
                                </div>
                            </div>
                        </div> */}
                        <div className="row g-5">
                            <div className="col-lg-7">
                                <div className="content">
                                    <h3 className="title">{ intercambio.nome }</h3>

                                    <p dangerouslySetInnerHTML={{__html: intercambio.detalhes}}></p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div >
                                    <div className="eduvibe-widget eduvibe-widget-details">
                                        <div className="video-area">
                                            <div className="thumbnail video-popup-wrapper">
                                                <img className="radius-small w-100" src={intercambio.foto_principal_url} alt="Curso Imagem" />
                                                {/* <button onClick={ () => setToggler( ! toggler ) } className="video-play-btn position-to-top video-popup-activation">
                                                    <span className="play-icon"></span>
                                                </button>
                                                <FsLightbox toggler={ toggler } sources={ data.videoLink } /> */}
                                            </div>
                                        </div>
                                        <div className="widget-content mt--45">
                                            <ul>
                                                { intercambio.dt_inicio && <li><span><i className="icon-calendar-2-line"></i> Início</span><span>{ intercambio.dt_inicio }</span></li> }
                                                { intercambio.dt_fim && <li><span><i className="icon-calendar-2-line"></i> Fim</span><span>{ intercambio.dt_fim }</span></li> }
                                                { intercambio.hospital && intercambio.hospital != "null" && <li><span><i className="icon-lab"></i> Hospital</span><span>{ intercambio.hospital }</span></li> }
                                                { intercambio.instituicao && <li><span><i className="icon-Campus"></i> Instituição</span><span>{ intercambio.instituicao }</span></li> }
                                                { intercambio.especialidade && <li><span><i className="icon-checkbox-circle-fill"></i> Especialidade</span><span>{ intercambio.especialidade }</span></li> }
                                                { intercambio.quantidade && <li><span><i className="icon-calculator"></i> Quantidade</span><span>{ intercambio.quantidade }</span></li> }
                                                { intercambio.periodo && <li><span><i className="icon-time-line"></i> Duração</span><span>{ intercambio.periodo }</span></li> }
                                                { intercambio.cidade && <li><span><i className="icon-map-pin-line"></i> Cidade</span><span>{ intercambio.cidade }</span></li> }
                                            </ul>

                                    
                                            <div className="read-more-btn mt--45">
                                                <a className="edu-btn w-100 text-center" href={intercambio.link} target='_blank'>Acessar intercâmbio</a>
                                            </div>

                                            
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/images/shapes/shape-03.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-4">
                                <img src="/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-5">
                                <img src="/images/shapes/shape-36.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-6">
                                <img src="/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                            </div>
                        </div>

                        
                    </div>
                    
                </div>
            </Layout>
        </>
    )
}
export default IntercambioDetails;