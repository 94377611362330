import ScrollAnimation from "react-animate-on-scroll";
import React, {useState} from "react";


export default function ImpulsoSection({title, text, button, href, reverse, id, img}){
  const [imageScreen, setImageScreen ] = useState(img[0])

  return(
    <section className={`impulso-section ${reverse}`} id={id}>
      <div className="box-l">
      <ScrollAnimation
      animateIn="fadeInLeft"
      animateOut="fadeOutRight"
      animateOnce={ true }>
        <div className="card-text">
          <div className="card-text-wrapp">
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: text }} />
            <a className="button" href={href}>{button}</a>
          </div>
        </div>
      </ScrollAnimation>
      </div>

      <div className="box-r">
        <ScrollAnimation
        animateIn="fadeInDown"
        animateOut="fadeOutUp"
        animateOnce={ true }>
          <div className="box-image">
          <div className="image-big">
          <img src={imageScreen} alt="imagem plano" />
          </div>
          <div className="image-select">
            <img src={img[0]} alt="imagem plano" onClick={() => setImageScreen(img[0])}/>
            <img src={img[1]} alt="imagem plano" onClick={() => setImageScreen(img[1])}/>
            <img src={img[2]} alt="imagem plano" onClick={() => setImageScreen(img[2])}/>
          </div>
        </div>
        </ScrollAnimation>
      </div>
    </section>
  )
}