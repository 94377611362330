const SupportSection = () => {
    return(
        <div className="container-fluid ptb--50" id="support-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="banner-support text-center">
                            <span className="text-white">PRECISA DE AJUDA?</span>
                            <h4 className="text-center title-imersao">Contato e Suporte</h4>
                            <p className="text-white fw-light">Se tiver alguma dúvida ou precisar de mais informações, entre em contato conosco através do e-mail <a className='fw-bold text-white' href="mailto:suporte@academyabroad.com.br" rel="noreferrer" target="_blank">suporte@academyabroad.com.br</a> ou pelo telefone (31) 9561-3002</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportSection;