import SEO from "../../common/SEO"
import HeaderTwo from "../../common/header/HeaderTwo"
import BannerOne from "../../components/banner/BannerOne";
import CallToActionFive from "../../components/cta/CallToActionFive";
import CallToActionFour from "../../components/cta/CallToActionFour";
import NewsletterThree from "../../components/newsletter/NewsLetterThree";
import CourseCarouselThree from "../course/CourseCarouselThree";
import CourseCarouselTwo from "../course/CourseCarouselTwo";
import FooterOne from "../../common/footer/FooterOne";
import imgElement from "../../assets/images/bg/elemento-horizontal-2.png"
import img1 from '../../assets/images/kenji.jpg'
import icon1 from '../../assets/images/icons/badge.svg'
import icon2 from '../../assets/images/icons/g195.svg'
import icon3 from '../../assets/images/icons/plain.svg'
import img2 from '../../assets/images/phone-3.png'
import HowItWorksThree from "../../components/how-it-works/HowItWorksThree";


const data = [
  {
    icon: icon1,
    description: 'Escolha seu',
    title:'Mentor'
  },
  {
    icon: icon2,
    description: 'Agende sua',
    title:'Mentoria'
  },
  {
    icon: icon3,
    description: 'Garanta seu',
    title:'Intercâmbio'
  }
]

const intercambio = ["IDIOMAS", "PESQUISA", "PREPARAÇÃO PARA PROVAS", "ORATÓRIA", "INTERCÂMBIO"]

const ParaSeuIntercambio = () => {
  return(
    <>
    <SEO title="Para Seu Intercambio" />
    <HeaderTwo styles=" header-style-2" />
    <BannerOne
    title={`<h2 class="h3 text-white lh-1">Academy para</br><span class="text-primary h1">Intercâmbio</span></h2>`}
    description="<p class='description text-white fw-light lh-3 mt-5'>A Academy é a sua porta de entrada para oportunidades internacionais de estudos e carreira. Nossos mentores especializados oferecem suporte personalizdo para escolher programas, preparar candidaturas e otimizar suas experiências.</p><p class='text-white'> Expanda horizontes acadêmicos e profissionais com a expertise da Academy</p>"
    bg="bg-intercambio"
    isButton={false} 
    element={imgElement}/>
    <CallToActionFive 
    data={data}/>
    <HowItWorksThree />
    <CourseCarouselThree 
    title="Principais mentores para Intercâmbio" 
    categoria={intercambio}/>
    <NewsletterThree 
    hSize="h5"
    text="Fique por dentro em 1° mão de oportunidades de intercâmbio da Academy se cadastrando em nossa Newsletter"
    btnText="Cadastrar gratuitamente"
    imgPhone={img2}/>
    <CourseCarouselTwo title="Cursos para seu intercâmbio" />
    <CallToActionFour
    title="Comece hoje sua trajetória internacional"
    description="Conheça as experiências de quem já estudou no exterior"
    link="/experiencias"
    button="Mural de experiências"
    img={img1} />
    <FooterOne />
    </>
  )
}

export default ParaSeuIntercambio