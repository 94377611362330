import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import CourseTypeOne from './CourseTypeOne';
import CourseData from '../../data/course/CourseData.json';
import axios from 'axios';
import CourseTypeOneAcademy from './CourseTypeOneAcademy';
import CardMentor from './CardMentor';
import EventOne from '../../components/event/EventOne';
import EventData from '../../data/event/EventData.json';
import EventOneIntercambio from '../event/EventOneIntercambio';
import PostBlog from '../post/PostBlog';
import { useSearchParams } from 'react-router-dom';

const BlogFilter = ( { itemToShow, showLoadMore, incrementPerClick, cursos } ) => {
    const FilterControls = [...new Set( CourseData.map( item => item.filterParam ) ) ];
    FilterControls.unshift( 'All' );

    const EventItems = EventData.slice(0, 6);
    const [searchParams, setSearchParams] = useSearchParams()

    const [activeFilter, setActiveFilter] = useState( 'all' );
    const [visibleItems, setVisibleItems] = useState( [] );
    const [dataRaw, setDataRaw] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [ordenated, setOrdenated] = useState();

    const ordenation = [
        "Mais recentes",
        "Mais antigos",
        "Por autor"
    ]

    useEffect( () => {  
        axios.get('https://api.academyabroad.com.br/api/site/artigos')
            .then(response => {

               setDataRaw(response.data.data);

                

                let categorias = [];

                for (let i = 0; i < response.data.data.length; i++) {
                    const element = response.data.data[i];
                    if(categorias.find( ( cat ) => cat.id === element.categoria ) === undefined && element.categoria != null){
                        categorias.push({id: element.categoria, nome: element.categoria});
                    }
                }
                setCategorias(categorias);

                if(searchParams.get('tag')){
                    setVisibleItems(response.data.data.filter( ( data ) => data.tags.includes(searchParams.get('tag')) ));
                }else{
                    setVisibleItems(response.data.data.sort((a,b) =>{
                        const dataA = new Date(a.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                        const dataB = new Date(b.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                        return dataB - dataA
                    }))
                }
            })
            .catch(error => {
                console.error(error);
            });

    }, [] );


    const handleChange = ( e ) => {
        setActiveFilter(e);
        if(e === 'all'){
            setVisibleItems(dataRaw);
        }else{
            setVisibleItems(dataRaw.filter(( data ) => data.categoria === e ));
        }

    }

    

    const handleOrdenation = (order) => {
        setActiveFilter(order)

        if(order === "Mais recentes"){  
            setVisibleItems(dataRaw.sort((a,b) =>{
                const dataA = new Date(a.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                const dataB = new Date(b.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                return dataB - dataA
            }))
        }else if (order === "Mais antigos"){
            setVisibleItems(dataRaw.sort((a,b) =>{
                const dataA = new Date(a.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                const dataB = new Date(b.dt_post.split('/').reverse().join('-')).setHours(0, 0, 0, 0);
                return dataA - dataB
            }))
        }else if( order === "Por autor"){
            let newOrder = dataRaw.sort(function(a, b) {
                const nomeAutorA = a.autor.nome.toUpperCase();
                const nomeAutorB = b.autor.nome.toUpperCase();
                if (nomeAutorA < nomeAutorB) {
                    return -1;
                }
                if (nomeAutorA > nomeAutorB) {
                    return 1;
                }
                return 0;
            });
            setVisibleItems(newOrder);
        }
    }

    return (
        <>
            <div className="row g-5 align-items-center mb--45 mb--30 mt_md--30 mt_sm--30">
                <div className="col-lg-5">
                    <div className="section-title text-start">
                        {/* <span className="pre-title">Mentores</span> */}
                        <h3 className="title">Blog</h3>
                    </div>
                </div>
                {/* <div className="col-lg-7" >
                    <div className="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end">
                        <button
                            onClick={()=>{handleChange('all')}}
                            className={
                                'all' === activeFilter
                                ? "is-checked"
                                : " "
                            }
                        >
                            Todos
                        </button>
                        {categorias.map( ( filter, i ) => (
                            <button
                                onClick={()=>{handleChange(filter.id)}}
                                key={i}
                                className={
                                    filter.id === activeFilter
                                    ? "is-checked"
                                    : " "
                                }
                            >
                                {filter.nome}
                            </button>
                        ))}
                    </div>
                </div> */}
                
            </div>

            <div className="row g-5">
                <div className="col-lg-9" >
                    <div className="row g-5">
                    {
                        visibleItems.map((item,i ) => (
                            <div className="col-lg-6 col-md-6 col-12" key={ i }>
                                <PostBlog  data={item}  />
                            </div>
                        ))                       
                    }
                    </div>
                </div>
                <div className="col-lg-3">
                    <aside className="edu-course-sidebar">
                        <form onSubmit={ (e) => e.preventDefault() }>

                            <div className="edu-course-widget widget-shortby">
                                <div className="inner">
                                <h5 className="widget-title">Ordenar por</h5>
                                    <div className="content mb-5">
                                        <div>
                                            <select className=""  onChange={(e) => {handleOrdenation(e.target.value)}} defaultValue={ordenation[0]}>
                                                {ordenation.map( ( item, i ) => (
                                                    <option value={item} key={i} >{item}</option>
                                                ))}
                                                    
                                            </select>
                                        </div>
                                    </div>

                                    <h5 className="widget-title">Categorias</h5>
                                    <div className="content">
                                        <div>
                                            <select className=""  defaultValue={"all"} onChange={(e)=>{handleChange(e.target.value)}}>
                                                <option value="all" >Todos</option>
                                                {categorias.map( ( filter, i ) => (
                                                    <option value={filter.id} key={i} >{filter.nome}</option>
                                                ))}
                                            </select>
                                        </div>

                                        {/* <div className="edu-form-check">
                                            <input type="radio" id="all" value="all" name="coursesortby" checked={ 'all' === activeFilter ? "checked" : '' } onChange={()=>{handleChange('all')}}  />
                                            <label htmlFor="all">Todos</label>
                                        </div>
                                        {categorias.map( ( filter, i ) => (
                                            <div className="edu-form-check" key={i}>
                                                <input type="radio" id={filter.id} value={filter.id} name="coursesortby" checked={ filter.id === activeFilter ? "checked" : '' } onChange={()=>{console.log(filter);handleChange(filter.id)}}   />
                                                <label htmlFor={filter.id}>{filter.nome}</label>
                                            </div>

                                        ))} */}
                                    </div>
                                </div>
                            </div>

                        </form>

                        
                    </aside>
                </div>
            </div>

          
        </>
    )
}

export default BlogFilter;