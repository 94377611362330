import { FaArrowRight } from "react-icons/fa"

const InvestimentExperience = () => {
    return(
        <div className="container-fluid ptb--100 bg-primary">
            <div className="container text-center">
                <h3 className="text-white">Valor do Investimento</h3>
                <div className="row p-5 m-5">
                    <div className="col-12 col-lg-6 my-3 px-1 p-lg-5">
                        <div className="text-center p-5" style={{height:'220px', backgroundColor:'#FFFFFF25', borderRadius:'20px'}}>
                            <span className="fw-bold" style={{color:'var(--color-secondary)'}}>Investimento</span>
                            <p className="text-white lh-sm mb-0" style={{fontSize:'48px'}}>R$ 4000,00</p>
                            <p className="fw-normal fs-5" style={{color:'var(--color-secondary)'}}>à vista</p>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 my-3 px-1 p-lg-5">
                        <div className="text-center p-5" style={{height:'220px', backgroundColor:'#FFFFFF25', borderRadius:'20px'}}>
                            <span className="fw-bold" style={{color:'var(--color-secondary)'}}> Ou 12x de</span>
                            <p className="text-white lh-sm" style={{fontSize:'48px'}}>R$ 360,00</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <a href="https://wkf.ms/3PwhOCn" className="edu-btn bg-white text-primary">Garanta sua Vaga <FaArrowRight /></a>
                </div>
            </div>
        </div>
    )
}

export default InvestimentExperience