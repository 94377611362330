import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import CourseTypeOne from './CourseTypeOne';
import CourseData from '../../data/course/CourseData.json';
import axios from 'axios';
import CourseTypeOneAcademy from './CourseTypeOneAcademy';
import CardMentor from './CardMentor';
import EventOne from '../../components/event/EventOne';
import EventData from '../../data/event/EventData.json';
import EventOneIntercambio from '../event/EventOneIntercambio';
import CategoryFour from '../category/CategoryFour';
import SliderExperiencias from '../category/SliderExperiencias';

const ExperienciasFilter = ( { itemToShow, showLoadMore, incrementPerClick, cursos } ) => {

    const [itens, setItens] = useState([]);

    useEffect( () => {
      
        axios.get('https://api.academyabroad.com.br/api/site/experiencias')
            .then(response => {
                setItens(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });

    }, [] );




    return (
        <>
            <div className="row g-5 align-items-center mb--45 mb--30 mt_md--30 mt_sm--30">
                <div className="col-lg-12">
                    <div className="section-title text-start">
                        <span className="pre-title">Conheça as experiências  ao redor do mundo!</span>
                        <h3 className="title">Experiências</h3>
                    </div>
                </div>
              
                
            </div>

            <div className="row g-5">
                <SliderExperiencias itens={itens} ></SliderExperiencias>
            </div>

          
        </>
    )
}

export default ExperienciasFilter;