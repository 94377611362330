import icon1 from '../../../assets/images/imersao-trilingue/Ícone 3.svg';
import icon2 from '../../../assets/images/imersao-trilingue/Ícone 2.svg';
import icon3 from '../../../assets/images/imersao-trilingue/Ícone 1 (1).svg';
import icon4 from '../../../assets/images/imersao-trilingue/Ícone 4.svg'

const BenefitsSection = () => {

    const benefitsData = [
        {
            icon: icon1,
            descritivo: 'Aulas dinâmicas e interativas com professores experientes em cada idioma.'
        },
        {
            icon: icon2,
            descritivo: 'Flexibilidade para escolher entre aulas em grupo ou individuais, de acordo com suas preferências e necessidades.'
        },
        {
            icon: icon3,
            descritivo: 'Descubra novas culturas e expanda seus horizontes linguísticos enquanto aprimora suas habilidades linguísticas.'
        },
        {
            icon: icon4,
            descritivo: 'Siga seu próprio ritmo de aprendizado após a imersão, adaptando as aulas de acordo com sua agenda e objetivos.'
        }
    ]
    return(
        <div className="container-fluid ptb--50" id="benefits-section">
            <div className="container">
                <div className="row">
                    <h2 className='color-primary text-center'>Benefícios do Programa:</h2>
                </div>
                <div className='row'>
                    {benefitsData && benefitsData.map((item, i) => (
                        <div className='col-12 col-md-6 col-lg-3' key={i}>
                            <div className='card-benefits'>
                                <div className='box-image m-auto'>
                                    <img src={item.icon} alt='icone' />
                                </div>
                                <p className='mt-4'>{item.descritivo}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default BenefitsSection;