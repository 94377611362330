import React from 'react';
import Slider from 'react-slick';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const items = [
    {
        title: 'Art & Design',
        numberOfCourses: '45 Courses',
        image: 'category-01.png',
        shape: 'shape-bg-1',
        link: '#'
    },
    {
        title: 'Content Writting',
        numberOfCourses: '23 Courses',
        image: 'category-02.png',
        shape: 'shape-bg-2',
        link: '#'
    },
    {
        title: 'Data Science',
        numberOfCourses: '39 Courses',
        image: 'category-03.png',
        shape: 'shape-bg-3',
        link: '#'
    },
    {
        title: 'Health & Fitness',
        numberOfCourses: '25 Courses',
        image: 'category-04.png',
        shape: 'shape-bg-4',
        link: '#'
    },
    {
        title: 'Music Learning',
        numberOfCourses: '30 Courses',
        image: 'category-05.png',
        shape: 'shape-bg-5',
        link: '#'
    },
    {
        title: 'Data Science',
        numberOfCourses: '15 Courses',
        image: 'category-06.png',
        shape: 'shape-bg-3',
        link: '#'
    }
];

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow" onClick={ onClick } ><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow" onClick={ onClick } ><i className="icon-arrow-right-line"></i></button>
}

const SliderExperiencias = ( { wrapperClass, itemClass, itens } ) => {
    
    const sliderSettings = {
        dots: false,
        autoplay: false,
        infinite: false,
        arrows: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            }, 
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }, 
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, 
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Slider 
            className={ wrapperClass || 'col-lg-12 mt--60 mb_dec--20 slick-activation-wrapper service-activation-item5 edu-slick-arrow-top' }
            { ...sliderSettings }
        >
            { itens.map( ( data , i ) => (
                <div 
                    key={i}
                    className="slick-slide-item"
                >   
                    <div className="edu-blog blog-type-4 radius-small" style={{background: '#e8efff', margin: '1px 17px'}}>
                        <div className="inner">
                            <div className="thumbnail">
                                <a>
                                    <img src={data.foto_principal_url} alt="Blog Thumb" style={{height:'326px', objectFit:'cover'}} />

                      

                                </a>
                            </div>
                            <div className="content" style={{minHeight: '145px'}}>
                                <h6 className="title">
                                    <Link to={process.env.PUBLIC_URL + `/experiencias/${data.uuid}`}>{data.nome}</Link>
                                </h6>
                                <ul className="blog-meta">
                                    <li><i className="icon-Campus"></i>{data.instituicao}</li>
                                    <li><i className="icon-map-pin-line"></i>{data.cidade}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    )
}

export default SliderExperiencias;