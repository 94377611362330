import key from '../../../assets/images/imersao-jose/key.png'

const AboutSection = () => {

    const data = [
        {
            title:'O poder da voz',
            text:'Descubra como a sua voz pode ser uma ferramenta poderosa na comunicação. Aprenda a controlar a entonação, o ritmo e a intensidade para transmitir mensagens com mais impacto e autoridade. Explore o potencial da sua voz para conquistar a atenção e inspirar confiança em suas interações profissionais e pessoais.'
        },
        {
            title:'A Consciência Corporal',
            text:'Entenda a importância da consciência corporal na comunicação. Descubra como a linguagem não verbal, incluindo gestos, expressões faciais e postura, pode influenciar a forma como você é percebido pelos outros. Aprenda a utilizar seu corpo de forma consciente e assertiva para transmitir mensagens claras e assertivas.'
        },
        {
            title:'Conteúdo impactante',
            text:'Aprenda a criar conteúdo que realmente faça a diferença. Explore técnicas para desenvolver mensagens poderosas, persuasivas e memoráveis. Descubra como estruturar suas ideias de forma clara, concisa e envolvente para capturar a atenção do seu público e gerar impacto positivo em suas apresentações e conversas.'
        },
        {
            title:'A arte da escuta ativa',
            text:'Desenvolva habilidades essenciais de escuta para aprimorar suas interações. Aprenda a ouvir atentamente, compreender as necessidades e perspectivas dos outros e responder de forma empática e eficaz. Descubra como a escuta ativa pode fortalecer relacionamentos, resolver conflitos e promover uma comunicação mais significativa e produtiva.'
        },
    ]

    return(
        <div className="container-fluid ptb--100" id="about-section-imersao-jose">
            <div className="container">
                <div className="row">
                    <span className="text-center text-black mb-5">SOBRE A IMERSÃO</span>
                    <p className='text-center text-black lh-sm fs-4'>Nesta imersão você desvendará os quatro principais segredos por trás de uma comunicação eficiente e impactante.
                    Explorando estes aspectos fundamentais da comunicação, você descobrirá como aprimorar suas habilidades verbais, corporais e emocionais para construir conteúdos de impacto, a fim de alcançar o sucesso em sua área de atuação profissional e ter destaque com a sua oratória.</p>
                    <h4 className="text-center title-imersao mb-5">Preparado para destravar os 4 segredos?</h4>
                </div>
                <div className="row">
                    {data && data.map((item, i) => (
                        <div className="col-12 col-md-6 col-lg-3 mb-3" key={i}>
                            <div className="card-about">

                                <span>{`SEGREDO ${i+1}`}</span>
                                <h5 className='mt-2'>{item.title}</h5>
                                <div className='divider mb-5'></div>
                                <div className='card-image-box mb-4'>
                                    <img src={key} alt='chave' />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AboutSection;