import React, { useState } from "react";
import '../styles/faqGallery.scss'


const GalleryFaq = ({data}) => {

    const faqImages = data.slice().reverse()

    return(
        <div className="container-fluid ptb--100">
            <div className="container">
                <div className="row text-center pb-100">
                    <h1 className="h3 text-primary">Veja como foram os programas anteriores</h1>
                </div>
            </div>
            <div className="gallery-card-flex-wrap">
                {faqImages && faqImages.map((item, i) => (
                    <div className={`photo-container small`} key={i}>
                        <img className="photo" src={item} alt="faq imagens"/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default GalleryFaq;