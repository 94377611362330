import React, { useEffect, useState } from 'react';
import TrackVisibility from 'react-on-screen';
import CountUp from 'react-countup';
import { useParams, Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import InstructorData from '../../data/instructor/InstructorData.json';
import CourseData from '../../data/course/CourseData.json';
import axios from 'axios';
import { formatToBRL } from 'brazilian-values';
import AvaliacaoMentor from '../../components/testimonial/AvaliacaoMentor';
import _ from 'lodash';
import CalendarComponent from '../../components/calendar/Calendar';


const MentorDetalhes = () => {
    const { slug, exclusive } = useParams();
    const [randomNumber, setRandomNumber] = useState()
    const [articles, setArticles] = useState([])
    const [mentores, setMentores] = useState([])
    const [pacotes, setPacotes] = useState([])

    const data = InstructorData.filter(instructor => slugify( instructor.name ) === slugify( 'nancy-phipps' ) );
    const teamMember = data[0];

    const courses = CourseData.map(course => {
        return {
            ...course,
            instructor: slugify(course.instructor) === 'nancy-phipps' ? course.instructor : ''
        }
    }).filter(course => course.instructor.length > 0);

    const [mentor, setMentor] = useState({
        nome: '',

    });
    const [features, setFeatures] = useState([]);
    const [feature, setFeature] = useState(true);

    function getRandomInt(max) {
        return setRandomNumber(Math.floor(Math.random() * max))
    }

    const styleLink = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        minHeight: '59px',
        lineHeight: '31px'
    };

    useEffect( () => {
        axios.get(`https://api.academyabroad.com.br/api/site/mentor/${slug}`)
            .then(response => {
                setMentor({
                    ...response.data.data,
                    //video: "https://player.vimeo.com/video/832717390?h=513197f597"
                    
                });
                if(response.data.data.valores.length > 0){
                    setPacotes(response.data.data.valores)
                }else{
                    setPacotes(null)
                }
                setFeatures([
                    {
                        title: 'Mentorias',
                        isDecimal: false,
                        number: response.data.data.mentorias
                    },
                    {
                        title: 'Artigos publicados',
                        isDecimal: false,
                        number: articles.length
                    },
                    {
                        title: 'Avaliação',
                        isDecimal: true,
                        number: response.data.data.nota
                    }
                ])

                getRandomInt(9999999)

                if(response.data.data.mentorias === 0 && response.data.data.horas === 0 && response.data.data.nota === 0){
                    setFeature(false);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [slug] );

    useEffect( () => {
        axios.get('https://api.academyabroad.com.br/api/site/artigos')
            .then(response => {
               setArticles(response.data.data.filter( data => data.autor.nome.includes(mentor.nome) ));

            })
            .catch(error => {
                console.error(error);
            });

    }, [mentor],);

    useEffect( () => {  
        axios.get('https://api.academyabroad.com.br/api/site/mentor')
            .then(response => {

                const todosMentores = _.shuffle(response.data.data)

               setMentores(todosMentores.filter( ( data ) => data.categorias.includes(mentor.categorias[0])).slice(0,3));

            })
            .catch(error => {
                console.error(error);
            });

    }, [mentor],);

    function separateNames(name){

        let nome = name.split(' ')
        
        nome = nome[0] + ' ' + nome[1];

        return nome
    }


    return (
        <>
            <SEO title={ mentor.nome } />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Instructor Profile"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Instructor Profile"
                /> */}
                <div className="edu-instructor-profile-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5 gap-3">

                            <div className="col-12">
                                <p className="fs-3 fw-bold text-secondar mb-0">Mentor</p>
                                <h3 className="text-primary">{ mentor.nome }</h3>
                            </div>

                            <div className="col-12 col-lg-4 p-0">
                                <div className="instructor-profile-left bg-white bg-white shadow radius p-5">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src={mentor.foto_perfil_url?mentor.foto_perfil_url:mentor.foto_principal_url} 
                                            alt="Mentor"/>
                                        </div>
                                        <div className="content mt--65">
                                            <h5 className="title text-primary mb-3">Sobre o mentor</h5>
                                            <p className="fs-4 lh-base fw-normal mb-0" style={{textAlign:'justify'}}>{mentor.descricao_mentor}</p>
                                            
                                            <div className="row d-flex justify-content-center gap-3 mt-5">

                                                <div className="col radius text-center px-4 py-3 d-flex flex-column align-items-center" style={{backgroundColor:'#ECEEFF'}}>
                                                    <i className="icon-time-line text-primary fw-normal fs-2"></i>
                                                    <span className='mt-2 text-secondary fs-4'>Duração</span>
                                                    <span className='h6 text-primary mt-2 mb-0'>{mentor.tempo_mentoria} minutos</span>
                                                </div>

                                                <div className="col radius text-center px-4 py-3 d-flex flex-column align-items-center" style={{backgroundColor:'#ECEEFF'}}>
                                                    {!mentor.desconto ? (
                                                        <>
                                                            <i className="icon-price-tag-3-line text-primary fw-normal fs-2"></i>
                                                            <span className='mt-2 text-secondary fs-4'>Valor</span>
                                                            <span className='h6 text-primary mt-2 mb-0'>{formatToBRL(mentor.valor_mentoria)}</span>

                                                        </>

                                                    ) :
                                                    (
                                                        <>
                                                            <i className="icon-price-tag-3-line text-primary fw-normal fs-2"></i>
                                                            <span className='mt-2 text-secondary fs-4'> Valor</span>
                                                            <span className='mt-2 mb-0 d-flex flex-column align-items-end'>
                                                                <s className='text-danger'>{formatToBRL(mentor.valor_mentoria)}</s>
                                                                <span className='text-primary fs-1 fw-bold'>{formatToBRL(mentor.valor_desconto)}</span>
                                                            </span>

                                                        </>
                                                    )}

                                                </div>

                                                <div className="col-12 radius text-center px-4 py-3 d-flex flex-column align-items-center" style={{backgroundColor:'#ECEEFF'}}>
                                                    <i className="icon-box-line text-primary fw-normal fs-2"></i>
                                                    <span className='my-3 text-primary fs-2 fw-bold'> Pacotes de mentorias</span>
                                                    <div className='col-10 my-3'>
                                                    {
                                                        pacotes ? pacotes.map((valor, i) => (
                                                            <a href={`https://painel.academyabroad.com.br/checkout/mentor/${slug}`}
                                                            key={i} 
                                                            className='edu-btn btn-small fs-5 my-2'
                                                            rel="noreferrer" 
                                                            target="_blank">
                                                                Compre {valor.mentorias} com {valor.desconto}% de desconto
                                                                <i className='icon-arrow-right-line text-white fw-normal'></i>
                                                            </a>
                                                        )) : (
                                                            <p className='fs-5 fw-normal lh-sm mb-0'>Não há pacotes de mentorias disponíveis para esse mentor no momento.</p>
                                                        )
                                                    }
                                                    </div> 
                                                </div>

                                                
                                            </div>

                                            <div className='col-12'>
                                                <h6 className="title text-primary mt-5">Datas disponíveis</h6>
                                                <CalendarComponent slug={slug} />
                                            </div>

                                            { mentor.depoimentos && mentor.depoimentos.length > 0 && <div className="mt--30">
                                                <h5 className="title text-primary">Depoimentos</h5>
                                                <AvaliacaoMentor data={mentor.depoimentos} /> 
                                </div>
                                }

                                {mentores && (
                                    <>
                                        {!exclusive && (
                                            <>
                                            <h5 className='title text-primary h5 mb-5 mt--50'>Mentores similares</h5>
                                                {mentores.map((item, i) => (
                                                    <div className="row gap-2 my-5" key={ randomNumber + i}>
                                                        <div className="thumbnail col-5 mb-1">
                                                            <Link className="d-block h-100" to={process.env.PUBLIC_URL + `/mentor/${item.slug}`}>
                                                                <img src={`${item.foto_perfil_url}`} alt="Blog Thumb" className='radius' style={{objectFit:'cover', width: '100%', height:'100%'}} />
                                                            </Link>
                                                        </div>
                                                        <div className="post-content col">
                                                            <h6 className="title mb-1">
                                                                <Link  to={process.env.PUBLIC_URL + `/mentor/${item.slug}`} className='fs-2 mb-0 lh-base' style={styleLink}>{separateNames(item.nome)}</Link>
                                                            </h6>
                                                            <ul className='my-0 lh-base fs-4' style={{textAlign:'justify'}}>{item.categorias && (
                                                                <>
                                                                    {item.categorias.map((item, i) => (
                                                                        <li key={i} style={{listStyle:'none'}} className='lh-base my-0'>{item}</li>
                                                                    ))}
                                                                </>
                                                            )}
                                                            
                                                            </ul>
                                                            <Link className="edu-btn my-3 py-2 lh-base" style={{height:'auto'}} to={process.env.PUBLIC_URL + `/mentor/${item.slug}`}>
                                                                Ver mentor
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}

                                    </>
                                )}

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-lg-7">
                                <div className="instructor-profile-right">
                                    <div className="inner">
                                        <div className="section-title text-start">

                                            <h5 className="title text-primary h5 mb-2">Sobre a mentoria</h5>
                                            <p className="description" style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html: mentor.descricao_mentoria }}></p>
                                            

                                            <h5 className='title text-primary h5 mb-3'>Temas da mentoria</h5>
                                            {mentor.termo && <p className="description mt--30">
                                                <span  dangerouslySetInnerHTML={{__html: mentor.termo }}></span>
                                            </p>}
                                            
                                        </div>


                                        { feature === true && features && features.length > 0 && 
                                            <div className="course-statistic-wrapper bg-white shadow ptb--50 mt--65 radius-small">
                                                <div className="row g-5 h-100">
                                                    <div className="col h-100">
                                                        <div className="course-statistic text-center h-100">
                                                            <div className="inner d-flex flex-column align-items-center justify-content-start h-100">
                                                                <TrackVisibility once>
                                                                    { ( { isVisible } ) => isVisible && features[0].isDecimal !== true ? <span className="total text-primary fw-normal"><CountUp end={features[0].number} duration={1} delay={.1} start={0} /></span> : ''
                                                                        
                                                                    }
                                                                </TrackVisibility>
                                                                <TrackVisibility once>
                                                                    { ( { isVisible } ) => isVisible && features[0].isDecimal === true ? <span className="total text-primary fw-normal"><CountUp end={features[0].number} duration={ 1 } delay={.1} start={ 0 } decimals={ 1 } />/5.0</span> : ''
                                                                        
                                                                    }
                                                                </TrackVisibility>
                                                                <p className='text-secondary'>{ features[0].title }</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {articles.length > 0 && (
                                                        <div className="col-lg-4 col-md-6 col-12 h-100">
                                                        <div className="course-statistic text-center h-100">
                                                            <div className="inner d-flex flex-column align-items-center justify-content-start h-100">
                                                                <TrackVisibility once>
                                                                    { ( { isVisible } ) => isVisible && features[1].isDecimal !== true ? <span className="total text-primary fw-normal"><CountUp end={articles.length} duration={1} delay={.1} start={0} /></span> : ''
                                                                        
                                                                    }
                                                                </TrackVisibility>
                                                                <TrackVisibility once>
                                                                    { ( { isVisible } ) => isVisible && features[1].isDecimal === true ? <span className="total text-primary fw-normal"><CountUp end={articles.length} duration={ 1 } delay={.1} start={ 0 } decimals={ 1 } />/5.0</span> : ''
                                                                        
                                                                    }
                                                                </TrackVisibility>
                                                                <p className='text-secondary'>{ features[1].title }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )}

                                                    <div className="col h-100">
                                                        <div className="course-statistic text-center h-100">
                                                            <div className="inner d-flex flex-column align-items-center justify-content-start h-100">
                                                                <TrackVisibility once>
                                                                    { ( { isVisible } ) => isVisible && features[2].isDecimal !== true ? <span className="total text-primary fw-normal"><CountUp end={features[2].number} duration={1} delay={.1} start={0} /></span> : ''
                                                                        
                                                                    }
                                                                </TrackVisibility>
                                                                <TrackVisibility once>
                                                                    { ( { isVisible } ) => isVisible && features[2].isDecimal === true ? <span className="total text-primary fw-normal"><CountUp end={features[2].number} duration={ 1 } delay={.1} start={ 0 } decimals={ 1 } />/5.0</span> : ''
                                                                        
                                                                    }
                                                                </TrackVisibility>
                                                                <p className='text-secondary'>{ features[2].title }</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {  mentor.video !== null && <div style={{padding: '56.25% 0 0 0', position: 'relative'}} className="mt--40"><iframe src={`${mentor.video}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture"  style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%'}} title="Video"></iframe></div> }



                                        <h5 className='title text-primary h5 mb-3 mt--50'>Tags</h5>
                                        <div className="blog-tag-and-share">
                                                { mentor.tags && mentor.tags.length > 0 &&
                                                    <div className="blog-tag">
                                                        <div className="tag-list bg-shade">
                                                            {
                                                                mentor.tags.map( ( tag, i ) => {
                                                                    return (
                                                                        <span className='fw-bold' key={ randomNumber * i + 2 } >
                                                                            { tag }
                                                                        </span>
                                                                    )
                                                                } )
                                                            }
                                                            {
                                                                mentor.categorias.map( ( categoria, i ) => (
                                                                    <span className='fw-bold' key={  randomNumber * i + 3  } >
                                                                        { categoria }
                                                                    </span>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            
                                            </div>

                                            {articles.length > 0 && (
                                                <div className='col'>
                                                    <h5 className='title text-primary h5 mb-5 mt--50'>Artigos do mentor</h5>
                                                    {articles.map((article) => (
                                                        <div className="row gap-2 mt-5" key={ article.uuid }>
                                                            <div className="thumbnail col-4">
                                                                <Link className="d-block h-100" to={process.env.PUBLIC_URL + `/blog/${article.slug}`}>
                                                                    <img src={`${article.foto_url}`} alt="Blog Thumb" className='radius' style={{objectFit:'cover', width: '100%', height:'100%'}} />
                                                                </Link>
                                                            </div>
                                                            <div className="post-content col">
                                                                <h6 className="title mb-1">
                                                                    <Link  to={process.env.PUBLIC_URL + `/blog/${article.slug}`} style={styleLink}>{article.titulo}</Link>
                                                                </h6>
                                                                <p className='my-0 lh-base fs-4' style={{textAlign:'justify'}}>{article.descricao.substring(0,90) + '...'}</p>
                                                                <Link className="edu-btn my-3 py-2 lh-base" style={{height:'auto'}} to={process.env.PUBLIC_URL + `/blog/${article.slug}`}>
                                                                    Leia mais
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default MentorDetalhes;