import imgHero from '../../../assets/images/imersao-jose/img-hero.png';
import imgH1 from '../../../assets/images/comunicador-expressivo/lampada.svg';
import { FaArrowCircleRight } from 'react-icons/fa';

const HeroSection = () => {
    return(
        <div className="container-fluid pt--50 px-0 mx-0" id="imersao-comunicador-expressivo-hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 pt--100">
                        <p className="fs-1 fw-bold mb-0">Transforme Sua Comunicação com a Imersão</p>
                        <div className='d-flex align-items-center justify-content-start'>
                            <div>
                            <div className="img-box-h1 me-3">
                                <img src={imgH1} alt='imagem hero section' />
                            </div>
                            </div>
                            <div>
                                <h1>COMUNICADOR EXPRESSIVO</h1>
                            </div>
                        </div>
                        <p className='fs-3 fw-thin mb-5'>Desenvolva sua comunicação e oratória para ser mais <br /> assertivo e expressivo - com José Bergossi</p>
                        <a className='hero-btn' href='https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/comunicador-expressivo'><FaArrowCircleRight /> Garanta a sua vaga</a>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="img-box">
                            <img src={imgHero} alt='imagem hero section' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid px-0 mx-0' id='banner-hero'>
                <div className='container px-0 px-md-5'>
                    <div className='row px-0 px-md-5'>
                        <div className='col-12 pt-5 px-0 px-md-5'>
                            <p className='text-center lh-base px-5 mx-5'>Prepare-se para elevar suas habilidades de comunicação ao próximo nível com a imersão <br />"COMUNICADOR EXPRESSIVO"! Sob a orientação de José Bergossi, você irá explorar técnicas avançadas para melhorar sua presença e impacto em qualquer situação.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;