import robo from '../../../assets/images/bg/rob.png'

const ImpulsoMain = () => {

  return (
    <section id="impulso-main">
      <div className="box-l">
      <h1 className='impulso-h1'>Impulso: Acelere sua Empresa</h1>
      <p>Dê as boas-vindas ao Impulso!</p>
      <p>A Impulso é uma IA generativa que oferece orientações personalizadas, combinando tecnologia avançada e expertise empresarial. Com ele, sua empresa obtém a ferramenta necessária para acelerar rumo ao sucesso!</p>
      </div>
      <div className="box-r">
        <div className="box-image">
          <img src={robo} alt="imagem robô" id="image-robo" />
        </div>
      </div>
    </section>
  )
}

export default ImpulsoMain