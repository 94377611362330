import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import avatar from '../../assets/images/icons/avatar.jpg'

const PostBlog = ( { data, classes, bgWhite } ) => {
    const styleLink = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        minHeight: '89px',
        lineHeight: '31px'
    };

    return (
        <div className={`edu-blog blog-type-2 radius-small ${classes ? classes : '' } ${bgWhite === 'enable' ? 'bg-color-white' : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/blog/${data.slug}`}>
                        <img src={`${data.foto_url}`} style={{height:'259px', objectFit: 'cover'}} alt="Blog Thumb" />
                    </Link>
                    <img src={!data.autor.foto ? avatar : data.autor.foto} style={{
                        width:'60px', 
                        height:'60px', 
                        borderRadius:'50%', 
                        objectFit:'cover',
                        position:'relative',
                        zIndex:'9',
                        left:'10px',
                        bottom:'45px'}} alt='autor avatar' />
                    <span style={{
                        position:'relative',
                        left:'-50px',
                        top:'5px'
                    }}>{data.autor.nome}</span>
                </div>
                <div className="content pt-0">
                    <div className="status-group">
                        <Link className="eduvibe-status status-05" to={process.env.PUBLIC_URL + `/blog/${data.slug}`}>
                            <i className="icon-price-tag-3-line"></i> {data.categoria}
                        </Link>
                    </div>
                    <h5 className="title">
                        <Link style={styleLink} to={process.env.PUBLIC_URL + `/blog/${data.slug}`} title={data.titulo}>{data.titulo}</Link>
                    </h5>
                    <div className="blog-card-bottom">
                        <ul className="blog-meta">
                            <li><i className="icon-calendar-2-line"></i>{ data.dt_post }</li>
                        </ul>
                        <div className="read-more-btn">
                            <Link className="btn-transparent" to={process.env.PUBLIC_URL + `/blog/${data.slug}`}>Leia mais<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostBlog;