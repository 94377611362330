import React from 'react';
import icon1 from '../../assets/images/icons/award.svg';
import icon2 from '../../assets/images/icons/person.svg';
import icon3 from '../../assets/images/icons/ubuntu.svg';
import icon4 from '../../assets/images/icons/target.svg';

const items = [
    {
        id: "01231",
        info: 'Nossos cursos irão te ajudar a se sentir mais preparado para conquistar novas oportunidades de carreira.',
        icon: icon1
    },
    {
        id: "01232",
        info: 'Oferecemos mentores capacitados como líderes, executivos e empresários pare te orientar.',
        icon: icon2
    },
    {
        id: "01233",
        info: 'Te ajudamos a encontrar as melhores vagas de trabalho e estudos.',
        icon: icon3
    },
    {
        id: "01234",
        info: 'Conectamos você a pessoas que já tiveram experiências no setor, inclusive no exterior.',
        icon: icon4
    },
];

const HomeOneAbout = () => {
    return (
        <div
            name="about-us"
            className="edu-about-area about-style-1 edu-section-gap bg-color-white"
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                    <h4 className='about-title'>Somos a Academy, <text>seu hub de aprendizado e orientação</text> para você conquistar novas oportunidades e impulsionar sua carreira</h4>
                    </div>
                    <div className='col-1'></div>
                    
                    <div className='col-12 col-lg'>
                        <div className='row'>
                            {items.map((item) => (
                                <div key={item.id}
                                className="col-12 col-md-6 d-flex align-items-start flex-row h90 mb-4 gap-4"
                                >
                                    <div className='col-2'>
                                        <img src={item.icon} alt='icon' />
                                    </div>
                                    <div className='col-9'>
                                        <p style={{fontSize:"18px"}}>{item.info}</p>
                                    </div>
                
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeOneAbout;