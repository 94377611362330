import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseInfoAcademy from '../../components/course/CourseInfoAcademy';
import RelatedCoursesAcademy from '../../components/course/RelatedCoursesAcademy';
import CourseData from '../../data/course/CourseData.json';
//import InstructorData from '../../data/instructor/InstructorData.json';
import CurriculumTabContent from '../../data/course/CurriculumTabContent.json';
import axios from 'axios';

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{children}</button>
}

const CurriculumContent = ({modulos}) => {
    const [activeId, setActiveId] = useState( '0' );

    function toggleActive( id ) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
            {
                modulos.map( ( accordion, i ) => {
                    if(accordion.topicos.length==0){
                        return (<></>);
                    }
                    
                    return (
                        <>
                            <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item ${activeId === i.toString() ? 'bg-active' : ''}`}>
                                <div className="edu-accordion-header">
                                    <CustomToggle eventKey={i.toString()}>{accordion.nome}</CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                    <ul>
                                        { 
                                            accordion.topicos.map( ( title, index ) => (
                                                <li key={index}>
                                                    <div className="text"><i className="icon-draft-line"></i>{title}</div>
                                                    <div className="icon"><i className="icon-lock-password-line"></i></div>
                                                </li>
                                            ) )
                                        
                                        }
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </>
                    )
                } )
            }
        </Accordion>
    )
}

const CursoDetalhe = () => {
    const { slug } = useParams();
    //const courseId = parseInt( id, 10 );
    //const data = CourseData.filter( course => course.id === courseId );
    //const courseItem = data[0];

    // const indexOfInstructor = InstructorData.findIndex( function( instructor ) {
    //     return slugify( instructor.name ) === slugify( courseItem.instructor );
    // } );
    // const instructor = InstructorData[indexOfInstructor];
    // const instructorExcerpt = instructor.details.substring(0, 190) + "...";

    const [contentTab, setContentTab] = useState( 'overview' );
    const handleTab = ( content ) => {
        if ( content === 'overview' ) {
            setContentTab( 'overview' );
        } else if ( content === 'curriculum' ) {
            setContentTab( 'curriculum' );
        } else if ( content === 'instructor' ) {
            setContentTab( 'instructor' );
        } else if ( content === 'reviews' ) {
            setContentTab( 'reviews' );
        }
    }

    const [cursoItem, setCursoItem] = useState({
        nome: '',
        modulos: [],
        professores: [],
        precos: [],
        avaliacoes: [],
    });

    useEffect( () => {
        axios.get(`https://api.academyabroad.com.br/api/site/cursos/${slug}`)
            .then(response => {
                setCursoItem(response.data.data);
                setContentTab( 'overview' );
            })
            .catch(error => {
                console.error(error);
            });
    }, [slug] );

    return (
        <>
            <SEO title={ cursoItem.nome } />
            <Layout>
               
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        {/* <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="main-image thumbnail">
                                    <img className="radius-small" src={`${process.env.PUBLIC_URL}/images/course/course-details/${courseItem.image}`} alt="Course Thumb" />
                                </div>
                            </div>
                        </div> */}

                        <div className="row g-5">
                            <div className="col-xl-8 col-lg-7">
                                <div className="course-details-content">

                                    

                                    <h3 className="title">{ cursoItem.nome }</h3>
                                    <ul className="edu-course-tab nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'overview' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Overview"
                                                onClick={() => handleTab('overview')}
                                            >
                                                Descrição
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'curriculum' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Curriculum"
                                                onClick={() => handleTab('curriculum')}
                                            >
                                                Módulos
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'instructor' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Instructor"
                                                onClick={() => handleTab('instructor')}
                                            >
                                                Professores
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Reviews"
                                                onClick={() => handleTab('reviews')}
                                            >
                                                Avaliações
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        { contentTab === 'overview' && 
                                            <div className={`tab-pane fade show ${contentTab === 'overview' ? 'active' : '' } `}>
                                                <div className="course-tab-content" dangerouslySetInnerHTML={{__html: cursoItem.descricao_html?cursoItem.descricao_html:cursoItem.descricao}} />
                                            </div>
                                        }

                                        { contentTab === 'curriculum' && 
                                            <div className={`tab-pane fade show ${contentTab === 'curriculum' ? 'active' : '' } `}>
                                                <div className="course-tab-content">
                                                    <CurriculumContent modulos={cursoItem.modulos} />
                                                </div>
                                            </div>
                                        }
                                        
                                        { contentTab === 'instructor' && 
                                            <div className={`tab-pane fade show ${contentTab === 'instructor' ? 'active' : '' } `}>
                                                <div className="course-tab-content">
                                                {
                                                    cursoItem.professores.map( ( professor, i ) => (
                                                        <div className="course-author-wrapper" key={i}>
                                                            { professor.foto_principal_url && 
                                                                <div className="thumbnail">
                                                                    {/* <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( courseItem.instructor ) }`}> */}
                                                                        <img src={professor.foto_principal_url} alt="Author Thumb" />
                                                                    {/* </Link> */}
                                                                </div> 
                                                            }
                                                            <div className="author-content">
                                                                <h6 className="title">
                                                                    {/* <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( courseItem.instructor ) }`}> */}
                                                                    {professor.nome}
                                                                    {/* </Link> */}
                                                                </h6>
                                                                {/* <span className="subtitle">{instructor.designation}</span> */}
                                                                {/* <p>{ instructorExcerpt }</p> */}
                                                                <ul className="social-share border-style">
                                                                    {professor.facebook_url && <li><a href={professor.facebook_url}><i className="icon-Fb"></i></a></li>}
                                                                    {professor.linkedin_url && <li><a href={professor.linkedin_url}><i className="icon-linkedin"></i></a></li>}
                                                                    {/* <li><a href={instructor.pinterest}><i className="icon-Pinterest"></i></a></li>
                                                                    <li><a href={instructor.twitterUrl}><i className="icon-Twitter"></i></a></li> */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                            </div>
                                        }

                                        { contentTab === 'reviews' && 
                                            <div className={`tab-pane fade show ${contentTab === 'reviews' ? 'active' : '' } `}>
                                                <div className="course-tab-content">
                                                    <div className="row row--30">
                                                        <div className="col-lg-4">
                                                            <div className="rating-box">
                                                                <div className="rating-number">{cursoItem.nota}</div>
                                                                <div className="rating eduvibe-course-rating-stars">
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                    <i className="icon-Star"></i>
                                                                </div>
                                                                {/* <span>({courseItem.review} Review)</span> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <div className="review-wrapper">

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        5 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '100%'} } aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">1</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        4 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        3 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        2 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>

                                                                <div className="single-progress-bar">
                                                                    <div className="rating-text">
                                                                        1 <i className="icon-Star"></i>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" style={ {width: '0%'} } aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                    <span className="rating-value">0</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { cursoItem.avaliacoes.length > 0 && <div className="comment-wrapper pt--40">
                                                        <div className="section-title">
                                                            <h5 className="mb--25">Avaliações</h5>
                                                        </div>
                                                        {cursoItem.avaliacoes.map( ( avaliacao, i ) => (
                                                            <div className="edu-comment">
                                                                {/* <div className="thumbnail">
                                                                    <img src="/images/course/student-review/student-1.png" alt="Student Thumb" />
                                                                </div> */}
                                                                <div className="comment-content">
                                                                    <div className="comment-top">
                                                                        <h6 className="title">{avaliacao.user.nome}</h6>
                                                                        <div className="rating eduvibe-course-rating-stars">
                                                                            <i className="icon-Star"></i>
                                                                            <i className="icon-Star"></i>
                                                                            <i className="icon-Star"></i>
                                                                            <i className="icon-Star"></i>
                                                                            <i className="icon-Star"></i>
                                                                        </div>
                                                                    </div>
                                                                    {/* <span className="subtitle">“ Outstanding Course ”</span> */}
                                                                    <p>{avaliacao.avaliacao}</p>
                                                                </div>
                                                            </div>
                                                        ) ) }
                                                    </div> }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                {cursoItem.nome != '' && <CourseInfoAcademy data={cursoItem}/>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <RelatedCoursesAcademy courseID={ cursoItem.uuid } />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default CursoDetalhe;