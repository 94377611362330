import icon1 from '../../../assets/images/icons/online.svg'
import icon2 from '../../../assets/images/icons/calendar.svg'
import icon3 from '../../../assets/images/icons/visit.svg'
import icon4 from '../../../assets/images/icons/learn.svg'
import icon5 from '../../../assets/images/icons/book-2.svg'
import icon6 from '../../../assets/images/icons/cap.svg'


const ImersaoHowItWorks = ({data}) =>{

  const icones = [icon1, icon2, icon3, icon4, icon5, icon6]

  return(
    <div className="bhi-how-it-works container-fluid d-flex flex-column align-items-center justify-content-around ptb--100">
      <div className="container text-center">
        <span className='text-light fw-normal'>conheça as etapas</span>
        <h2 className="h4" style={{color:'var(--color-gold)'}}>Como funciona o programa</h2>
      </div>
      <div className="container row m-0 p-0">
        <div className='row'>
          {data.etapas && data.etapas.map((item, i) => (
            <div key={i} className="col-12 col-sm-6 col-lg-4 p-4 mt-5">
              <div className='bg-white radius p-3 text-center h-100 w-100'>
                <span 
                style={{
                  backgroundColor:'var(--color-gold)',
                  borderRadius:'12px',
                  padding:'25px',
                  position:'relative',
                  top:-15,
                }}>
                  <img src={icones[i]} alt='icon' width='35px' height='35px'/>
                </span>
                <h5 className='h5 mt-3 fw-normal' style={{color:'var(--color-gold)'}}>{item.titulo}</h5>
                <p>{item.descricao}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ImersaoHowItWorks;