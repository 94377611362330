
import icon1 from '../../assets/images/icons/oportunidades.svg';
import icon2 from '../../assets/images/icons/curriculo.svg';
import icon3 from '../../assets/images/icons/construção.svg';
import icon4 from '../../assets/images/icons/email.svg';
import { FaArrowRight } from 'react-icons/fa';

const data = [
    {
        icon: icon1,
        title: "Oportunidades Personalizadas",
        text: "Com base na análise detalhada do seu perfil, forneceremos uma lista de e-mails e oportunidades exclusivas que se alinham perfeitamente com seus objetivos médicos internacionais."
    },
    {
        icon: icon2,
        title: "Análise de Currículo",
        text: "Trabalhamos lado a lado com você para analisar e aprimorar seu currículo, destacando suas conquistas e experiências relevantes para impressionar instituições médicas no exterior."
    },
    {
        icon: icon3,
        title: "Construção do Currículo em Conjunto",
        text: "Nossa equipe o guiará na construção de um currículo médico excepcional que reflete sua jornada única e capacidades."
    },
    {
        icon: icon4,
        title: "Análise do E-mail de Inscrição",
        text: "Prepare-se para causar uma excelente primeira impressão. Analisaremos e otimizaremos seu e-mail padrão de inscrição para maximizar suas chances de ser selecionado."
    }
]

const FeatureExperience = () => {
    return(
        <div className="container-fluid bg-primary pt--100">
            <div className="container">
                <div className="row">
                    {data && data.map((item, i) => (
                        <div className='col-12 col-md-6 col-lg-3 p-2' key={i}>
                            <div className='text-center p-5 d-flex flex-column align-items-center' style={{backgroundColor:'#09336690', height:'438px', borderRadius:'20px'}}>
                                <div className='my-5' style={{width:'55px', height:'68px'}}>
                                    <img src={item.icon} alt='icon' style={{width:'100%', height:'100%', objectFit:'contain'}} />
                                </div>
                                <div style={{height:'80px'}}>
                                    <h5 className='fs-3 text-white'>{item.title}</h5>
                                </div>
                                <p className='text-white fs-5 fw-normal' style={{textAlign:'justify'}}>{item.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='d-flex align-items-center justify-content-center py-5 pb-0 mb-0'>
                    <a href='https://wkf.ms/3PwhOCn' className='edu-btn bg-white text-primary mb-0'>Inscrever agora <FaArrowRight /></a>
                </div>
                <div className='row py-5'>
                    <div className='shadow p-5' 
                    style={{
                        backgroundColor:'var(--color-secondary)', 
                        borderRadius:'20px',
                        position:'relative',
                        top:'85px'}}>
                        <p className='text-white fs-4 text-center mb-0 lh-sm'>No "Experience Hunting", personalização e orientação são fundamentais. Vamos apoiá-lo em cada etapa do processo, garantindo que você esteja pronto para conquistar o mundo da medicina internacional. Sua jornada começa aqui, e estamos ansiosos para ajudá-lo a alcançar seus sonhos médicos.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureExperience