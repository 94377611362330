import React from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import FooterOne from "../../common/footer/FooterOne";
import ImpulsoMain from "./components/ImpulsoMain";
import ImpulsoSection2 from "./components/ImpulsoSection2";
import ImpulsoSection3 from "./components/ImpulsoSection3";
import ImpulsoSection from "./components/ImpulsoSection";
import ImpulsoSection6 from "./components/ImpulsoSection6";
import '../../assets/scss/impulso/_impulso.scss'
import img1 from '../../assets/images/screen/standard-1.jpg';
import img2 from '../../assets/images/screen/standard-2.jpg';
import img3 from '../../assets/images/screen/standard-3.jpg';
import img4 from '../../assets/images/screen/premium-1.jpg';
import img5 from '../../assets/images/screen/premium-2.jpg';
import img6 from '../../assets/images/screen/premium-3.jpg';


const Impulso = () => {
  return(
    <>
      <SEO title="Impulso" />
      <HeaderTwo styles=" header-style-2" />
      <ImpulsoMain />
      <ImpulsoSection2 />
      <ImpulsoSection3 />
      <ImpulsoSection
        title="Para Startups"
        id="section-4"
        text={`<p>Inove com a força da IA! Impulso é a parceira tecnológica que sua startup precisa para alcançar novas alturas. Com recursos inteligentes e adaptativos, nossa plataforma fornece uma abordagem dinâmica para a otimização de processos e crescimento sustentável. Desde análises profundas de mercado até a criação de estratégias de expansão, a IA do Impulso se adapta, aprende e responde às particularidades da sua startup.<br><br>Confira ao lado como essa solução pode ser aplicada diretamente ao seu negócio. Com o Impulso, você não está apenas adotando uma tecnologia - está abraçando uma revolução. Junte-se a nós e veja sua startup crescer!</p>`}
        img={[img1, img2, img3]}
        href="#planos"
        button="COMPRAR AGORA"
      />
      <ImpulsoSection 
        title="Para Empresa Júnior"
        id="section-5"
        text={`<p>Acelere o sucesso da sua Empresa Júnior com o Impulso, uma solução de IA feita sob medida para as organizações em desenvolvimento. O que distingue o Impulso é a habilidade de criar soluções adaptadas que entendem as complexidades e desafios únicos que as Empresas Júnior enfrentam. Desde o gerenciamento de projetos e tarefas até o desenvolvimento de estratégias de marketing, nós integramos inteligência artificial para fornecer insights poderosos e recomendações práticas.<br><br>Na imagem ao lado, explore como essa ferramenta pode ser implantada para transformar a maneira como sua Empresa Júnior opera. O Impulso não é apenas uma ferramenta - é um aliado no seu caminho para o sucesso. Experimente hoje e sinta o impacto!</p>`}
        img={[img4, img5, img6]}
        href="#planos"
        button="COMPRAR AGORA"
        reverse="reverse"
      />
      <ImpulsoSection6
      title="Gostaria de saber mais sobre a Academy Abroad?"
      description={'<p>Assine nossa Newsletter gratuitamente e fique por dentro das novidades!</p>'}
      link="https://materiais.theacademyabroad.com.br/impulso"
      btnText="ASSINATURA GRATUITA"
      />
      <FooterOne />
    </>
  )
}

export default Impulso;