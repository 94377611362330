import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeFilter from '../../components/course/CourseTypeFilter';
import MentorFilter from '../../components/course/MentorFilter';
import BannerPublicidade from '../../components/banner/BannerPublicidade';
import { Helmet } from 'react-helmet';

const Mentores = () => {
    return (
        <>
                <Helmet>
                <meta charSet="utf-8" />
                <title>Mentores || Academy Abroad</title>

                {/* Meta tags padrão */}
                <meta name="robots" content="noindex, follow" />
                <meta name="description" content="Conheça os mentores que podem te ajudar na sua carreira!" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {/* Open Graph (para redes sociais) */}
                <meta property="og:title" content="Mentores" />
                <meta property="og:description" content="Conheça os mentores que podem te ajudar na sua carreira!" />
                <meta property="og:image" content="https://academyabroad.com.br/static/media/1.67e9913cc95c5c837c2b.png" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:url" content={`https://academyabroad.com.br/mentores`} />
                <meta property="og:type" content="website" />

                {/* Twitter Cards */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={"Mentores"} />
                <meta name="twitter:description" content={"Conheça os mentores que podem te ajudar na sua carreira!"} />
                <meta name="twitter:image" content="https://academyabroad.com.br/static/media/1.67e9913cc95c5c837c2b.png" />

                {/* Canonical */}
                <link rel="canonical" href={`https://academyabroad.com.br/mentores`} />
                
            </Helmet>
            <Layout>
                {/* <BreadcrumbOne 
                    title="Course Filter 1"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course Filter 1"
                /> */}
                    <div className="edu-course-area course-three-wrapper edu-section-gap bg-color-white position-relative">
                        <BannerPublicidade />
                        <div className="container">
                            <MentorFilter itemToShow="9" />
                        </div>
                    </div>
            </Layout>
        </>
    )
}

export default Mentores;