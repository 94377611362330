import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import BannerOne from "../../components/banner/BannerOne";
import CallToActionFive from "../../components/cta/CallToActionFive";
import CallToActionFour from "../../components/cta/CallToActionFour";
import NewsletterThree from "../../components/newsletter/NewsLetterThree";
import CourseCarouselThree from "../course/CourseCarouselThree";
import CourseCarouselTwo from "../course/CourseCarouselTwo";
import imgElement from "../../assets/images/bg/elemento-horizontal-2.png"
import img1 from '../../assets/images/cta-2.png'
import icon1 from '../../assets/images/icons/badge.svg'
import icon2 from '../../assets/images/icons/g195.svg'
import icon3 from '../../assets/images/icons/chart.svg'
import CallToActionAbstrat from "../../components/cta/CallToActionAbstrat";
import img2 from '../../assets/images/img-carreira.png'
import img3 from '../../assets/images/CURRICULO.png'
import img4 from '../../assets/images/phone-3.png'



const data = [
  {
    icon: icon1,
    description: 'Encontre seu',
    title:'Mentor'
  },
  {
    icon: icon2,
    description: 'Agende sua',
    title:'Mentoria'
  },
  {
    icon: icon3,
    description: 'Aperfeiçoe suas',
    title:'Habilidades'
  }
]

const carreira = ["CARREIRA","INOVAÇÃO","IDIOMAS", "PESQUISA", "PREPARAÇÃO PARA PROVAS", "SAÚDE", "ORATÓRIA", "MARKETING", "ENGENHARIA", "DIREITO", "TI", "COMERCIAL"]

export default function ParaSuaCarreira(){
  return(
    <>
      <SEO title="Para Sua Carreira" />
      <HeaderTwo styles=" header-style-2" />
      <BannerOne
      title={`<h3 class="text-white"><span class="text-primary">Aprenda</span> novas habilidades</br>
              <span class="text-primary">Lance</span> um projeto</br>
              <span class="text-primary">Conquiste</span> a carreira dos seus sonhos</h3>`}
      description="A Academy é sua parceira ideal para impulsionar sua carreira! Oferecemos cursos e mentores especializados para desenvolver habilidades técnicas e soft skills essenciais. Conte conosco para avançar em sua profissão, mudar de área ou alcançar novas metas."
      bg="bg-carreira"
      isButton={false} 
      element={imgElement}/>
      <CallToActionFive
      data={data} />
      {/* <CourseCarouselThree 
      title="Principais mentores para sua Carreira" 
      categoria={carreira}/> */}
      <CallToActionAbstrat 
        img={img2}
        text={"Nossos mentores estão aqui para te preparar tanto para entrevistas de emprego quanto para experiências de intercâmbio"}
      />
      <CallToActionFour 
      title="Faça seu currículo direto pela nossa plataforma e compartilhe para buscadores de vaga!"
      description="Veja as melhores oportunidades para a sua carreira aqui:"
      link="https://painel.academyabroad.com.br/"
      button="Cadastrar currículo gratuitamente"
      img={img3} />
      <NewsletterThree
        hSize="h5"
        text="Quer impulsionar sua carreira? Cadastre-se e nossa newsletter e receba os melhores e-mail para alavancar sua carreira"
        btnText="Cadastrar gratuitamente"
        imgPhone={img4}/>
      <CourseCarouselTwo title="Principais cursos para sua Carreira"/>
      <CallToActionFour 
      title="Somos a ponte entre talentos em busca de oportunidades e empresas em busca de candidatos."
      description="Veja as melhores oportunidades para a sua carreira aqui:"
      link="https://painel.academyabroad.com.br/"
      button="Cadastrar gratuitamente"
      img={img1} />
      <FooterOne />
    </>
  )
}