import img1 from '../../assets/images/beneficios-1.png'
import img2 from '../../assets/images/beneficios-2.png'

const items = [
  {
    id: 1,
    title: "Escolha seu mentor",
    text: "Conheça nossos mentores disponíveis e avalie qual deles será mais adequado para o seu momento atual."
  },
  {
    id: 2,
    title: "Agende seu horário",
    text: "Efetue o pagamento para as mentorias e agende o melhor horário para você."
  },
  {
    id: 3,
    title: "Transforme seu futuro",
    text: "Pronto! É só começar a sua jornada para o futuro!"
  }
]

export default function HowItWorksTwo(){
  return(
    <div className="container">
      <div className="row ptb--120">
        <div className="col-12 col-lg-5">
            <div className="row">
              <p className="fs3 mb-0 fw-bolder">como funcionam nossas</p>
              <h3 className="h3 text-primary">Mentorias</h3>
            </div>
            {
              items.map((item) => (
                <div key={item.id}
                 className="row mb-5 how-it-works-card-box">
                  <div className='col-2 text-white fw-bold fs-2 d-flex align-items-center justify-content-center how-it-works-card-item'>{item.id}</div>
                  <div className='col bg-color-secondary-alt py-4 how-it-works-card-text'>
                    <h5 className='text-primary mb-0 lh-lg'>{item.title}</h5>
                    <p className='fs-5 mb-3'>{item.text}</p>
                  </div>
                </div>
              ))
            }
        </div>
        <div className="col-12 col-lg-7 d-flex how-it-works-img-box">
          <div className="how-it-works-img align-self-start" id='image-1'>
            <img src={img1} alt="team"/>
          </div>
          <div className="how-it-works-img align-self-end" id='image-2'>
            <img src={img2} alt="team" />
          </div>
        </div>
      </div>
    </div>
  )
}