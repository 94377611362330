import { FaCheckSquare } from 'react-icons/fa';
import img1 from '../../../assets/images/imersao-trilingue/Ativo 1.png';
import img2 from '../../../assets/images/imersao-trilingue/Prancheta 1 1.png';

const InvestSection = () => {
    return(
        <div className="container-fluid ptb--50" id="invest-section">
            <div className="container">
                <div className="row mb-5">
                    <div className='col-12 col-md-6'>
                        <div className='box-image m-auto'>
                            <img src={img1} alt='imagem investimento' />
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <h3 className='text-white mb-0 lh-sm'>INVESTIMENTO</h3>
                        <h3 className='text-primary mb-0 lh-sm'>R$1.100,00</h3>
                        <h5 className='text-white mb-0 lh-sm'>ou 12x de R$91,66</h5>
                        <span className='text-white fs-5'>Aproximadamente R$84,00 por aula</span>
                        <p className="text-primary fw-bold fs-3">Junte a sua turma e ganhe 5% de desconto para cada participante!</p>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12 col-md-6 ps-5'>
                        <div className='ms-5 mt-5 ps-5'>
                            <p className='text-white fs-5 lh-sm mb-5'>Não perca a chance de se tornar fluente em um novo idioma e abrir portas para oportunidades internacionais! Inscreva-se agora no Programa de Imersão Trilíngue com a Academy Abroad e inicie sua jornada multilíngue de sucesso.</p>
                            <a href='https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/descubra-o-idioma-ideal-para-voce' className='edu-btn'><FaCheckSquare />Inscreva-se agora</a>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                    <div className='box-image'>
                            <img src={img2} alt='imagem investimento' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestSection;