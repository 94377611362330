import img1 from '../../../assets/images/comunicador-expressivo/jose-3.png'
const BenefitsSection = () => {
    return(
        <div className="container-fluid pt--50" id="comunicador-expressivo-benefits-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 pt--100">
                        <h4 className='mb-5'>Benefícios da Imersão</h4>
                        <div>
                            <h6 className='mb-0 lh-sm'>Transformação Pessoal e Profissional: </h6>
                            <p>Melhore suas apresentações e comunicações diárias.</p>
                        </div>
                        <div>
                            <h6 className='mb-0 lh-sm'>Técnicas Práticas e Imediatas: </h6>
                            <p>Aplicação direta das técnicas para resultados rápidos.</p>
                        </div>
                        <div>
                            <h6 className='mb-0 lh-sm'>Orientação de um Especialista: </h6>
                            <p>Aprenda diretamente com um dos maiores especialistas em comunicação.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='image-box'>
                            <img src={img1} alt='imagem José Bergossi' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BenefitsSection;