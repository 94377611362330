import { FaChevronRight, FaChevronDown } from "react-icons/fa";

const CallToActionFive = ({data}) => {
  return(
    <div className="container-fluid d-flex align-items-center justify-content-center pt--50 pb--100" style={{backgroundColor:'#ECEEFF'}}>
      <div className="container row d-flex align-items-center justify-content-between">
        <div className="col-12 col-lg-10 d-flex align-items-center justify-content-between flex-column flex-md-row gap-4 gap-md-0">
          {data.map((item) => (
            <>
              <div className="col-5 col-md-3 text-center d-flex align-items-center justify-content-between py-md-4 py-3 px-4 h-100 flex-column flex-lg-row" 
                key={item.title} 
                style={{minHeight:'154px', backgroundColor:'#FAFAFA', borderRadius:'13px'}}>
                  
                <div className="col-12 col-sm-4 py-2 px-2 h-50">
                  <img src={item.icon} alt='icon' style={{height:'100%', maxHeight:'64px'}} />
                </div>
                <div className="col-12 col-sm-7">
                  <p className='mb-0 text-center text-lg-start fs-5 fs-lg-4'>{item.description}</p>
                  <p className='mb-0 fw-bold text-center text-lg-start fs-4'>{item.title}</p>
                </div>
              </div>

            <div className="col-1 d-md-flex align-items-center justify-content-center d-none">
              <FaChevronRight color="#093366"/>
              <FaChevronRight color="#093366"/>
            </div>

            <div className="col-1 d-flex flex-column align-items-center justify-content-center d-md-none">
              <FaChevronDown color="#093366"/>
              <FaChevronDown color="#093366"/>
            </div>
            </>
          ))}
        </div>
        <div className="col-12 col-lg-2 d-flex justify-content-lg-end justify-content-center pt-5 pt-lg-0">
          <a className="edu-btn fs-4 px-4 text-center" href="https://painel.academyabroad.com.br/" style={{width:'170px'}}>Cadastre-se</a>
        </div>
      </div>
        
    </div>
  )
}

export default CallToActionFive