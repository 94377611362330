import Slider from "react-slick"
import { FaChevronLeft, FaChevronRight, FaLinkedin } from "react-icons/fa";


const dataSpeakers = [
  {
    "avatar": require("../../../assets/images/bhi-ceos/barbara.png"),
    "name": "Bárbara C.S. Pinto, MBA",
    "occupation": "Founder e CEO da NestSaúde, com MBA de Gestão e Liderança empresarial na Babson College. ",
    "embed": "https://www.linkedin.com/in/barbaraspinto/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/wendel-afonso.png"),
    "name": "Wendel Afonso, PhD",
    "occupation": "Founder da Harmony Baby Nutrition. Pesquisador do MIT",
    "embed": "/"
  },  
  {
    "avatar": require("../../../assets/images/bhi-ceos/jennifer.png"),
    "name": "Jennifer Gentile, PhD",
    "occupation": "Co-founder e COO da Think. Psicóloga no Boston Children's, com fellow na Harvard Medical School",
    "embed": "https://www.linkedin.com/in/jennifer-gentile-0913a8157/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/vitor-pamplona.png"),
    "name": "Vitor Pamplona, PhD",
    "occupation": "Founder e CEO da EyeNetra. Pesquisador do MIT e fundador do PUB Boston",
    "embed": "https://www.linkedin.com/in/vitorpamplona/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/charles-pozner.png"),
    "name": "Charles Pozner, MD, PhD",
    "occupation": "CMO do PC Institute for Medical Education, ex-presidente da American Heart Association",
    "embed": "https://www.linkedin.com/in/charles-pozner-b0117316/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/sean-matsuoka.png"),
    "name": "Sean Matsuoka, PhD",
    "occupation": "Co-Founder na General Prognostics (GPx), com PhD na Harvard Business School ",
    "embed": "https://www.linkedin.com/in/seanmatsuoka/"
  },
  {
    "avatar": require("../../../assets/images/bhi-ceos/vivek-menon.png"),
    "name": "Vivek Menon, BA",
    "occupation": "Co-Founder do ELITE HRV (Spren). Economista pelo Dartmouth College.",
    "embed": "https://www.linkedin.com/in/vvkmenon/"
  },
]

const BhiFounders = ({descritivo}) => {

  const PrevArrow = ( props ) => {
    const { onClick } = props;
    return (
        <button className="slide-arrow prev-arrow" onClick={ onClick } >
            <FaChevronLeft className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}

const NextArrow = (props) => {
    const { onClick } = props;
    return  (
        <button className="slide-arrow next-arrow" onClick={ onClick } >
            <FaChevronRight className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}

  const ThreeColumnCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
          breakpoint: 1199,
          settings: {
              slidesToShow: 2
          }
      },
      {
          breakpoint: 767,
          settings: {
              slidesToShow: 1,
          }
      }
  ]
   
}

  return(
    <div className="container-fluid d-flex-align-items-center justify-content-around ptb--100" style={{backgroundColor:'var(--color-bhi-blue)'}}>
      <div className="container text-center">
        <h3 className="h3" style={{color:'var(--color-gold)'}}>Founders</h3>
        <div className='row text-center' dangerouslySetInnerHTML={{__html: descritivo}}></div>
      </div>
      <div className="container" style={{maxWidth:'1400px'}}>
        <Slider
        className="slick-arrow-style-3 px-5 ms-5"
        {...ThreeColumnCarousel}>

          {dataSpeakers && dataSpeakers.map((item, i) => (
            <div className="col-3 p-2">
              <div key={i} className="speakers-card radius shadow bg-white text-center px-3 py-4">
                <div 
                className="radius m-auto" 
                style={{
                  backgroundColor:'#093366',
                  height:'150px',
                  width:'150px',
                  borderRadius:'50%'
                }}>
                  <img 
                  style={{
                    width:'100%', 
                    height:'100%', 
                    objectFit:'cover',
                    borderRadius:'50%'}} 
                  src={item.avatar} 
                  alt={item.name + ' avatar'}/>

                </div>
                <h5 className="mt-3" style={{color:'var(--color-gold)'}}>{item.name}</h5>
                <div className="ocupation-section">
                  <p className="fw-normal lh-base">{item.occupation}</p>
                </div>
                <a className="align-self-end" href={item.embed} target="_blank" rel="noreferrer">
                  <FaLinkedin style={{fontSize:'40px', color:'var(--color-gold)'}} />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
export default BhiFounders