const FacSection = () => {
    return(
        <div className="container-fluid ptb--50" id="comunicador-expressivo-faq-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 pt--100">
                        <h4 className='mb-5'>Dúvidas frequentes</h4>
                        <div className="card-faq-section">
                            <h6 className='mb-3 lh-sm'>Como posso pagar?</h6>
                            <p className="mb-1">Aceitamos pagamentos via cartão de crédito podendo parcelar em até 6x</p>
                        </div>
                        <div className="card-faq-section">
                            <h6 className='mb-3 lh-sm'>Há algum pré-requisito?</h6>
                            <p className="mb-1">Não, a imersão é aberta para todos os níveis de experiência.</p>
                        </div>
                        <div className="card-faq-section">
                            <h6 className='mb-3 lh-sm'>O que está incluído?</h6>
                            <p className="mb-1">Inclui todas as sessões ao vivo, materiais de apoio e uma aula com também mentores Rafael Kenji e Thais Scharfenberg sobre como ingressar na ONU.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacSection;