
export default function NewsletterThree({hSize, text, btnText, imgPhone }){
  return(
    <div className="container-fluid bg-primary newsletter-three-container">
      <div className="container row d-flex justify-content-between">
        
        <div className="col-12 col-lg-6 " style={{height:'325px'}}>
          <div className="col d-flex flex-column align-items-center align-items-lg-start">

            <h4 className={`text-white text-center text-lg-start my-5 fw-normal ${hSize}`}>{text}</h4>

            <a href='https://materiais.theacademyabroad.com.br/interesse-e-mail' 
                target='_blank' 
                rel="noreferrer" 
                style={{width:'330px',textAlign:'center', padding:'12px'}}
                className='edu-btn cta-four-btn pt-0 my-5'>{btnText}
            </a>

          </div>
        </div>

        <div
        className="col-12 col-lg-5 newsletter-three-img" style={{height:'356px'}}>
          <img src={imgPhone} alt="Telefone" />
        </div>
      </div>
    </div>
  )
}