import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Filters from '../../components/course/Filters';
import CourseItemsForFilter from '../../components/course/CourseItemsForFilter';
import { CoursesProvider } from '../../context/courses_context';
import { FilterProvider } from '../../context/filter_context';
import MentorFilter from '../../components/course/MentorFilter';
import EditaisFilter from '../../components/course/EditaisFilter';

const EditalIntercambio = () => {
    return (
        <>
            <SEO title="Experiências" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Course Filter 1"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course Filter 1"
                /> */}
                    <div className="edu-course-area course-three-wrapper edu-section-gap bg-color-white position-relative">
                        <div className="container">
                            <EditaisFilter itemToShow="9" />
                        </div>
                    </div>
            </Layout>
        </>
    )
}

export default EditalIntercambio;