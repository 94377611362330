import axios from "axios";
import { useEffect, useState } from "react";
import Slider from "react-slick";

const BannerPublicidade = () => {
    const [banners, setBanners] = useState()

    const handlePage = (pagina) => {
        const pageLocation = pagina.split('/')
        if (pageLocation.length > 2){
          return pageLocation[3]
        }
      }
      
      const filtrarPublicidades = (data, valorEspecifico) => {
        const resultadosFiltrados = data.data.filter(objeto => {
          const paginaValida = objeto.paginas && objeto.paginas.includes(valorEspecifico);
          const siteValido = objeto.site === 1;
          return paginaValida && siteValido;
        });
        return resultadosFiltrados;
      };
      
      const pagina = handlePage(window.location.href)

    useEffect(() => {
        axios.get('https://api.academyabroad.com.br/api/site/publicidades')
        .then(response => {
            console.log(filtrarPublicidades(response, pagina))
            setBanners(filtrarPublicidades(response, pagina))
        })
        .catch(error => console.log(error))
    },[])

    const ThreeColumnCarousel = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,

    };
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Slider className="mt--40 mb--50 slick-arrow-style-3 slick-activation-wrapper eduvibe-course-one-carousel px-5"
                      { ...ThreeColumnCarousel }>
                        {banners && banners.map((item) => (
                            <div className="col-12 rounded" key={item.id}>
                                <a href={item.link} target="_blank" rel="noreferrer">
                                    <img src={item.imagem_url} alt={item.titulo} style={{height:'100%', width:'100%', position:'relative', borderRadius:'12px'}} />
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default BannerPublicidade;