import img1 from '../../../assets/images/imersao-trilingue/Sem Título-2 1.png'
const PathSection = () => {
    return(
        <div className="container-fluid ptb--50" id="path-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h2 className='color-primary mt-5'>Após os Três Meses: Escolha Seu Caminho!</h2>
                        <p>Ao final dos três meses de imersão trilíngue, você terá a oportunidade de escolher o idioma que mais o cativou. Além disso, você pode continuar seus estudos no idioma escolhido, optando por aulas regulares 1, 2 ou 3 vezes por semana, de acordo com sua disponibilidade e ritmo de aprendizado.</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='box-image m-auto'>
                            <img src={img1} alt='imagem menina comemorando' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PathSection;