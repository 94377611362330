import SEO from "../../common/SEO"
import HeaderTwo from "../../common/header/HeaderTwo"
import BannerOne from "../../components/banner/BannerOne";
import CallToActionFour from "../../components/cta/CallToActionFour";
import NewsletterThree from "../../components/newsletter/NewsLetterThree";
import CourseCarouselThree from "../course/CourseCarouselThree";
import CourseCarouselTwo from "../course/CourseCarouselTwo";
import FooterOne from "../../common/footer/FooterOne";
import imgElement from "../../assets/images/bg/elemento-horizontal-2.png"
import img1 from '../../assets/images/cta-2.png'
import img2 from '../../assets/images/phone-4.png'
import CallToActionSix from "../../components/cta/CallToActionSix";
import ImpulsoSection6 from "../../pages/impulso/components/ImpulsoSection6";



const negocio = ["INOVAÇÃO","BUSINESS", "RH", "PREPARAÇÃO PARA PROVAS", "ORATÓRIA", "MARKETING", "ENGENHARIA","SAÚDE", "DIREITO", "TI", "COMERCIAL"]

const ParaSeuNegocio = () => {
  return(
    <>
    <SEO title="Para Seu Intercambio" />
    <HeaderTwo styles=" header-style-2" />
    <BannerOne
    title={`<h2 class="text-white">Academy para o</br>
            <span class="text-primary">Seu Negócio</span></h2>`}
    description="A Academy oferece cursos e mentoria especializada em diversas áreas desde para capacitação de equipe até para otimização de processos empresariais. Nossos cursos e mentores especializados impulsionam a eficiência e a inovação, proporcionando crescimento e vantagem competitiva. Conte conosco para soluções adaptadas às suas necessidades empresariais."
    bg="bg-negocio"
    isButton={false} 
    element={imgElement}/>
    <CallToActionSix />
    <CourseCarouselThree 
      title="Mentores de Negócio"
      categoria={negocio} />
    <NewsletterThree
    hSize="h4"
    text="Cadastre em nossa Newsletter e seja receba as melhores dicas para alavancar seu negócio!"
    btnText="Cadastrar gratuitamente"
    imgPhone={img2}
    />
    <CourseCarouselTwo title="Principais cursos para seu Négocio" />
    <ImpulsoSection6
    title="Impulso: Acelere sua Empresa"
    description="<p>Dê boas vindas ao Impulso!</p><p>A Impulso é uma IA generativa que oferece orientações personalizadas, combinando tecnologia avançada e expertise empresarial. Com ele, sua empresa obtém a ferramenta necessária para acelerar rumo ao sucesso!</p>"
    link="https://materiais.theacademyabroad.com.br/impulso"
    btnText="Saiba mais sobre o Impulso"
    />
    <FooterOne />
    </>
  )
}

export default ParaSeuNegocio