import React from 'react';

const AboutOneExperiencia = ( props ) => {
    return (
        <div className={`edu-blog-widget ${ props.style2 === 'enable' ? 'edu-about-widget-2' : '' } widget-about ${ props.extraClass || '' }`}>
            <div className="inner">
                <div className="content">
                    <div className="about-wrapper">
                        <div className="thumbnail" >
                            <img src={props.data.foto_principal_url} alt="Blog Images" style={{maxHeight:'initial', borderRadius:'10px'}} />
                        </div>
                        <div className="about-content">
                            <h6 className="title">{props.data.nome}</h6>
                            {/* <p>Vesti at bulum nec the odio aea the dumm ipsumm ipsum that dolocons rsus malware suada fadolorit to consectetur</p>
                            <ul className="social-share icon-transparent justify-content-center">
                                <li><a href="#"><i className="icon-Fb"></i></a></li>
                                <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                <li><a href="#"><i className="icon-Twitter"></i></a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutOneExperiencia;