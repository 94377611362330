import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const TermosDeUso = () => {
  return (
    <>
      <SEO title="Termos de uso" />
      <Layout>

        <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="content">

                <h5><strong>ACADEMY ABROAD</strong></h5>
                <h6>TERMOS DE USO</h6>

                <span className="text-justify">
                  <p>Seja bem-vindo(a)!</p>

                  <p>Ficamos muito felizes por <strong>Você</strong> escolher a Plataforma <strong><strong>ACADEMY ABROAD</strong>!</strong></p>

                  <p>A Plataforma <strong>ACADEMY ABROAD</strong> oferece produtos e serviços para capacitação profissional por meio de videoaulas, ebooks, mentorias individuais e coletivas, cursos on-line, entre outros.</p>

                  <p>Por isso, criamos este Termo, que irá regular a relação para utilização da Plataforma, permitindo que <strong>Você</strong> tenha acesso ao conteúdo da Plataforma <strong>ACADEMY ABROAD</strong>, disponibilizada via Internet para determinados dispositivos conectados, tais como computadores, aparelhos móveis e/ou outros aparelhos compatíveis ("Aparelhos Compatíveis").</p>

                  <p>Ao utilizar a Plataforma, <strong>Você</strong> está concordando com este Termo. Por isso, é importante que <strong>Você</strong> o leia com muita atenção.</p>

                  <h4>Capítulo I - CONDIÇÕES GERAIS</h4>
                  <p><strong style={{ display: 'block' }}>1.1. Âmbito.</strong> O presente Termo e Condições Gerais de Uso (denominado somente "Termo" ou "Termo de Uso") vinculam todas as atividades desenvolvidas e serviços oferecidos pela <strong>ACADEMY ABROAD</strong> (doravante assim denominada ou somente "Plataforma"), que engloba o website e seus subdomínios, aplicativos, programas e demais extensões. A Plataforma é de propriedade de <strong>ACADEMY ABROAD SERVIÇOS DE EDUCAÇÃO LTDA.</strong>, pessoa jurídica inscrita no CNPJ sob o nº 39.652.030.0001-18, com sede na R. Rio Grande do Norte, 645 - Funcionários, Belo Horizonte/MG (<strong>"ACADEMY ABROAD"</strong>), e está sob sua responsabilidade e gestão."</p>

                  <p><strong style={{ display: 'block' }}>1.2. Atuação.</strong> A <strong>ACADEMY ABROAD</strong> é uma Plataforma desenvolvida e de propriedade exclusiva da <strong>ACADEMY ABROAD SERVIÇOS DE EDUCAÇÃO LTDA.</strong>, que tem por objetivo oferecer ao Usuário(a) serviços e produtos para capacitação profissional por meio de videoaulas, ebooks, mentorias individuais e coletivas, cursos on-line, entre outros, bem como auxílio na obtenção de uma vaga de intercâmbio.</p>

                  <p><strong style={{ display: 'block' }}>1.3. Sujeitos.</strong> Podem se cadastrar na plataforma:</p>
                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>1.3.1. Usuário(a) (<strong>Você</strong>):</strong> Pessoa física de qualquer idade que se cadastra, gratuitamente, na Plataforma <strong>ACADEMY ABROAD</strong>.  Caso o Usuário(a) seja menor de idade e incapaz, é imprescindível que este seja assistido ou representado por seus pais ou representantes legais, a fim de cumprir as disposições previstas em legislação civil aplicável.</p>

                  <p><strong style={{ display: 'block' }}>1.4. Adesão e Aceite.</strong> Pelo presente instrumento, <strong>Você</strong> concorda que:</p>
                  <p style={{ marginLeft: 20 }}><strong>(i)</strong> Deverá aceitar o Termo da <strong>ACADEMY ABROAD</strong>, para fins de cadastro, controle de acesso e execução de melhorias na Plataforma;</p>
                  <p style={{ marginLeft: 20 }}><strong>(ii)</strong> Clicando na caixa de aceitação do Termo deve aderir e concordar em se submeter ao disposto no presente instrumento.</p>

                  <p><strong style={{ display: 'block' }}>1.5. Violação das Condições.</strong> Caso <strong>Você</strong> venha a descumprir qualquer das condições aqui previstas, a <strong>ACADEMY ABROAD</strong> reserva-se o direito de encerrar sua conta e suspender ou anular seu acesso à Plataforma, sem necessidade de aviso prévio.</p>

                  <p><strong style={{ display: 'block' }}>1.6. Modificações.</strong> A <strong>ACADEMY ABROAD</strong> se reserva o direito de, a qualquer momento, modificar o Termo de Uso, sempre com o envio de avisos a <strong>Você</strong>, para ciência, sem qualquer responsabilização da <strong>ACADEMY ABROAD</strong> devido a quaisquer modificações efetuadas na Plataforma, que entrarão em vigor imediatamente após sua publicação <i>online</i>.</p>

                  <p><strong style={{ display: 'block' }}>1.7. Alterações e descontinuidade.</strong> A <strong>ACADEMY ABROAD</strong> poderá, a qualquer momento, de acordo com a sua disponibilidade negocial, alterar qualquer aspecto da Plataforma, inclusive quanto às suas características ou funcionalidades, bem como suspender, cancelar ou descontinuá-lo, no todo ou em parte, temporariamente ou em caráter definitivo, a seu critério exclusivo, mediante comunicação à <strong>Você</strong>, sem que isso caracterize inadimplemento deste Termo, ou direito de indenização ou multa em favor do mesmo, salvo estorno de valores que porventura tenham sido adimplidos antecipadamente.</p>

                  <h4>Capítulo II - MODO DE UTILIZAÇÃO</h4>
                  <p><strong style={{ display: 'block' }}>2.1. Cadastro na Plataforma.</strong> A Plataforma, denominada <strong>ACADEMY ABROAD</strong>, ficará disponível para <strong>Você</strong> no website https://academyabroad.com.br/. Ao acessar a Plataforma, <strong>Você</strong> se cadastrará, gratuitamente, colocando as informações necessárias para o uso, tais como: nome, e-mail e senha.</p>

                  <p><strong style={{ display: 'block' }}>2.2. Dinâmica.</strong> Após <strong>Você</strong> realizar o Cadastro, terá acesso à Plataforma <strong>ACADEMY ABROAD</strong>, que dispõe de um número limitado de conteúdos para acesso gratuito. Caso queira, <strong>Você</strong> poderá adquirir um serviço pago para ter acesso a todo o conteúdo da <strong>ACADEMY ABROAD</strong>.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>2.2.1. Mentorias.</strong> Caso queira, <strong>Você</strong> poderá contratar mentorias em grupo e individuais, com capacitação para a aplicação a vagas de oportunidades nacionais e internacionais, além de instruções sobre pesquisa, carreira e intercâmbio, pagando um valor adicional a <strong>ACADEMY ABROAD</strong>.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>2.2.2. Intercâmbio.</strong> Caso queira, <strong>Você</strong> poderá contratar assessoria para processos de aplicação a vagas de intercâmbio, oferecidas por Instituições de Ensino, pagando um valor adicional a <strong>ACADEMY ABROAD</strong>. A aprovação para a vaga, assim como os custos e despesas com viagem, competem exclusivamente a <strong>Você</strong>.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>2.2.3. Cadastro de Currículo.</strong> <strong>Você</strong> poderá preencher informações para elaborar currículo por meio da Plataforma, gratuitamente, e baixar o arquivo em PDF. A <strong>ACADEMY ABROAD</strong> utilizará as informações cadastradas no currículo, exclusivamente, para sugerir vagas de intercâmbio a <strong>Você</strong>, com base na análise curricular.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>2.2.4. Comunicação.</strong> As notificações e comunicação entre <strong>Você</strong> e a <strong>ACADEMY ABROAD</strong> será realizada pelo e-mail cadastrado por <strong>Você</strong> e <i>push notification</i>.</p>

                  <p><strong style={{ display: 'block' }}>2.3. Incorreção de Informações Cadastrais.</strong> Para acessar e utilizar as funcionalidades da Plataforma, <strong>Você</strong> deverá prestar as informações exigidas no momento do cadastro, conforme disposto na Política de Privacidade.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>2.3.1. Critérios de recusa.</strong> A <strong>ACADEMY ABROAD</strong> se reserva o direito de impedir, a seu critério, novos cadastros, ou cancelar os já efetuados, em caso de ser detectada anomalia que considere, em sua análise, revestida de erro grave ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todo Usuário(a).</p>

                  <p><strong style={{ display: 'block' }}>2.4. Uso de Cadastro por Terceiros.</strong> Os cadastros na Plataforma são individuais e intransferíveis, assim, <strong>Você</strong> deverá imediatamente notificar a <strong>ACADEMY ABROAD</strong> sobre qualquer uso não autorizado de sua conta, em tempo hábil, não sendo a <strong>ACADEMY ABROAD</strong> responsável por quaisquer prejuízos ou danos oriundos do uso de "login" e senha por terceiro, com ou sem o seu consentimento.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>2.4.1. Cuidados a serem observados.</strong> Para utilização adequada da Plataforma, <strong>Você</strong> deverá:</p>

                  <p style={{ marginLeft: 20 }}><strong>(i)</strong> Ser cuidadoso com os dados de sua identificação individual sempre que acessar a Internet;</p>

                  <p style={{ marginLeft: 20 }}><strong>(ii)</strong> Tomar outras medidas necessárias para se proteger de danos, inclusive fraudes online e off-line;</p>

                  <p style={{ marginLeft: 20 }}><strong>(iii)</strong> Ser diligente no uso do conteúdo disponibilizado na Plataforma <strong>ACADEMY ABROAD</strong>, sabendo de suas responsabilidades;</p>

                  <p style={{ marginLeft: 20 }}><strong>(iv)</strong> Cumprir rigorosamente todas as determinações deste Termo.</p>

                  <p><strong style={{ display: 'block' }}>2.5. Limitações de Uso.</strong> A licença de uso da Plataforma é concedida de forma limitada, não exclusiva, intransferível, não customizável e não passível de sublicenciamento, para utilização da Plataforma incluindo acessar e visualizar os conteúdos pela internet, exclusivamente para uso pessoal, sem fins comerciais e desde que integralmente atendidas as condições previstas neste Termo.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>2.5.1. Finalidade.</strong> Dessa forma, <strong>Você</strong> não poderá utilizar nem permitir o uso da Plataforma para qualquer outra finalidade que não seja aquela descrita no presente Termo. Caso seja constatada alguma ofensa ao Termo e a Política de Privacidade, a <strong>ACADEMY ABROAD</strong> poderá suspender o seu acesso por completo, sem que isso implique no inadimplemento deste Termo, de modo que <strong>Você</strong> deverá continuar cumprindo com todas as suas obrigações, até que se verifique ou sane, no prazo de 15 (quinze) dias, a irregularidade constatada.</p>

                  <p><strong style={{ display: 'block' }}>2.6. Responsabilidade por conteúdo inserido.</strong> <strong>Você</strong> está ciente que todo e qualquer conteúdo inserido é de sua inteira responsabilidade, já que, a <strong>ACADEMY ABROAD</strong> não faz, e não fará, qualquer controle sobre a veracidade e/ou licitude das informações inseridas.</p>

                  <p><strong style={{ display: 'block' }}>2.7. Obrigações do Usuário(a).</strong> <strong>Você</strong> tem as seguintes responsabilidades:</p>

                  <p style={{ marginLeft: 20 }}><strong>(i)</strong> Não utilizar a Plataforma <strong>ACADEMY ABROAD</strong> para qualquer propósito que seja ilícito ou proibido pelo Termo e/ou pelas normas aplicáveis;</p>

                  <p style={{ marginLeft: 20 }}><strong>(ii)</strong> Autorizar que a <strong>ACADEMY ABROAD</strong> possa enviar comunicações por e-mail, telefone ou mensagens instantâneas, inclusive de caráter publicitário;</p>

                  <p style={{ marginLeft: 20 }}><strong>(iii)</strong> Apresentar comportamento honesto e de boa fé;</p>

                  <p style={{ marginLeft: 20 }}><strong>(iv)</strong> Informar corretamente e com precisão todos os seus dados, para fins cadastrais;</p>

                  <p style={{ marginLeft: 20 }}><strong>(v)</strong> Responder pelas informações inseridas e mantidas na Plataforma, pelo cadastramento, permissões, senhas e modo de utilização, isentando a <strong>ACADEMY ABROAD</strong> de toda e qualquer responsabilidade com relação ao conteúdo inserido e sua forma de utilização;</p>

                  <p style={{ marginLeft: 20 }}><strong>(vi)</strong> Pagar o valor referentes aos serviços contratados.</p>

                  <p><strong style={{ display: 'block' }}>2.8. Idoneidade.</strong> <strong>Você</strong> declara, ao consentir com este documento para utilizar a Plataforma, que detém idoneidade incólume, ficando ciente, desde já, que as informações cadastrais falsas ou inverídicas registradas ou fornecidas durante o processo eletrônico de contratação de serviços podem constituir crime.</p>

                  <h4>Capítulo III - ACESSO E RESTRIÇÕES DE FUNCIONALIDADE</h4>

                  <p><strong style={{ display: 'block' }}>3.1. Restrições.</strong> Não é permitido acessar as áreas de programação da Plataforma <strong>ACADEMY ABROAD</strong>, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de webmastering, realizar ou permitir que se realize qualquer tipo de engenharia reversa, tradução, decompilação, cópia, modificação, reprodução, locação, sublocação, sublicenciamento, publicação, divulgação, transmissão, empréstimo, distribuição ou, de qualquer outra forma, a disposição de ferramentas de consulta deste website, aplicativos, programas e de suas funcionalidades para terceiros sem a prévia e expressa autorização da <strong>ACADEMY ABROAD</strong>, ficando sujeito quem o fizer a penalidades da legislação brasileira, sem prejuízo da obrigação de reparar os danos que causarem. Esta restrição inclui qualquer tentativa de incorporar quaisquer informações da plataforma em qualquer outro diretório, produto ou serviço.</p>

                  <h4>Capítulo IV – REMUNERAÇÃO E CANCELAMENTO</h4>

                  <p><strong style={{ display: 'block' }}>4.1. Remuneração.</strong> O cadastro na Plataforma é totalmente gratuito. Após o cadastro, <strong>Você</strong>, caso não adquira um serviço pago, será automaticamente incluído no plano gratuito, no qual terá acesso a conteúdo limitado de videoaulas e ebooks, a plataforma MEU CV e os editais de intercâmbios. Contudo, a qualquer momento, <strong>Você</strong> poderá optar pela aquisição do plano pago, que dará acesso a todo o conteúdo gratuito e recursos a mais, incluindo mentorias coletivas e individuais, cursos on-line, entre outros, a depender do plano optado. As transações do pagamento são realizadas por meio da Plataforma Pagar.me e o pagamento é realizado dentro da própria plataforma https://academyabroad.com.br/.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>4.1.1. Responsabilidade de pagamento.</strong> Desde já, <strong>Você</strong> está ciente que a aprovação deste se dará por plataforma de terceiros (Plataforma Pagar.me), não havendo qualquer ingerência e, consequentemente, responsabilidade da <strong>ACADEMY ABROAD</strong>.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>4.1.2. Outros serviços.</strong> A <strong>ACADEMY ABROAD</strong> poderá oferecer a <strong>Você</strong> outros serviços, mediando o pagamento de remuneração, o que dependerá sempre de prévia e expressa anuência e contratação por <strong>Você</strong>, a seu exclusivo critério.</p>

                  <p><strong style={{ display: 'block' }}>4.2. Cancelamento do serviço.</strong> <strong>Você</strong> poderá cancelar seu cadastro na Plataforma a qualquer tempo por meio da Plataforma.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>4.2.1. Desobrigação de reter dados.</strong> <strong>Você</strong> reconhece e concorda que a Plataforma <strong>ACADEMY ABROAD</strong> não possui obrigação de reter ou manter arquivados quaisquer dados/conteúdo de sua propriedade na Plataforma <strong>ACADEMY ABROAD</strong> após o término da relação contratual.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>4.2.2. Pendências.</strong> Caso haja valores em aberto, o interessado no cancelamento deverá efetuar a sua quitação para que seja efetuado. </p>

                  <h4>Capítulo V - EXCLUSÃO DE GARANTIAS</h4>

                  <p><strong style={{ display: 'block' }}>5.1. Disponibilidade.</strong> A Plataforma está disponível para o seu uso e é oferecida "no estado em que se encontra" e "conforme disponível". A <strong>ACADEMY ABROAD</strong> declina qualquer responsabilidade por garantias implícitas de comerciabilidade, adequação a um determinado fim, de retorno financeiro. Além disso, a <strong>ACADEMY ABROAD</strong> não garante que os serviços serão fornecidos sem interrupções ou erros. Sendo assim, <strong>Você</strong> aceita que todo o risco decorrente da utilização da Plataforma <strong>ACADEMY ABROAD</strong> é de sua exclusiva responsabilidade.</p>

                  <h4>Capítulo VI - LIMITAÇÃO DE RESPONSABILIDADE</h4>

                  <p><strong style={{ display: 'block' }}>6.1. Atendimento integral.</strong> A <strong>ACADEMY ABROAD</strong> não garante a <strong>Você</strong> que: (a) as funções contidas na Plataforma atenderão plenamente as suas necessidades; (b) que a operação da Plataforma será ininterrupta ou livre de erros; ou (c) que a Plataforma será compatível ou funcione com qualquer outro software, aplicações ou serviços de terceiros. Dessa forma, a <strong>ACADEMY ABROAD</strong> responderá exclusiva e limitadamente pelos serviços por si prestados.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>6.1.1. Independência entre as partes.</strong> A <strong>ACADEMY ABROAD</strong> reitera que não constitui com <strong>Você</strong> nenhum tipo de: (i) sociedade, associação ou representação; (ii) prestação de qualquer serviço diverso do objeto deste instrumento particular, e/ou (iii) relação empregatícia.</p>

                  <p><strong style={{ display: 'block' }}>6.2. Responsabilidade.</strong> A <strong>ACADEMY ABROAD</strong> não será responsável por quaisquer perdas ou danos sofridos, resultantes de: (i) qualquer informação errada ou incompleta que seja fornecida por <strong>Você</strong>; (ii) qualquer fraude, declaração fraudulenta ou violação do dever, ou violação de qualquer uma das condições por outros Usuários(as); (iii) falhas nas conexões; (iv) problemas no processamento de dados por culpa de terceiros; (v) produtos ou serviços de terceiros, mesmo que adquiridos através da Plataforma; (vi) pelo conteúdo inserido por <strong>Você</strong>; (vii) por danos ou prejuízos decorrentes de decisões tomadas com base nas informações fornecidas pela Plataforma; e (viii) por problemas definidos como "caso fortuito" ou "força maior" contemplados pelo artigo 393 do Código Civil Brasileiro.</p>

                  <p><strong style={{ display: 'block' }}>6.3. Violação de Direito de Terceiros.</strong> A <strong>ACADEMY ABROAD</strong> não se responsabilizará perante qualquer Usuário(as) em relação a qualquer violação de direito de terceiros.</p>

                  <p><strong style={{ display: 'block' }}>6.4. Danos.</strong> Em nenhum caso, a <strong>ACADEMY ABROAD</strong> será responsável por danos indiretos, incidentais, especiais, exemplares, punitivos ou consequentes, incluindo, danos pessoais ou à propriedade, relacionados com, ou de outra forma resultantes, de qualquer uso da Plataforma que <strong>Você</strong> possa sofrer devido às ações realizadas ou não realizadas através desta, ainda que decorrentes da conduta de terceiros.</p>

                  <p><strong style={{ display: 'block' }}>6.5. Indenização.</strong> <strong>Você</strong> deverá indenizar a <strong>ACADEMY ABROAD</strong>, seus diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por terceiros decorrentes de suas atividades na Plataforma, e pelo descumprimento dos termos de uso e condições e demais políticas da mesma, e ainda pela violação de qualquer lei ou direitos de terceiros, incluindo honorários advocatícios.</p>

                  <h4>Capítulo VII - INATIVAÇÃO DO CADASTRO, DÚVIDAS E SUGESTÕES</h4>

                  <p><strong style={{ display: 'block' }}>7.1. Inativação Do Cadastro, Dúvidas e Sugestões.</strong> Em caso de dúvidas ou sugestões relacionadas a este Termo de Uso da Plataforma ou quaisquer outras informações, <strong>Você</strong> pode entrar em contato com os administradores através do e-mail plataforma@academyabroad.com.br, disponível na Plataforma, que será respondido em horário comercial, de segunda-feira a sexta-feira, das 08h00 às 18h00.</p>

                  <p><strong style={{ display: 'block' }}>7.2. Vedação e inativação de cadastro.</strong> Como forma de manter a confiança e respeito nesta Plataforma, serão vedados atos ilegais ou incompatíveis com a proposta de zelar pela prestação dos serviços de qualidade. Assim, poderá resultar em suspensão ou inativação do seu cadastro caso <strong>Você</strong> incorra em qualquer das hipóteses elencadas abaixo:</p>

                  <p style={{ marginLeft: 20 }}><strong>(i)</strong> Promoção da violência e/ou discriminação, baseada em questões de raça, sexo, religião, nacionalidade, orientação sexual ou de qualquer outro tipo;</p>

                  <p style={{ marginLeft: 20 }}><strong>(ii)</strong> Violação de quais leis, principalmente no que tange aos direitos autorais, propriedade intelectual, tais como cópias não autorizadas, utilização de imagens, sons, movimentos ou textos sem autorização do autor, sejam elas marcas, réplicas e/ou falsificações;</p>

                  <p style={{ marginLeft: 20 }}><strong>(iii)</strong> Violação do disposto na Política de Privacidade da Plataforma; </p>

                  <p style={{ marginLeft: 20 }}><strong>(iv)</strong> Não utilização da Plataforma por mais de 6 (seis) meses.</p>

                  <h4>Capítulo VIII - ENGENHARIA DA PLATAFORMA</h4>

                  <p><strong style={{ display: 'block' }}>8.1. Aperfeiçoamento.</strong> <strong>Você</strong> concorda que todo e qualquer sistema, plataforma ou serviço, independentemente de seu produtor ou características, é uma espécie de produto que está sempre em atualização e aperfeiçoamento, possuindo, constante e incondicionalmente, aspectos a serem melhorados, o que não pode ser considerado em si como falha ou defeito.</p>

                  <p><strong style={{ display: 'block' }}>8.2. Erros de Funcionamento.</strong> Eventuais erros no funcionamento da Plataforma serão corrigidos o mais breve possível, durante o período que for necessário para manutenção. A <strong>ACADEMY ABROAD</strong> não pretende que o funcionamento do servidor da Plataforma, bem como de seu sistema, do seu banco de dados, software e de seu website, sejam livres de erros, falhas ou de interrupções.</p>

                  <h4>Capítulo IX - PROPRIEDADE INTELECTUAL</h4>

                  <p><strong style={{ display: 'block' }}>9.1. Utilização da Propriedade Intelectual.</strong> O uso comercial da expressão "<strong>ACADEMY ABROAD</strong>", como marca, nome empresarial ou nome de domínio, e o logotipo são de propriedade exclusiva dos proprietários da <strong>ACADEMY ABROAD</strong>, conforme disposto no respectivo ato constitutivo da organização. Da mesma forma, direitos autorais e outros direitos passíveis de proteção por propriedade industrial como estrutura, os conteúdos das telas relativas à Plataforma, assim como os programas relacionados a mesma, software, website, bancos de dados, redes e arquivos. Todos os direitos estão protegidos, no Brasil e internacionalmente por leis e tratados internacionais de direito autoral, marcas, patentes e desenhos industriais. Aos proprietários da <strong>ACADEMY ABROAD</strong> reservam-se todos os direitos relativos a autorias, marcas, patentes e desenhos industriais, quer sejam de sua propriedade ou licenciados em seu favor, a si.</p>

                  <p style={{ marginLeft: 20 }}><strong style={{ display: 'block' }}>9.1.1. Vedação.</strong> Não é permitido reproduzir, duplicar, copiar, vender, revender, visitar, ou de qualquer outra forma explorar para fins comerciais o conteúdo da Plataforma sem o prévio consentimento escrito dos proprietários da <strong>ACADEMY ABROAD</strong>. Particularmente, é expressamente proibida a utilização de mineração de dados, robôs, ou outras ferramentas de coleta e extração de dados para extrair, de forma isolada ou recorrente, qualquer parte substancial da Plataforma visando sua reutilização.</p>

                  <p><strong style={{ display: 'block' }}>9.2. Relações com Terceiros.</strong> A Plataforma poderá fornecer links de acesso a outros websites, o que não significa que esses sites sejam de propriedade ou operados pela <strong>ACADEMY ABROAD</strong>. Como não possui controle sobre esses sites, a <strong>ACADEMY ABROAD</strong> não será responsável pelos conteúdos, práticas e serviços ofertados nos mesmos. A presença de links para outros sites não implica relação de sociedade, de supervisão, de cumplicidade ou solidariedade da <strong>ACADEMY ABROAD</strong> para com esses sites e seus conteúdos.</p>

                  <h4>Capítulo X - CONDIÇÕES FINAIS</h4>

                  <p><strong style={{ display: 'block' }}>10.1. Suspensão e Cancelamento do Acesso.</strong> Na eventualidade de <strong>Você</strong> descumprir qualquer disposição do Termo, desde já, reconhece e aceita que a <strong>ACADEMY ABROAD</strong> pode, sem aviso prévio, interromper ou suspender, temporária ou permanentemente, parte ou a totalidade, do seu acesso à Plataforma.</p>

                  <p><strong style={{ display: 'block' }}>10.2. Advertências e suspensão.</strong> Sem prejuízo de outras medidas cabíveis, a <strong>ACADEMY ABROAD</strong> poderá te advertir, suspender, temporária ou definitivamente seu acesso, a qualquer tempo, se <strong>Você</strong>:</p>

                  <p style={{ marginLeft: 20 }}><strong>(i)</strong> Não cumprir com qualquer dispositivo do Termo ou da Política de Privacidade;</p>

                  <p style={{ marginLeft: 20 }}><strong>(ii)</strong> Descumprir com quaisquer de suas obrigações ou proibições assumidas ao utilizar os serviços da plataforma;</p>

                  <p style={{ marginLeft: 20 }}><strong>(iii)</strong> Utilizar, sem autorização, nome e/ou marca da <strong>ACADEMY ABROAD</strong>, em parte ou na sua totalidade, e violar direitos de propriedade intelectual da <strong>ACADEMY ABROAD</strong>;</p>

                  <p style={{ marginLeft: 20 }}><strong>(iv)</strong> Não oferecer condições que possibilitem a verificação da sua identidade ou se qualquer informação fornecida estiver incorreta.</p>

                  <p><strong style={{ display: 'block' }}>10.3. Tratamento dos Dados Pessoais.</strong> Para que a Plataforma tenha qualidade e para que <strong>Você</strong> possa obter resultados de forma rápida e segura, é necessário o fornecimento de dados pessoais, nos termos dispostos na Política de Privacidade da Plataforma <strong>ACADEMY ABROAD</strong>.</p>

                  <p><strong style={{ display: 'block' }}>10.4. Nulidades.</strong> Se qualquer cláusula deste instrumento for considerada ilegal, inválida ou inaplicável, no seu todo ou em parte, ao abrigo de qualquer lei, essa cláusula ou parte do mesmo será nesta exata medida entendida como não existente nos Termos de Uso, sendo que a legalidade, validade e aplicabilidade das demais cláusulas não serão afetadas. </p>

                  <h4>Capítulo XI - LEI APLICÁVEL E JURISDIÇÃO</h4>

                  <p><strong style={{ display: 'block' }}>11.1. Foro.</strong> Os presentes Termos de Uso são regidos pelas leis brasileiras, tendo como definido o foro da Comarca de Rio de Janeiro/RJ, como competente para dirimir eventuais controvérsias oriundas destes, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser, devendo-se dar prioridade, sempre, às tentativas de resolução por autocomposição.</p>
                </span>

                <p style={{ marginTop: '60px' }}>
                  <span style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>Academy Abroad – Atualizado em 06 de abril de 2021</span>
                  <a href="mailto:plataforma@academyabroad.com.br" alt="">plataforma@academyabroad.com.br</a>
                </p>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default TermosDeUso;