import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';

const giftcard = [
    {
        id: 'gftc-50',
        preco: 50,
        image: require('../../assets/images/gift-card/card-50.png')
    },
    {
        id: 'gftc-100',
        preco: 100,
        image: require('../../assets/images/gift-card/card-100.png')
    },
    {
        id: 'gftc-250',
        preco: 250,
        image: require('../../assets/images/gift-card/card-250.png')
    },
    {
        id: 'gftc-500',
        preco: 500,
        image: require('../../assets/images/gift-card/card-500.png')
    },
    {
        id: 'gftc-1000',
        preco: 1000,
        image: require('../../assets/images/gift-card/card-1000.png')
    },
    {
        id: 'gftc-gold',
        preco: null,
        image: require('../../assets/images/gift-card/card-gold.png')
    }

]


const GiftCard = () => {
    return (
        <>
            <SEO title="Gift-Card" />
            <Layout>
                
                <div className="edu-course-area course-three-wrapper edu-section-gap bg-color-white position-relative">
                    <div className="container">

                        <div className="row g-5 align-items-center mb--50 mt_md--30 mt_sm--30">
                            <div className="col-8">
                                <div className="section-title text-start">
                                    {/* <p className="mb-0 lh-sm fs-2 fw-bold" style={{color:'var(--color-secondary'}}>Veja os melhores</p> */}
                                    <h3 className="title text-primary lh-sm">Gift Card</h3>
                                </div>

                                <div className='text-start pt--50'>
                                    <h5>Que tal presentear alguém com conhecimento? </h5>
                                    <p>Ao presetear com um gift card da Academy Abroad, você tem a chance de dar uma forcinha para um amigo ou familiar no avanço de suas carreiras!</p>
                                    <p className='fs-5 fw-light text-danger'>**Consulte as regras</p>
                                </div>
                            </div>
                    
                        </div>

                        <div className='col-12 row ptb--100'>
                            {giftcard && giftcard.map((item) => (
                                <div key={item.id} className='col-12 col-md-6 col-xl-4 mb--50 plr--50'>
                                    <a className='card-gift-card' href={`https://painel.academyabroad.com.br/checkout/gift-card/${item.id}`}>
                                        <img src={item.image} alt='gift-card'/>
                                    </a>
                                </div>
                            ))}
                        </div>

                        <div className="row g-5 align-items-center mb--50 mt_md--30 mt_sm--30">
                            <div className="col">
                                <div className="section-title text-start">
                                    <h5 className="text-primary lh-sm">Regras</h5>
                                </div>

                                <div className='text-start pt--50'>
                                    <p className='lh-sm mb-3 fw-normal fs-4'><i className='icon-arrow-right-s-line text-primary'></i>Ao comprar, você irá colocar o nome o email de quem deseja presentear</p>
                                    <p className='lh-sm mb-3 fw-normal fs-4'><i className='icon-arrow-right-s-line text-primary'></i>A pessoa recebe em seu email o Gift Card com um código exclusívo para usar em suas compras na Academy Abroad</p>
                                    <p className='lh-sm mb-3 fw-normal fs-4'><i className='icon-arrow-right-s-line text-primary'></i>O Gift Card tem validade de 6 meses</p>
                                    <p className='lh-sm mb-3 fw-normal fs-4'><i className='icon-arrow-right-s-line text-primary'></i>Só poderá ser utilizado uma vez, caso a pessoa não utilize o valor total. Outro cupom será gerado com o valor restante para ser usado em outras compras e assim até que o valor total seja utilizado.</p>
                                </div>
                            </div>
                    
                        </div>
                        
                    </div>
                </div>

            </Layout>
        </>
    )
}

export default GiftCard;