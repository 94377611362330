import React from 'react';
import { Link } from 'react-router-dom';
import { formatToBRL } from 'brazilian-values'
import imgNatal from '../../assets/images/bola-de-natal.png'

const CardMentoriaGrupo = ({ data}) => {

    console.log(data)

    let numAleatorio3 = Math.floor(Math.random() * 9) + 1;

    const styleLink = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 5,
        height:'140px',
        textAlign:'justify',
        WebkitBoxOrient: 'vertical',
        lineHeight: '16px',
        fontSize: '14px'
    };

    const descricao = data.descricao_mentor.length > 230 ? data.descricao_mentor.substring(0, 230) + '...' : data.descricao_mentor;

    const categorias = data.categorias.length > 3 ? data.categorias.slice(0,4) : data.categorias

    return (
        <div  className={`card-mentor radius-big bg-white shadow-sm p-3`} style={{minHeight:'505px'}}>
            <div className="inner">
                <div className="col d-flex align-items-start justify-content-around" 
                style={{height:'120px'}}>
                    <Link to={process.env.PUBLIC_URL + `/mentorias-em-grupo/${data.slug}`}
                    style={{
                        width:'104px',
                        height:'104px',
                        border:'3px solid var(--color-primary)',
                        borderRadius:'50%'
                    }}>
                        <img className="w-100 h-100 object-fit-cover rounded-circle" src={data.foto_perfil_url}  alt="Foto"/>
                    </Link>

                    <div className="col-6">
                        <p className="fs-3 fw-bold lh-sm mb-1 mt-3" 
                        style={{height:'45px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2}}>
                            <Link to={process.env.PUBLIC_URL + `/mentorias-em-grupo/${data.slug}`}>{data.nome}</Link>
                        </p>
                        { data.nota !== 0 && 
                        <div className="edu-rating rating-default mt-0">
                            <div className="d-flex gap-1 me-2">
                                <i className="icon-Star fs-5"></i>
                                <i className="icon-Star fs-5"></i>
                                <i className="icon-Star fs-5"></i>
                                <i className="icon-Star fs-5"></i>
                                <i className="icon-Star fs-5"></i>
                            </div>
                            <span className="rating-count fs-5">{data.nota} avaliações</span>
                        </div> }

                    </div>
                </div>

                <div className="content mt-5">
                    <p className='fs-3 fw-bold lh-sm mb-2' style={{color:'var(--color-secondary)'}}>Sobre a mentoria em grupo</p>
                    <p className="text mb-2 fs-5 fw-normal" style={styleLink}>{descricao}</p>

                    <div className="col d-flex align-items center justify-content-start gap-1 mb-3">
                        {categorias.map((item, i) => (
                            <div className='fs-6 py-1 px-2 radius-small lh-base' style={{backgroundColor:'#1780FF25'}} key={i}>{item}</div>
                        ))}
                    </div>

                    <div className="row d-flex-justify-content-center-align-items-center mt-4">

                        <div className="col-6">
                            {
                                data.valor_mentoria === null ?
                                    <div className="fs-3 fw-bold" style={{color:'var(--color-secondary'}}>Grátis</div>
                                :
                                    <>
                                        {data.desconto ? (
                                            <div className="fs-3 fw-bold" style={{color:'var(--color-secondary'}}>{formatToBRL(data.valor_desconto)}</div>
                                        ) : (
                                            <div className="fs-3 fw-bold" style={{color:'var(--color-secondary'}}>{formatToBRL(data.valor_mentoria)}</div>
                                        )}
                                    </>
                            }
                        </div>
                        

                        <div className="col fs-5 fw-bold d-flex align-items-center">
                            {data.mentorias.length} mentorias em grupo
                        </div>

                    </div>

                    
                    <div className="row gap-1" style={{position:'relative', bottom: '-55px'}}>
                        <div className="col read-more-btn m-0">
                            <a href={`https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/${data.slug}`} target="_blank" rel="noreferrer"
                            className="edu-btn py-2 px-3 lh-sm fs-5 d-flex align-items-center justify-content-center"  
                            style={{height:'40px'}}>
                            Comprar 
                            </a>
                        </div>

                        <div className="col-4 d-flex align-items-center justify-content-center py-1 px-3 fs-5 me-1 radius-small lh-base"
                        style={{backgroundColor:'#1780FF25'}}>
                            <Link to={process.env.PUBLIC_URL + `/mentorias-em-grupo/${data.slug}`} 
                            className='fw-bold'>Detalhes</Link>
                        </div>
                    </div>

                    {data.desconto && (
                        <span className='d-flex justify-content-center align-items-center text-white fs-1' 
                        style={{width:'65px', height:'65px', position:'relative', zIndex:'999', bottom:'50px', right:'-46px', transform:'rotate(-17deg)'}}>
                            <span style={{position:'relative', zIndex:'1', left:'47px', top:'5px'}}><p className='text-white fs-4 mb-0'>{data.desconto}%</p></span>
                            <img src={imgNatal} alt='bolinha de natal' style={{position:'relative', zIndex:'-1'}}/>
                        </span>
                    )}

                </div>
            </div>
        </div>
    )
}

export default CardMentoriaGrupo;