import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import img1 from '../../assets/images/kenji-perfil.jpg'
import { FaCheckCircle } from "react-icons/fa";
import './styles/palestra.scss'
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import logo1 from '../../assets/images/logos/logo-academy.svg'

const data = [
    'Médico formado na UFJF',
    'Intercambista da Harvard Medical School',
    'Participante de fóruns de liderança e empreendedorismo da ONU',
    'CEO e Fundador da Academy Abroad',
    'CEO da FHE Ventures e Health Angels Venture Builder',
    'TEDx Speaker'
]
const Palestra = () => {
    const { slug } = useParams()
    const [nomeCompleto, setNomeCompleto] = useState('');
    const [email, setEmail] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [telefone, setTelefone] = useState('');
    const [curso, setCurso] = useState('');
    const [faculdade, setFaculdade] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('')
    const [dataPalestra, setDataPalestra] = useState();
    const [intercambio, setIntercambio] = useState(false);
    const [privacidade, setPrivacidade] = useState(false);
    const [novidades, setNovidades] = useState(false);

    function formatarData(dataDoFormulario) {
        
    var data = new Date(dataDoFormulario);
    
    var ano = data.getFullYear();
    var mes = ('0' + (data.getMonth() + 1)).slice(-2); 
    var dia = ('0' + data.getDate()).slice(-2);

    var dataFormatada = ano + '/' + mes + '/' + dia;
    
    return dataFormatada;
    }

    useEffect(() => {
        axios.get(`https://api.academyabroad.com.br/api/site/palestras/${slug}`)
        .then((response) => {
            console.log(response.data)
            setDataPalestra(response.data)
        }).catch(error => console.log(error))
    },[slug])

    function resetForm(){
        setNomeCompleto('');
        setEmail('');
        setDataNascimento('');
        setTelefone('');
        setCurso('');
        setFaculdade('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log('Nome Completo:', nomeCompleto);
        console.log('Email:', email);
        console.log('Data de Nascimento:', formatarData(dataNascimento));
        console.log('Telefone:', telefone);
        console.log('Curso:', curso);
        console.log('Faculdade:', faculdade);
        console.log('Intercambio:', intercambio);
        console.log('Privacidade:', privacidade);
        console.log('Novidades:', novidades);

        const formData = {
            nome_completo: nomeCompleto,
            email: email,
            data_nascimento: dataNascimento,
            telefone: telefone,
            curso: curso,
            faculdade: faculdade,
            contato_whatsapp: intercambio,
            lgpd: privacidade,
            contato_email: novidades
        };

        fetch(`https://api.academyabroad.com.br/api/palestras/${dataPalestra.id}/alunos-palestra`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Sucesso:', data);
            resetForm()
            setMessage(`Inscrição realizada com sucesso!`)
            setStatus('sucesso')
        })
        .catch(error => {
            console.error('Erro:', error);
            setMessage(`Ocorreu um erro e sua inscrição não foi efetuada, por favor, tente novamente!`)
            setStatus('erro')
        });
    };


    return(
        <>
            <SEO title={"Palestra"} />
            <HeaderTwo styles={"header-style-2"} />
            {dataPalestra && (
                <div className="container-fluid  palestra-container m-0 p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 ptb--120">
                                <div className='row'>
                                    <div className='col-11 pb--50'>
                                        <h1 className='h4 text-white text-center text-lg-start'>{dataPalestra.nome}</h1>
                                        <p className='pt--50 fw-bold text-white' style={{textAlign:'justify'}}>{`Data da palestra: ${dataPalestra.data}`}</p>
                                        <div className='fw-normal' style={{color:'#ffffff', textAlign:'justify'}} dangerouslySetInnerHTML={{__html: dataPalestra.descricao}}></div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-5 h-100">
                                        <img src={img1} alt='kenji' style={{height:'100%', width:'100%', objectFit:'cover', borderRadius:'20px', border:'4px solid white'}}/>
                                    </div>
                                    <div className="col-12 col-lg-6 h-100">
                                        <h5 className='h6 fw-normal mt-5 mt-lg-0 ms-4' style={{color:'var(--color-secondary)'}}>Quem é o Dr. Rafael Kenji?</h5>
                                        <ul>
                                            {data && data.map((item, i) => (
                                                <li className='fs-5 fw-normal row' style={{listStyle:'none', color:'var(--color-secondary-alt)'}} key={i}>
                                                    <div className='col-1'><FaCheckCircle className='fs-4 me-3' style={{color:'var(--color-secondary)'}} /></div>
                                                    <div className='col mb-0 lh-sm'>{item}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6" style={{padding:'120px 50px', backgroundColor:'#F6F7F7'}}>
                                <div className='m-0 m-lg-5'>
                                
                                    <div className='row mb-5'>
                                        <div className='col-6'>
                                            <div className='box-image' style={{height:'67px', width:'129px',}}>
                                                <img src={logo1} style={{height:'100%', width:'100%', objectFit:'contain'}} alt='logo' />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='box-image' style={{height:'67px', width:'129px',}}>
                                                <img src={`https://api.academyabroad.com.br/storage/${dataPalestra.logo}`} style={{height:'100%', width:'100%', objectFit:'contain'}} alt='logo' />
                                            </div>
                                        </div>

                                    </div>


                                    {dataPalestra.status === 'ativo' ? (
                                      <>
                                        {/* <h4 className='h6 text-primary'>Preencha o formulário para participar da palestra gratuita com o Dr. Rafael Kenji</h4> */}
                                        <form onSubmit={handleSubmit}>

                                            <label htmlFor="nomeCompleto">Nome Completo:</label>
                                            <input type="text" id="nomeCompleto" name="nomeCompleto" value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} required/>

                                            <label htmlFor="email">Email:</label>
                                            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>

                                            <div className='row'>

                                                <div className='col-12 col-lg-6'>
                                                    <label htmlFor="dataNascimento">Data de Nascimento:</label>
                                                    <input type="date" id="dataNascimento" name="dataNascimento" value={dataNascimento} onChange={(e) => setDataNascimento(e.target.value)} required/>
                                                </div>

                                                <div className='col-12 col-lg-6'>
                                                    <label htmlFor="telefone">Telefone:</label>
                                                    <input type="tel" id="telefone" name="telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} required/>
                                                </div>

                                                <div className='col-12 col-lg-6'>
                                                    <label htmlFor="curso">Curso:</label>
                                                    <input type="text" id="curso" name="curso" value={curso} onChange={(e) => setCurso(e.target.value)} required/>
                                                </div>

                                                <div className='col-12 col-lg-6'>
                                                    <label htmlFor="faculdade">Faculdade:</label>
                                                    <input type="text" id="faculdade" name="faculdade" value={faculdade} onChange={(e) => setFaculdade(e.target.value)} required/>
                                                </div>
                                            </div>

                                            <div className='mt-5'>
                                                <input
                                                    type="checkbox"
                                                    id="intercambio"
                                                    name="intercambio"
                                                    checked={intercambio}
                                                    onChange={() => setIntercambio((prev) => !prev)}
                                                />
                                                <label className='fs-6 fw-normal' htmlFor="intercambio">
                                                    Aceito receber oportunidades de intercâmbio pelo Whatsapp
                                                </label>
                                            </div>

                                            <div className='mt-2'>
                                                <input
                                                    type="checkbox"
                                                    id="politicaPrivacidade"
                                                    name="politicaPrivacidade"
                                                    checked={privacidade}
                                                    onChange={() => setPrivacidade((prev) => !prev)}
                                                    required
                                                />
                                                <label className='fs-6 fw-normal' htmlFor="politicaPrivacidade">
                                                    Concordo com a Política de Privacidade
                                                </label>
                                            </div>

                                            <div className='mt-2'>
                                                <input
                                                    type="checkbox"
                                                    id="novidades"
                                                    name="novidades"
                                                    checked={novidades}
                                                    onChange={() => setNovidades((prev) => !prev)}
                                                />
                                                <label className='fs-6 fw-normal' htmlFor="novidades">
                                                    Aceito receber novidades da Academy por e-mail
                                                </label>
                                            </div>


                                            {!message && (
                                                <input className='edu-btn w-100' type="submit" value="Inscreva-se agora"/>
                                            )}

                                            {message && status === 'sucesso' && (
                                                <span className='text-success'>{message}</span>
                                            )}

                                            {message && status === 'erro' && (
                                                <span className='text-danger'>{message}</span>
                                            )}

                                            </form>
                                      </>      
                                    ) : (
                                        <>
                                            <h5 className='text-primary fw-bold text-center'>Inscrições encerradas</h5>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <FooterOne />
        </>
    )
}

export default Palestra;