import { FaArrowRight } from 'react-icons/fa';
import img1 from '../../assets/images/luiz-geraldo.png';
import img2 from '../../assets/images/kenji-perfil.jpg';
import img3 from '../../assets/images/eduardo-lobo.png';

const OrientationExperience = () => {
    return(
        <div className="container-fluid ptb--100">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-12 col-lg-7 pe-5">
                        <h3 className="text-primary">Quem irá te orientar</h3>
                        <p className="text-black fw-bold">Luiz Henrique</p>
                        <p className="fw-normal fs-4">Durante seu doutorado, além de trabalhar como médico de família e comunidade no complexo do alemão, estudou potenciais novas imunoterapias para tumores cerebrais na UFRJ e nas Universidades de Paris e Sourbonne (Hospital Europeu Georges Pompidou, Instituto de Pesquisa Cardiovascular de Paris e Instituto do Cérebro de Paris).</p>
                        <p className="fw-normal fs-4">Atualmente, Luiz é Cardiology Research Fellow em Yale.</p>
                        <a href='https://wkf.ms/3PwhOCn' className='mt-5 edu-btn'>Inscrever Agora <FaArrowRight /></a>
                    </div>
                    <div className="col-12 col-lg-5">
                        <div className="m-auto" style={{width:'472px',height:'472px'}}>
                            <img src={img1} alt='luiz geraldo' style={{width:'100%', height:'100%', objectFit:'cover', borderRadius:'20px'}} />
                        </div>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-12 col-lg-5">
                        <div className="m-auto" style={{width:'472px',height:'472px'}}>
                            <img src={img2} alt='luiz geraldo' style={{width:'100%', height:'100%', objectFit:'cover', borderRadius:'20px'}} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 pe-5">
                        <p className="text-black fw-bold">Rafael Kenji</p>
                        <p className="fw-normal fs-4">Rafael é o fundador da Academy Abroad e idealizador do projeto Experience Hunting, um programa de mentoria de intercâmbio focado na área da saúde. Médico e ex-intercambista da Harvard Medical School, sua experiência pessoal inspirou a criação da Academy Abroad. Além disso, Rafael é CEO da FHE Ventures e da Health Angels, reconhecido como um dos CEOs mais jovens do Brasil pelo Estadão. Palestrante do TEDx e representante da ONU em fóruns sobre desenvolvimento sustentável e políticas globais, ele atua como mentor e palestrante nas áreas de liderança, inovação, empreendedorismo e tecnologia na saúde.</p>
                        <a href='https://wkf.ms/3PwhOCn' className='mt-5 edu-btn'>Inscrever Agora <FaArrowRight /></a>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-12 col-lg-7 pe-5">
                        <p className="text-black fw-bold">Luiz Henrique</p>
                        <p className="fw-normal fs-4">Eduardo Lobo foi bolsista do Santander Scholars no Harvard Business Publishing e é coordenador do Boston Health Innovation Program. Na startup Medmap começou como estagiário. Participou do Health InnovaBon Program - Hackmed e foi Diretor de Saúde Pública e Capacity Builder na IFMSA Brazil: UFJF. Na Harvard Medical School, foi trainee de pesquisa na Foundation for Neurologic Diseases. Também foi Diretor de Projetos na Medic Jr., empresa Júnior focada em consultoria e gestão de projetos médicos.</p>
                        <a href='https://wkf.ms/3PwhOCn' className='mt-5 edu-btn'>Inscrever Agora <FaArrowRight /></a>
                    </div>
                    <div className="col-12 col-lg-5">
                        <div className="m-auto" style={{width:'472px',height:'472px'}}>
                            <img src={img3} alt='luiz geraldo' style={{width:'100%', height:'100%', objectFit:'cover', borderRadius:'20px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrientationExperience;