import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import BannerOne from "../../components/banner/BannerOne";
import imgElement from "../../assets/images/bg/elemento-horizontal.png"
import AboutExperience from "../../components/about/AboutExperience";
import StepsExperience from "../../components/steps/StepsExperience";
import FeatureExperience from "../../components/feature/FeatureExperience";
import OrientationExperience from "../../components/orientation/OrientationExperience";
import InvestimentExperience from "../../components/investiment/InvestimentExperience";
import QuestionsExperience from "../../components/questions/QuestinsExperience";


const questions = [
    {
        pergunta:'O que é o programa "Experience Hunting" da Academy Abroad?',
        resposta: 'O "Experience Hunting" é um programa projetado para estudantes de medicina e médicos que desejam estudar, trabalhar ou se especializar no exterior. Ele oferece orientação personalizada, acesso a oportunidades de residência, estágios, pesquisa e trabalho em hospitais no exterior, além de ajudar a construir um currículo forte para o mercado internacional.'
    },
    {
        pergunta:'Como funciona o programa?',
        resposta: 'O programa "Experience Hunting" é dividido em cinco etapas, incluindo três encontros online com o Dr. Luiz Henrique Geraldo, que guiará você em todo o processo. A Academy Abroad analisa seu perfil e fornece oportunidades personalizadas, ajudando na construção e aprimoramento do seu currículo.'
    },
    {
        pergunta:'Quem é o Dr. Luiz Henrique Geraldo?',
        resposta: 'O Dr. Luiz Henrique Geraldo é um médico com doutorado em Neurociência Translacional pela UFRJ e Cardiology Research Fellow em Yale. Ele tem ampla experiência em pesquisa médica e apoio à comunidade médica.'
    },
    {
        pergunta:'Quanto custa o programa e qual é a oferta especial?',
        resposta: 'O programa tem um valor de investimento de R$4.000,00 até o dia 31/01/2024. Após essa data, o preço será ajustado para R$5.000,00. A oferta especial é uma oportunidade única para economizar enquanto investe em sua carreira médica internacional.'
    },
    {
        pergunta:'Como faço para me inscrever no programa?',
        resposta: 'Para se inscrever no programa "Experience Hunting" e começar sua jornada rumo ao sucesso médico internacional, entre em contato conosco pelo nosso site ou por e-mail. Estamos aqui para ajudar e responder a todas as suas perguntas.'
    },
    {
        pergunta:'Qual é a duração do programa?',
        resposta: 'O programa tem a duração de 3 meses, com um encontro online por mês e entregas recorrentes ao longo desse período.'
    },
    {
        pergunta:'Que tipo de suporte e orientação receberei durante o programa?',
        resposta: 'Durante o programa, você terá orientação direta do Dr. Luiz Henrique Geraldo, que irá guiá-lo em cada etapa. Além disso, nossa equipe estará disponível para responder a todas as suas perguntas e fornecer suporte contínuo.'
    },
    {
        pergunta:'Como o programa pode beneficiar minha carreira médica?',
        resposta: 'O programa "Experience Hunting" pode abrir portas para oportunidades de estudo, trabalho e pesquisa no exterior, expandindo sua carreira médica. Além disso, ele ajuda a construir um currículo forte, tornando você mais competitivo no mercado internacional.'
    },
    {
        pergunta:'O programa é adequado para estudantes de medicina e médicos de todas as especialidades?',
        resposta: 'Sim, o programa é projetado para atender às necessidades de estudantes de medicina e médicos de todas as especialidades que desejam seguir uma carreira médica internacional.'
    },
    {
        pergunta:'Como posso entrar em contato com a Academy Abroad para mais informações?',
        resposta: 'Você pode entrar em contato conosco pelo nosso site ou por e-mail. Teremos o prazer de responder a todas as suas perguntas e fornecer informações detalhadas sobre o programa. Estamos aqui para ajudar você a alcançar suas metas médicas internacionais. Não hesite em nos contatar para mais informações ou para iniciar sua jornada com o programa "Experience Hunting".'
    }
]


const ExperienceHunting = () => {
    return(
        <>
            <SEO title={"Experience Hunting"} />
            <HeaderTwo styles="header-style-2" />
            <BannerOne 
                title="<h3 class='text-white'>Explore o Mundo da Medicina no Exterior com o Programa Experience Hunting</h3>"
                description="Desenvolva sua carreira médica com orientação especializada e conquiste oportunidades no exterior"
                bg="bg-experience"
                isButton={true}
                element={imgElement}
            />
            <AboutExperience />
            <StepsExperience />
            <FeatureExperience />
            <OrientationExperience />
            <InvestimentExperience />
            <QuestionsExperience questions={questions} program={"Experience Hunting"}/>
            <FooterOne />
        </>

    )
}

export default ExperienceHunting;