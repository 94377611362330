import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeFilter from '../../components/course/CourseTypeFilter';
import axios from 'axios';
import BannerPublicidade from '../../components/banner/BannerPublicidade';

const CourseFilterAcademy = () => {
    

    return (
        <>
            <SEO title="Cursos" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Cursos"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Cursos"
                /> */}
                    <div className="edu-course-area course-three-wrapper edu-section-gap bg-color-white position-relative">
                        <BannerPublicidade />
                        <div className="container">
                            <CourseTypeFilter itemToShow="9" />
                        </div>
                    </div>
            </Layout>
        </>
    )
}

export default CourseFilterAcademy;