import Slider from 'react-slick';


const TeachersSection = () => {

    const teachersData = [
        {
            avatar: require('../../../assets/images/imersao-trilingue/1.png'),
            nome: 'Raphael Sampaio',
            bio: 'Além de coordenador pedagógico, também sou professor de inglês e mestre em tradução pela Universidade Federal do Ceará. Possuo experiência em escolas, cursos e aulas particulares, onde sempre priorizei o aprendizado me adaptando às necessidades dos alunos.'
        },
        {
            avatar: require('../../../assets/images/imersao-trilingue/2.png'),
            nome: 'Johnson Carlos',
            bio: 'Sou atuante na área desde 2012. Possuo certificação em planejamento de aulas e uso de recursos para o ensino do inglês pela Cambridge (TKT). Tenho experiência em conversação e preparação para testes.'
        },
        {
            avatar: require('../../../assets/images/imersao-trilingue/3.png'),
            nome: 'Jaciara Caetano',
            bio: 'Sou formada em Letras Francês e Mestre em Linguística Aplicada pela UECE. Ensino francês desde 2013 e Inglês desde 2016. Sou apaixonada pelo aprendizado de línguas e gosto de utilizar elementos da cultura pop nas aulas para melhor imersão do aluno.'
        },
        {
            avatar: require('../../../assets/images/imersao-trilingue/4.png'),
            nome: 'André Luís Mendes',
            bio: 'Sou professor de espanhol desde 2009 e graduado pela Universidade Estadual do Ceará em 2011. Possuo uma especialização em ensino de língua espanhola pela Faculdade Ateneu e atualmente sou professor efetivo pela Secretaria de Educação do Ceará.'
        }
    ]

    const ThreeColumnCarousel = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
    };

    return(
        <div className="container-fluid ptb--50" id="teachers-section">
            <div className="container">
                <div className="row">
                    <h2 className="color-primary text-center">Veja quem vai te ajudar <br/> nesses 3 meses de imersao.</h2>
                </div>
                <div className='row'>
                    <Slider className="mt--40 mb--50 slick-activation-wrapper eduvibe-course-one-carousel px-5"
                      { ...ThreeColumnCarousel }>
                        {teachersData && teachersData.map((item, i) => (
                            <div className='col' key={i}>
                                <div className='card-passport mx-auto my-5'>
                                    <div className='row px-5 pt-3'>
                                        <h4 className='mb-1'>PASSPORT</h4>
                                        <div className='fs-5 d-flex align-items-center justify-content-between mb-3'>
                                            <div>PASSPORT NO:<span className='fw-bold'>SD1234567</span></div>
                                            <div>TYPE:<span className='fw-bold'>W</span></div>
                                            <div>SERIES:<span className='fw-bold'>SD0000000000</span></div>
                                        </div>
                                    </div>
                                    <div className='row px-5'>
                                        <div className='col-4'>
                                            <div className='box-image'>
                                                <img src={item.avatar} alt={item.nome} />
                                            </div>
                                        </div>
                                        <div className='col-8 mb-4'>
                                            <span className='text-black fs-5 lh-sm mb-0'>PROFESSOR</span><br/>
                                            <span className='text-black fw-bold'>{item.nome}</span>
                                            <p className='text-black fs-6 mb-0'>{item.bio}</p>
                                        </div>
                                    </div>
                                    <div className='doted-divider mb-3'></div>
                                    <div className='row'>
                                        <span className='text-center fw-bold text-black fs-5 lh-sm mb-0'>M/JOHN/SMITH/SD1234564/M/D0000000000</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default TeachersSection;