const Banner1 = () => {
    return(
        <div className="banner-1-container container-fluid">
            <div className="container">
                <div className="banner p-3">
                    Descubra o seu propósito e conquiste o sucesso na sua nova carreira!
                </div>
            </div>
        </div>
    )
}

export default Banner1