

import ScrollAnimation from 'react-animate-on-scroll';
import image1 from '../../../assets/images/bg/rob.png'
import image2 from '../../../assets/images/bg/Seção 6 - Imagem 1.png'

function renderToHTML(descript) {
  const renderedHTML = descript;
  return {__html: renderedHTML};
}

const ImpulsoSection6 = ({title, description, link, btnText}) =>{
  const descript = renderToHTML(description)

  return(
    <section id="section-6">
      <div className="box-l">
      <div className="card-text">
          <div className="card-text-wrapp">
            <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeOutDown"
            animateOnce={ true }>
              <h3>{title}</h3>
            </ScrollAnimation>
            <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeOutDown"
            animateOnce={ true }>
              <span dangerouslySetInnerHTML={descript}/>
            </ScrollAnimation>
          </div>
          
            <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeOutDown"
            animateOnce={ true }>
              <div className="button-wrapp mt-5">
                <a href={link} id="second">{btnText}</a>
              </div>
            </ScrollAnimation>
          
        </div>
        
      </div>
      <div className="box-r">
        <div className="box-image">
          <div className="image-box" id="image-1">
            <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutRight"
            animateOnce={ true }>
              <img src={image1} alt="imagem robo"/>
            </ScrollAnimation>
          </div>
          <div className="image-box" id="image-2">
            <img src={image2} alt="imagem mulher"/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImpulsoSection6;