import Slider from "react-slick"
import { FaChevronLeft, FaChevronRight, FaLinkedin } from "react-icons/fa";

const dataSpeakers = [
  {
    "avatar": require("../../../assets/images/bhi-speakers/mary.png"),
    "name": "Mary Witkowski, MD",
    "occupation": "Professora da Harvard Business School, trabalhando no Institute for Strategy & Competitiveness",
    "embed": "https://www.linkedin.com/in/mary-witkowski/"
  },
  {
    "avatar": require("../../../assets/images/bhi-speakers/bizzo.png"),
    "name": "Bernardo Bizzo",
    "occupation": "Médico radiologista na Harvard Medical School. Diretor sênior de Inteligência Artificial do Mass General Brigham. ",
    "embed": "https://www.linkedin.com/in/bernardobizzo/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/roger-daglius.png'),
    "name": "Roger D. Dias",
    "occupation": "Diretor de Pesquisa e Inovação do Mass General Brigham, professor na Harvard Medical School ",
    "embed": "https://www.linkedin.com/in/rogerdagliusdias/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/stephen-brand.png'),
    "name": "Stephen Brand, EdM",
    "occupation": "Chief Imagination Officer na The New Enterprise Factory, MIT I–Corps Instructor e professor da Babson College",
    "embed": "https://www.linkedin.com/in/innovationatthecore/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/lee-schwamm.png'),
    "name": "Lee Schwamm, MD",
    "occupation": "VP de Virtual Care no Mass General Brigham. Diretor de Telehealth no MGH e professor da Harvard Medical School ",
    "embed": "https://www.linkedin.com/in/doctorleeschwamm/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/nevan-clancy.png'),
    "name": "Nevan Hanamura, PhD",
    "occupation": "Cientista e empreendedor. PhD em engenharia mecânica e professor no MIT",
    "embed": "https://www.linkedin.com/in/nevanhanumara/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/iavor-bojinov.png'),
    "name": "Iavor Bojinov, PhD",
    "occupation": "Professor associado da Harvard Business School, ex-cientista de dados do LinkedIn",
    "embed": "https://www.linkedin.com/in/iavor-bojinov/"
  },
  {
    "avatar": require('../../../assets/images/bhi-speakers/marc-succi.png'),
    "name": "Marc Succi, MD",
    "occupation": "Executive Director do MESH Incubator do Mass General Brigham e Founder/CEO da 2 Minute Medicine ",
    "embed": "https://www.linkedin.com/in/marc-succi/"
  },
]

const BhiSpeakers = ({descritivo}) => {

  const PrevArrow = ( props ) => {
    const { onClick } = props;
    return (
        <button className="slide-arrow prev-arrow" onClick={ onClick } >
            <FaChevronLeft className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}

const NextArrow = (props) => {
    const { onClick } = props;
    return  (
        <button className="slide-arrow next-arrow" onClick={ onClick } >
            <FaChevronRight className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}

  const ThreeColumnCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
          breakpoint: 1199,
          settings: {
              slidesToShow: 2
          }
      },
      {
          breakpoint: 767,
          settings: {
              slidesToShow: 1,
          }
      }
  ]
   
}

  return(
    <div className="container-fluid d-flex-align-items-center justify-content-around ptb--100">
      <div className="container text-center">
        <h3 className="h3" style={{color:'var(--color-gold)'}}>Speakers</h3>
        <div className='row text-center' dangerouslySetInnerHTML={{__html: descritivo}}></div>
      </div>
      <div className="container" style={{maxWidth:'1400px'}}>
        <Slider
        className="slick-arrow-style-3 px-5 ms-5"
        {...ThreeColumnCarousel}>

          {dataSpeakers && dataSpeakers.map((item, i) => (
            <div className="col-3 p-2">
              <div key={i} className="speakers-card radius shadow bg-white text-center px-3 py-4">
              <div 
                className="radius m-auto" 
                style={{
                  backgroundColor:'#093366',
                  height:'150px',
                  width:'150px',
                  borderRadius:'50%'
                }}>
                  <img 
                  style={{
                    width:'100%', 
                    height:'100%', 
                    objectFit:'cover',
                    borderRadius:'50%'}} 
                  src={item.avatar} 
                  alt={item.name + ' avatar'}/>

                </div>
                <h5 className="mt-3" style={{color:'var(--color-gold)'}}>{item.name}</h5>
                <div className="ocupation-section">
                  <p className="fw-normal lh-base">{item.occupation}</p>
                </div>
                <a className="align-self-end" href={item.embed} target="_blank" rel="noreferrer">
                  <FaLinkedin style={{fontSize:'40px', color:'var(--color-gold)'}} />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
export default BhiSpeakers