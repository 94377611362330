import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { formatToBRL } from 'brazilian-values';
const CourseInfo = ( { data }) => {
    const [toggler, setToggler] = useState( false );
    return (
        <div className="eduvibe-sidebar course-details-sidebar">
            <div className="inner">
                <div className="eduvibe-widget">
                    <div className="video-area">
                        <div className="thumbnail video-popup-wrapper">
                            <img className="radius-small w-100" src={data.foto_principal_url} alt="Curso Imagem" />
                            {/* <button onClick={ () => setToggler( ! toggler ) } className="video-play-btn position-to-top video-popup-activation">
                                <span className="play-icon"></span>
                            </button>
                            <FsLightbox toggler={ toggler } sources={ data.videoLink } /> */}
                        </div>
                    </div>
                    <div className="eduvibe-widget-details mt--35">
                        <div className="widget-content">
                            <ul>
                                { data.duracao && <li><span><i className="icon-time-line"></i> Duracao</span><span>{data.duracao}</span></li> }
                                { data.alunos && <li><span><i className="icon-user-2"></i> Alunos</span><span>{data.alunos}</span></li> }
                                { data.licoes && <li><span><i className="icon-draft-line"></i> Lições</span><span>{data.licoes}</span></li> }
                                {/* { data.level && <li><span><i className="icon-bar-chart-2-line"></i> Skill Level</span><span>{data.level}</span></li> }
                                { data.language && <li><span><i className="icon-translate"></i> Language</span><span>{data.language}</span></li> }
                                { data.quizzes && <li><span><i className="icon-artboard-line"></i> Quizzes</span><span>{data.quizzes}</span></li> }
                                { data.certificate && <li><span><i className="icon-award-line"></i> Certificate</span><span>{data.certificate === 'available' ? 'Yes' : 'No'}</span></li> }
                                { data.passPercentage && <li><span><img className="eduvibe-course-sidebar-img-icon" src="/images/icons/percent.svg" alt="icon Thumb" />Pass Percentage</span><span>{data.passPercentage}%</span></li> }
                                { data.deadline && <li><span><i className="icon-calendar-2-line"></i> Deadline</span><span>{data.deadline}</span></li> }
                                { data.instructor && <li><span><i className="icon-user-2-line_tie"></i> Instructor</span><span>{data.instructor}</span></li> } */}
                            </ul>
                            <div className="read-more-btn mt--45">
                                <a href="#" className="edu-btn btn-bg-alt w-100 text-center">
                                    { data.preco == null ? 'Grátis' : 'Preço:' + formatToBRL(data.preco.valor) }
                                </a>
                            </div>
                            <div className="read-more-btn mt--15">
                                <a href={`https://painel.academyabroad.com.br/checkout/curso/${data.slug}`} target="_blank" className="edu-btn w-100 text-center">
                                { data.preco == null ? 'Inscreva-se Agora' : 'Comprar Agora' }
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseInfo;