import React from 'react';
import { Link as ScrollTo } from 'react-scroll';


const BannerOne = ({title, description, bg, isButton, element}) => {
    
    return (
        <div className={`slider-area banner-style-1 bg-image height-940 d-flex align-items-center justify-content-center ${bg}`}>
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="banner-left-content mt-0">
                            <div className="inner">
                                <h1 className='text-white' dangerouslySetInnerHTML={{__html: title}}></h1>
                                <p className="description text-white fw-light lh-3 mt-5" dangerouslySetInnerHTML={{__html: description ? description : null}}></p>
                                {!isButton ? null : <div className="read-more-btn">
                                    <a className="edu-btn" href="https://painel.academyabroad.com.br/register">Cadastre-se agora mesmo<i className="icon-arrow-right-line-right"></i></a>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>

            <div className="scroll-down-btn">
                <ScrollTo
                    className="round-btn"
                    to="about-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                >
                    <i className="icon-arrow-down-s-line"></i>
                </ScrollTo>
                <img src={element} alt='elemento' />
            </div>
        </div>

    )
}
export default BannerOne;