import React from 'react';
import GalleryItemExperiencia from '../../gallery/GalleryItemExperiencia';

const GaleriaExperiencia = ( props ) => {
    return (
        <div className={`edu-blog-widget ${ props.style2 === 'enable' ? 'edu-instagram-widget-2' : '' } widget-instagram ${ props.extraClass || '' }`}>
            <div className="inner">
                <h5 className="widget-title">Galeria de fotos</h5>
                <div className="content">
                    <div className="instagram-list">
                        {
                            props.data.fotos.map((item, i) => (
                                <div key={i} className="col-lg-4 col-md-6 col-12">
                                    <GalleryItemExperiencia data={{
                                        "id": i,
                                        "foto": item,
                                        "category": "fotos",
                                        "image":  props.data.fotos
                                    }} />
                                </div>
                            ) )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GaleriaExperiencia;