import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import AboutSection from "./components/AboutSection";
import CtaSection from "./components/CtaSection";
import HeroSection from "./components/HeroSection";
import ProgramSection from "./components/ProgramSection";
import SocialSection from "./components/SocialSection";
import SupportSection from "./components/SupportSection";
import './styles/styles.scss';

export default function ImersaoJoseBergossi(){
    return(
        <>
            <SEO  title={"4 Segredos de uma comunicação de sucesso com José Bergossi"}/>
            <HeaderTwo styles=" header-style-2" />
            <HeroSection />
            <AboutSection />
            <ProgramSection />
            <SocialSection />
            <CtaSection />
            <SupportSection />
            <FooterOne />
        </>
    )
}