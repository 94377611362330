import ScrollAnimation from "react-animate-on-scroll";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export default function ImpulsoCardPlan(){

  return(
    <div className="plans-wrapp">
      <ScrollAnimation
      animateIn="fadeInLeft"
      animateOut="fadeOutRight"
      animateOnce={ true }>
      <div className="card-plan">
      <div className="title-wrapp">
        <h2>Plano Standard</h2>
      </div>
      <div className="content-wrapp">
        <span>
          <FaCheckCircle color="#1780FF"/>
          <p>IA com identidade visual da Academy Abroad</p>
        </span>
        <span>
          <FaCheckCircle color="#1780FF"/>
          <p>20 ferramentas padrão</p>
        </span>
      </div>
      <span className="divider"></span>
      <div className="price-wrapp">
        <h3>OFERTA EXCLUSIVA</h3>
        <p>R$ 1.500,00 de implantação + R$ 69,90 por usuário por mês</p>
        <a className="button" href="https://painel.academyabroad.com.br/checkout/ia/standard">COMPRAR AGORA</a>
      </div>
    </div>
    </ScrollAnimation>
    <ScrollAnimation 
    animateIn="fadeInRight"
    animateOut="fadeOutLeft"
    animateOnce={ true }>    
    <div className="card-plan">
      <div className="title-wrapp">
        <span>O MAIS VENDIDO</span>
        <h2>Plano Premium</h2>
      </div>
      <div className="content-wrapp">
        <span>
          <FaCheckCircle color="#1780FF" />
          <p>IA com identidade visual 100% personalizável</p>
        </span>
        <span>
          <FaCheckCircle color="#1780FF"/>
          <p>20 ferramentas padrão</p>
        </span>
        <span>
          <FaCheckCircle color="#1780FF"/>
          <p>10 ferramentas personalizadas</p>
        </span>
      </div>
      <span className="divider"></span>
      <div className="price-wrapp">
        <h3>OFERTA EXCLUSIVA</h3>
        <p>R$ 2.500,00 de implantação + R$ 69,90 por usuário por mês</p>
        <a className="button" href="https://painel.academyabroad.com.br/checkout/ia/premium">COMPRAR AGORA</a>
      </div>
    </div>
    </ScrollAnimation>
    </div>
  )
}