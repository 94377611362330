import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import SearchOne from '../../components/widgets/blog/SearchOne';
import CategoryOne from '../../components/widgets/blog/CategoryOne';
import LatestPostOne from '../../components/widgets/blog/LatestPostOne';
import TagOne from '../../components/widgets/blog/TagOne';
import AboutOne from '../../components/widgets/blog/AboutOne';
import InstagramOne from '../../components/widgets/blog/InstagramOne';
import Comment from '../blog/Comment';
import PostData from '../../data/blog/PostData.json';
import axios from 'axios';
import AboutOneExperiencia from '../../components/widgets/blog/AboutOneExperiencia';
import GaleriaExperiencia from '../../components/widgets/blog/GaleriaExperiencia';

const ExperienciaDetalhe = () => {
    const { uuid } = useParams();
    const id = 2;
    const blogId = parseInt(id, 10);
    const dataItem = PostData.filter(blog => blog.id === blogId);
    const data = dataItem[0];

    const [experiencia, setExperiencia] = useState({
        nome: '',
        vantagens: [],
        desvantagens: [],
        fotos: [],
    });

    useEffect( () => {
        axios.get(`https://api.academyabroad.com.br/api/site/experiencias/${uuid}`)
            .then(response => {
                setExperiencia(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [uuid] );

    return (
        <>
            <SEO title={ data.title } />
            <Layout>
                {/* <BreadcrumbOne 
                    title={ data.title }
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Details"
                /> */}
                <div className="edu-blog-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-8 order-2">
                                <div className="blog-details-1">
                                    <div className="content-blog-top">
                                        <h4 className="title">{ experiencia.nome }</h4>
                                        <ul className="blog-meta">
                                            {experiencia.instituicao && <li><i className="icon-Campus"></i>{ experiencia.instituicao }</li>}
                                            {experiencia.hospital && <li><i className="icon-lab"></i>{ experiencia.hospital }</li>}
                                            {experiencia.periodo && <li><i className="icon-time-line"></i>{ experiencia.periodo }</li>}
                                            {experiencia.cidade && <li><i className="icon-map-pin-line"></i>{ experiencia.cidade }</li>}
                                        </ul>
                                       
                                    </div>

                                    <div className="blog-main-content">
                                        <p dangerouslySetInnerHTML={{__html: experiencia.experiencia}}></p>
                                  
                                        
                                        <div className="row">
                                            {experiencia.vantagens.length > 0  && <div className="col-sm-12">
                                                <h5>Vantagens</h5>
                                                <ul className="list-style-1">
                                                    {
                                                        experiencia.vantagens.map( ( raw, i ) => {
                                                            return (
                                                                <li key={i}><i className="icon-checkbox-circle-fill-solid"></i> {raw}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            }
                                            {experiencia.desvantagens.length > 0  && <div className="col-sm-12">
                                                <h5>Desafios</h5>
                                                <ul className="list-style-1">
                                                    {
                                                        experiencia.desvantagens.map( ( raw, i ) => {
                                                            return (
                                                                <li key={i}><i className="icon-checkbox-circle-fill-solid"></i> {raw}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            }
                                         
                                        </div>
                                    </div>

                                    <div className="blog-tag-and-share mt--50">
                                        { experiencia.tags && experiencia.tags.length > 0 &&
                                            <div className="blog-tag">
                                                <div className="tag-list bg-shade">
                                                    {
                                                        experiencia.tags.map( ( tag, i ) => {
                                                            return (
                                                                <span key={ i }>
                                                                    { tag }
                                                                </span>
                                                            )
                                                        } )
                                                    }
                                                </div>
                                            </div>
                                        }
                                     
                                    </div>


                                </div>
                            </div>
                            <div className="col-lg-4">
                                <aside className="edu-blog-sidebar">
                                    {/* <CategoryOne extraClass="mt--50" />
                                    <LatestPostOne extraClass="mt--50" /> */}
                                    <AboutOneExperiencia extraClass="mt--20" data={experiencia} />
                                    {/* <TagOne extraClass="mt--50" /> */}
                                    <GaleriaExperiencia extraClass="mt--40" data={experiencia} />
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ExperienciaDetalhe;