import Slider from "react-slick";
import img1 from '../../../assets/images/imersao-jose/robledo.png';
import img2 from '../../../assets/images/imersao-jose/heschisa.png';
import img3 from '../../../assets/images/imersao-jose/tatiana.png';
import img4 from '../../../assets/images/imersao-jose/natalia.png';
import img5 from '../../../assets/images/imersao-jose/mario.png';

const SocialSection = () => {

    const ThreeColumnCarousel = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
    };

    const depoimentos = [
        {
            nome:'Mario Takashi',
            profissao: 'Diretor de Finanças Dolce & Gabbana',
            depoimento: 'Recentemente eu fiz uma mentoria voltada para a comunicação com o José e indico para todos! Mesmo que você ache que tenha uma ótima comunicação. Questione se tem mesmo... Perceba se você ouve e presta atenção quando as pessoas falam contigo. Veja se você tem comunicação assertiva. Perceba o tom da sua voz, os gestos e as expressões. O Bergossi questionara e guiará vocês em alguns detalhes importantíssimos que fazem muito sentido no dia a dia. A consultoria dele me ajudou muito na comunicação e oratória e postura. Agradeço pelo tempo que falamos e corrigimos os gaps, além de dominar o tema, se mostrou uma pessoa muito culta e polida!',
            avatar: img5
        },
        {
            nome:'Robledo Leonardo',
            profissao: 'Gerente de TI',
            depoimento: 'A mentoria com José Bergossi foi uma experiência extraordinariamente enriquecedora, especialmente no que tange aos temas de Consciência Corporal e Comunicação Assertiva. José demonstrou não apenas um profundo conhecimento teórico, mas também uma habilidade ímpar em transmitir esses conceitos de maneira clara e aplicável. Suas dicas foram práticas e adaptadas às minhas necessidades específicas, o que tornou a aprendizagem ainda mais eficaz. Além disso, a ênfase na comunicação assertiva foi particularmente valiosa, equipando-me com ferramentas para me comunicar de maneira mais eficiente. A mentoria do José foi, sem dúvida, uma experiência transformadora.',
            avatar: img1
        },
        {
            nome:'Heschisa Bressam',
            profissao: 'Analista de Inovação',
            depoimento: 'O tempo de mentoria com o José transformou a maneira como eu me comunico! Eu pude desenvolver um olhar consciente sobre o modo como eu me comunicava e a partir disto, com o auxílio e direção do José, trabalhei exatamente naquilo que dificultava a forma de eu me expressar. Além disso, as mentorias me ajudaram a construir uma comunicação segura: se comunicar bem vai muito além de ter domínio sobre os assuntos abordados - e o josé me ensinou a avançar de maneira segura para entregar uma mensagem assertiva e torná-la compreensível aos meus ouvintes. ',
            avatar: img2
        },
        {
            nome:'Tatiana Antonio',
            profissao: '',
            depoimento: 'A mentoria com o José Bergossi foi uma experiência enriquecedora. Pontual e de uma sensibilidade ímpar conseguiu conduzir de forma natural e eficaz nosso encontro; atenção e habilidade em transmitir segurança e conhecimento no tema foram fundamentais para o meu aprendizado. Me senti muito à vontade em falar das minhas inseguranças sobre a minha fala e postura e ele fez disso uma forma de mostrar os pontos bons da minha comunicação. Obrigado por sua dedicação e orientação valiosa.',
            avatar: img3
        },
        {
            nome:'Natália Mantovani',
            profissao: 'Psicóloga',
            depoimento: 'A mentoria com o José, desde o primeiro momento até o final foi de grande valia. Ele explicou tudo com muita didática, e depois de ir percebendo minhas dificuldades, pegou ponto por ponto para me explicar. Foi um encontro de muito aprendizado. Um excelente profissional com ética e muito conhecimento. Fundamental para melhorar minha comunicação e me incentivar a cada vez mais expor meu trabalho através da comunicação assertiva.',
            avatar: img4
        }
    ]
    return(
        <div className="container-fluid ptb--50" id="comunicador-expressivo-social-section">
            <div className="container" style={{maxWidth:'800px'}}>
                <div className="row">
                    <div className="col-12 text-center">
                        <span className="text-white fw-normal">DEPOIMENTOS</span>
                        <h4>Quem já participou o que achou da mentoria com José Bergossi</h4>
                    </div>
                </div>
                <div className="row">
                        <Slider className="mt--40 mb--50 edu-slick-button slick-activation-wrapper eduvibe-course-one-carousel"
                        { ...ThreeColumnCarousel }>
                            {depoimentos && depoimentos.map((item, i) => (
                                <div className="col-12 p-3">
                                    <div className="card-depoimento" key={i}>
                                        <div className="pt-3 mb-5">
                                            <p className="fw-light lh-sm text-black" id="depoimento">{item.depoimento}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-start gap-3">
                                            <div className="image-box pt-5">
                                                <img src={item.avatar} alt={`Avatar de ${item.nome}`} />
                                            </div>
                                            <div className="mt-5">
                                                <h6 className="mb-0 lh-sm">{item.nome}</h6>
                                                <p className="fs-5">{item.profissao}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                </div>
            </div>
        </div>
    )
}

export default SocialSection;