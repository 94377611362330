
import { BsPatchCheckFill } from "react-icons/bs";
import imgEmbaixador from '../../assets/images/secao-embaixador-pic.png'

const Embaixador = () => {
    return(
        <div className="container-fluid bg-primary">
            <div className="container px-0">
                <div className="row">
                    <div className="d-none d-lg-grid col-12 col-lg-6">
                        <div className="box-image d-flex align-items-end" style={{height:'450px'}}>
                            <img src={imgEmbaixador} alt="foto ilustrativa" style={{height:'700px', objectFit:'contain', objectPosition:'center bottom'}}/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 py-5">
                        <h4 className="text-white mb-5">Seja um embaixador da Academy e ganhe vantagens exclusivas!</h4>
                        <div className="row">
                            <div className="col-1 text-end">
                                <BsPatchCheckFill style={{color:'#093366', fontSize:'25px'}}/>
                            </div>
                            <div className="col">
                                <p className="text-white lh-sm fw-normal mb-3">Receba um cupom exclusivo para você divulgar os produtos da Academy Abroad.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 text-end">
                                <BsPatchCheckFill style={{color:'#093366', fontSize:'25px'}}/>
                            </div>
                            <div className="col">
                                <p className="text-white lh-sm fw-normal mb-3">Ganhe comissões por cada venda gerada.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 text-end">
                                <BsPatchCheckFill style={{color:'#093366', fontSize:'25px'}}/>
                            </div>
                            <div className="col">
                                <p className="text-white lh-sm fw-normal mb-3">Compartilhe conhecimento e impulsione carreiras</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <p className="text-white fw-normal">Faça parte dessa jornada de sucesso você também!</p>
                            <a className="btn"  style={{backgroundColor:'#093366', color:'#FFFFFF', fontSize:'25px'}} href="http://materiais.theacademyabroad.com.br/programa-embaixadores">Junte-se a nós</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Embaixador;