import React from 'react';
import Slider from 'react-slick';
import { ThreeColumnCarousel4 } from '../../utils/script';
import img1 from '../../assets/images/icons/depoimento.svg'

let data = [
  {
    "id": "1",
    "image": "larissa.jpg",
    "name": "Larissa Veloso Hilarino",
    "designation": "Aprovada no intercâmbio na Cornell University",
    "details": "Já fiquei impressionada na minha primeira mentoria: aulas ao vivo e de ótima qualidade, os mentores super dispostos a nos escutar e esclarecer dúvidas, discussão de assuntos que não achamos facilmente por aí. Todo meu processo de conseguir o estágio na Universidade de Nova York foi realizado com o que eu aprendi nas mentorias. Agradeço deeeemais a Academy e já estou ansiosa para as próximas mentorias!"
  },
  {
    "id": "2",
    "image": "amanda.jpg",
    "name": "Amanda Paganini Lourencini",
    "designation": "Intercambista do Hospital Israelita Albert Einstein",
    "details": "O conhecimento adquirido com a Academy é diferente de tudo que já vi! Ajudar acadêmicos a terem oportunidades em universidades do mundo a faz não só importante, mas relevante para a construção da classe médica do Brasil. Médicos mais capacitados, encorajados e com uma perspectiva aberta, beneficiando a classe, o paciente e toda a sociedade."
  },
  {
    "id": "3",
    "image": "eduardo.jpg",
    "name": "Eduardo Lobo",
    "designation": "Intercambista da Harvard University",
    "details": "A Academy ajudou a abrir meus horizontes para a medicina e me possibilita ajudar outros estudantes a encontrar seu caminhos nas principais faculdades de medicina espalhadas pelo mundo, facilitando o acesso à informação, desde burocracia de visto até o dia a dia no trabalho."
  },
  {
    "id": "4",
    "image": "maria.jpg",
    "name": "Maria Luiza Seixas",
    "designation": "Estudante de Medicina - Santa Casa de São Paulo",
    "details": "Não tenho palavras para descrever o valor que a Academy passa para nós. É ter a oportunidade de entrar em contato com pessoas que passaram pelo mesmo caminho que você quer passar, chegaram no lugar que você quer chegar! Os mentores são extremamente solícitos, pacientes e altamente qualificados para nos auxiliar na conquista do tão sonhado intercâmbio!"
  }
]

const TestimonialSectionFive = () => {
    return (
        <div className="container-fluid bg-primary">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title text-center mt-5">
                          <div>
                              <span className="pre-title" >
                                <img src={img1} alt='depoimentos'/>
                              </span>
                          </div>
                          
                          <div>
                              <h4 className="title text-white h4">O que nossos alunos dizem sobre nós</h4>
                          </div>
                      </div>
                    </div>
                </div>
                <div className="row g-5 mt--25">
                    <div className='col'>
                        <Slider 
                            className="mt--40 mb--50"
                            { ...ThreeColumnCarousel4 }
                        >
                            { 
                                data.map((item) => (
                                  <div className="single-testimonial-2" key={item.id}>
                                      <div className="inner bg-white">
                                          <div className="thumb">
                                              <img src={`${process.env.PUBLIC_URL}/images/testimonial/testimonial-01/${item.image}`} alt="Client Thumb"  />
                                          </div>
                                          <div className="client-information">
                                              <div className="info">
                                                <p className="description">“ { item.details }”</p>
                                                  <h5 className="title">{ item.name }</h5>
                                                  <span className="designation">{ item.designation }</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                ) )
                            }
                        </Slider>
                    </div>
                </div>
                {/* <div className="row g-5 d-flex justify-content-center">
                  <div className="col-4 mb--50">
                    <a href="/experiencias" className="edu-btn btn-medium bg-white text-primary shadow-sm">Veja o Mural de Experiências <FaArrowRight /></a>
                  </div>
                </div> */}
            </div>
        </div>
    )
}

export default TestimonialSectionFive;