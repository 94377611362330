import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const QuestionsExperience = ({questions, program}) => {
    const [expandedItem, setExpandedItem] = useState(null);


    const handleShowData = (index) => {
        setExpandedItem((prev) => (prev === index ? null : index));
    };
    return(
        <div className="container-fluid bg-primary ptb--100">
            <div className="container">
                <h3 className="fw-bold text-white text-center">Dúvidas Frequentes</h3>
                <div className="p-5 m-5" style={{backgroundColor:'var(--color-secondary)', borderRadius:'20px'}}>
                    {questions && questions.map((item, i) => (
                        <div key={i} className={`pt-2 ${i === 9 ? 'border-none' : 'border-bottom border-secondary'}`}>
                            <p className="text-white fw-bold fs-4" onClick={() => handleShowData(i)} style={{cursor:'pointer'}}>{`${i + 1}. ${item.pergunta}`}</p>
                            <p className={`text-white fw-normal fs-5 ${expandedItem === i ? 'd-block' : 'd-none'}`}>{item.resposta}</p>
                        </div>
                    ))}

                </div>

                <div className="row p-5 text-center text-white">
                    <a className="text-white" href="mailto:plataforma@academyabroad.com.br"><IoMdMail style={{color:'var(--color-secondary)', fontSize:'56px'}} /></a>
                    <a className="text-white" href="mailto:plataforma@academyabroad.com.br">plataforma@academyabroad.com.br</a>
                </div>
                <div className="row p-5 text-center">
                    <p className="text-white lh-sm fw-light fs-4">Você pode entrar em contato conosco pelo nosso site ou por e-mail. Teremos o prazer de responder a todas as suas perguntas e fornecer informações detalhadas sobre o programa.</p>
                    <p className="text-white lh-sm fw-light fs-4">{`Estamos aqui para ajudar você a alcançar suas metas médicas internacionais. Não hesite em nos contatar para mais informações ou para iniciar sua jornada com o programa "${program}"`}</p>
                </div>
            </div>
        </div>
    )
}

export default QuestionsExperience;