import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import CourseData from '../../data/course/CourseData.json';
import { ThreeColumnCarousel2 } from '../../utils/script';
import CourseTypeOne from '../../components/course/CourseTypeOne';
import CourseTypeOneAcademy from './CourseTypeOneAcademy';
import axios from 'axios';

const RelatedCoursesAcademy = ( { courseID } ) => {
    const [courses, setCourses] = useState([]);

    useEffect( () => {
        //setActiveFilter( FilterControls[0].toLowerCase() );
        
        axios.get('https://api.academyabroad.com.br/api/site/cursos')
            .then(response => {
                setCourses(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [] );


    return (
        // based on common categories.
        <>
            { courses && courses.length > 0 && 
                <div className="edu-course-wrapper pt--65">
                    <div className="section-title text-start mb--20">
                        <span className="pre-title">Cursos relacionados</span>
                        <h3 className="title">Cursos que você pode gostar</h3>
                    </div>
                    <Slider 
                        className="mt--40 edu-slick-button slick-activation-wrapper eduvibe-course-one-carousel"
                        { ...ThreeColumnCarousel2 }
                    >
                        {
                            courses.map( ( course, index ) => (
                                <div className="single-slick-card" key={ index }>
                                    <CourseTypeOneAcademy data={course} />
                                </div>
                            ) )
                        }
                    </Slider>
                </div>
            }
        </>
    )
}

export default RelatedCoursesAcademy;