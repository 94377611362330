import img from '../../../assets/images/faq-imersoes/4.png'

const BuyIn = ({slug}) => {
    return(
        <div className="container-fluid ptb--100">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 text-center text-lg-start p-5 p-lg-0">
                        <p className="mb-0 fs-5 fw-normal">Não perca tempo</p>
                        <h3 className="mt-0 mb-5 text-primary">Garanta já sua vaga!</h3>
                        <a href={`https://painel.academyabroad.com.br/checkout/imersoes/${slug}`} className="edu-btn mt--150" >Comprar agora</a>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="m-auto" style={{width:'100%', height:'350px', borderRadius:'15px', overflow:'hidden'}}>
                            <img src={img} alt='imersoes' style={{width:'100%', height:'100%', objectFit:'cover', borderRadius:'15px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyIn;