import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import _ from 'lodash';
import { FaArrowRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CourseTypeOneAcademyTwo from '../../components/course/CouserTypeOneAcademyTwo';

const PrevArrow = ( props ) => {
    const { onClick } = props;
    return (
        <button className="slide-arrow prev-arrow" onClick={ onClick } >
            <FaChevronLeft className="icon-arrow-left-line" />
        </button>
    )
}

const NextArrow = (props) => {
    const { onClick } = props;
    return  (
        <button className="slide-arrow next-arrow" onClick={ onClick } >
            <FaChevronRight className="icon-arrow-left-line" />
        </button>
    )
}


const CourseCarouselThree = ({title, categoria}) => {
  const [cursos, setCurso] = useState([]);

  const ThreeColumnCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
  
  
    useEffect( () => {
        axios.get(`https://api.academyabroad.com.br/api/site/mentor`)
            .then(response => {
                //setCurso(response.data.data);
                setCurso(_.slice(_.shuffle(response.data.data.filter((item) => item.categorias.some((category) => categoria.includes(category)))),0 , 10));
            })
            .catch(error => {
                console.error(error);
            });
    }, [] );

    return (
      <div className="container-fluid ptb--120 z-index-2 position-relative" style={{backgroundColor:'#F5F5F5'}}>
          <div className="row g-5 d-flex justify-content-center">
              <div className="col-lg-9 h-100">
                  <div className="section-title text-center">
                      <p className="fs-3 mb-0" style={{color:'#093366'}}>quem pode te ajudar nesse processo</p>
                      <h3 className="text-primary mb-0">{title}</h3>
                  </div>
                  
                  <Slider 
                      className="mt--40 mb--50 slick-arrow-style-3 slick-activation-wrapper eduvibe-course-one-carousel px-5"
                      { ...ThreeColumnCarousel }
                  >
                      { 
                          cursos.map((item) => (
                              <div className="single-slick-card" key={ item.slug }>
                                  <CourseTypeOneAcademyTwo data={item} />
                              </div>
                          ) )
                      }
                  </Slider>

                  <div className="col d-flex justify-content-center align-items-center">
                    <a href="/mentores" className="edu-btn btn-medium">Conheça todos nossos mentores <FaArrowRight /></a>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default CourseCarouselThree;