import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import SearchOne from '../../components/widgets/blog/SearchOne';
import CategoryOne from '../../components/widgets/blog/CategoryOne';
import LatestPostOne from '../../components/widgets/blog/LatestPostOne';
import TagOne from '../../components/widgets/blog/TagOne';
import BannerOne from '../../components/widgets/blog/BannerOne';
import Comment from '../blog/Comment';
import PostData from '../../data/blog/PostData.json';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import avatar from '../../assets/images/icons/avatar.jpg'
import PostsAuthor from '../../components/widgets/blog/PotsAuthor';

const BlogDetailAcademy = () => {
    const { id } = useParams();
    const { uuid } = useParams();

    const blogId = 10;
    const dataItem = PostData.filter(blog => blog.id === blogId);
    const data = dataItem[0];

    const [post, setPost] = useState({
        "uuid": "",
        "slug": "",
        "titulo": "",
        "descricao": "",
        "dt_post": "",
        "foto_url": "",
        "categoria": "",
        "conteudo": "",
        "tags": [],
        "autor": []
    });

    useEffect( () => {
        axios.get(`https://api.academyabroad.com.br/api/site/artigos/${uuid}`)
            .then(response => {
                setPost(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [uuid] );

    return (
        <>
            <SEO title={ data.titulo } />
            <Layout>
                {/* <BreadcrumbOne 
                    title={ data.title }
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Details"
                /> */}
                <div className="edu-blog-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                <div className="blog-details-1 style-variation2">
                                    <div className="content-blog-top">
                                        <div className="thumbnail">
                                            <img className="radius-small w-100 mb--30" src={`${post.foto_url}`} style={{objectFit:'cover', maxHeight: '480px'}} alt="Blog Thumb" />
                                        </div>
                                        {/* <ul className="blog-meta">
                                            <li className="blog-author"><img src="/images/blog/author/author-small/author.png" alt="Blog Images" /> <Link to={process.env.PUBLIC_URL + `/author/${slugify( data.author ) }`}>{data.author}</Link></li>
                                            <li><i className="icon-calendar-2-line"></i>{ data.date }</li>
                                            <li><i className="icon-discuss-line"></i>{ data.comment }</li>
                                            <li><i className="icon-time-line"></i>{ data.readingTime }</li>
                                        </ul> */}
                                        <h4 className="title">{ post.titulo }</h4>
                                    </div>

                                    <div className="blog-main-content" dangerouslySetInnerHTML={{__html: post.conteudo}}>
                                    </div>

                                    <div className='row mt--100'>
                                        <div className="col-4 px-0 me-3" style={{width:'100px', height:'100px'}}>
                                            <img src={!post.autor.foto ? avatar : post.autor.foto} alt='foto autor' style={{width:'100%', height:'100%', objectFit:'cover', borderRadius:'50%'}} />
                                        </div>
                                        <div className="col">
                                            <h4 className='mb-1'>{post.autor.nome}</h4>
                                            <p className='fs-3'>{post.autor.descricao}</p>
                                        </div>
                                    </div>


                                    <div className="edu-comment-form mt--50">
                                        <Comment 
                                            url=""
                                            id={post.uuid}
                                            title={post.titulo}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <aside className="edu-blog-sidebar">
          
                                    <LatestPostOne style2="enable" uuid={post.uuid} />
                                    <TagOne extraClass="mt--50" tags={post.tags} style2="enable" />
                                    <PostsAuthor style2="enable" props={post}/>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default BlogDetailAcademy;