import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import {formatToBRL} from 'brazilian-values';


const CourseTypeOneAcademyTwo = ({ data, classes }) => {

    const styleLink = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        height: '66px',
        lineHeight: '23px'
    };
    const excerpt = data.descricao_mentor.substring(0, 120) + "...";
    const excerptHover = data.descricao_mentor.substring(0, 220) + "...";
    const name = data.nome.substring(0, 20);
    return (
        <div className={`edu-card card-type-3 radius-big ${ classes ? classes : '' }`}>
            <div className="inner">
                <div className="d-flex align-items-center justify-content-center w-100 mt-4">
                    <Link to={process.env.PUBLIC_URL + `/mentor/${data.slug}`}
                        style={{width:'130px', height:'130px'}}
                    >
                        <img 
                        className="w-100 h-100 object-fit-cover rounded-circle"
                        style={{border:'3px solid #1780FF'}}
                        src={data.foto_perfil_url ? data.foto_perfil_url : data.foto_principal_url} 
                        alt="Course Thumb"/>
                    </Link>
                    {/* <div className="wishlist-top-right">
                        <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                    </div> */}
                </div>

                <h6 className="title text-center mt-3 mb-1">
                    {name}
                </h6>

                <div className="row d-flex justify-content-center mt-0 mb-4 py-0">
                    <div className="col-4 d-flex justify-content-around mt-0 py-0">
                        <i className="icon-Star fs-5"></i>
                        <i className="icon-Star fs-5"></i>
                        <i className="icon-Star fs-5"></i>
                        <i className="icon-Star fs-5"></i>
                        <i className="icon-Star fs-5"></i>
                        {data.nota.lenght >= 1 && (
                            <p>{data.nota}</p>
                        )}
                    </div>
                    
                </div>

                <div className="divider"></div>

                <div className="row d-flex justify-content-center">
                    <div className="col-11" style={{height:'80px'}}>
                        <p className='fs-5 text-center'>{excerpt}</p>
                    </div>
                </div>

                <div className="row w-100 d-flex justify-content-center">
                    <div className="d-flex justify-content-center gap-2">
                    { data.categorias.length >= 1 && (
                        <>
                            {data.categorias.slice(0,3).map((item, i) => (
                                <div key={i} 
                                className="fs-6 mt-5 py-1 px-3" 
                                style={{backgroundColor:'#09336620', color:'#093366', borderRadius:'5px'}}>
                                    {item}
                                </div>
                            ))}
                        </>
                    ) }
                    </div>
                </div>

                <div className="row d-flex justify-content-center w-100 my-4">
                    <Link className="edu-btn btn-medium col-7 text-center mb-4" to={process.env.PUBLIC_URL + `/mentor/${data.slug}`}>
                        Ver mentoria
                        <i className="icon-arrow-right-line-right"></i>
                    </Link>
                </div>

                
                {/* <div className="content">
                    <div className="card-top">
                        <div className="author-meta">
                            <div className="author-thumb">
                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( data.instructor ) }`}>
                                    <img src={`${process.env.PUBLIC_URL}/images/instructor/instructor-small/${instructorThumb}`} alt="Author Thumb" />
                                    <span className="author-title">{ data.instructor }</span>
                                </Link>
                            </div>
                        </div>
                        <ul className="edu-meta meta-02" style={{height:"66px"}}>
                            <li><i className="icon-file-list-3-line"></i>{data.licoes} Lições</li>
                            <li><i className="icon-time-line"></i>{data.duracao}</li>
                            <li><i className="icon-group-line"></i>{data.alunos}</li>
                        </ul>
                    </div>
                    
                    <div className="card-bottom">
                        <div className="price-list price-style-02">
                            {
                                data.preco == null ?
                                    <div className="price current-price">Grátis</div>
                                :
                                    <div className="price current-price">{formatToBRL(data.preco.valor)}</div>
                            }
                        </div>
                        <div className="edu-rating rating-default">
                            <div className="rating eduvibe-course-rating-stars">
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                            </div>
                            <span className="rating-count">{data.nota}</span>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="card-hover-action">
                <div className="hover-content">
                    <div className="content-top">
                        { data.tags.length >=1 && <div className="top-status-bar">
                            <Link className="eduvibe-status status-03 fs-6" to={process.env.PUBLIC_URL + `/mentor/${ data.slug }`}>
                                {data.tags.slice(0, 1)}
                            </Link>
                        </div>}
                        {/* <div className="top-wishlist-bar">
                            <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                        </div> */}
                    </div>

                    <h6 className="title">
                        <Link style={styleLink} to={process.env.PUBLIC_URL + `/mentor/${data.slug}`}>{data.nome}</Link>
                    </h6>

                    <p className="description fs-5 mb-3">{ excerptHover }</p>

                    {/* <div className="price-list price-style-02">
                        {
                            data.valor_mentoria == null ?
                                <div className="price current-price">Grátis</div>
                            :
                                <div className="price current-price">{formatToBRL(data.valor_mentoria.valor)}</div>
                        }
                    </div> */}

                    <div className="hover-bottom-content">
                        {/* <div className="author-meta">
                            <div className="author-thumb">
                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${slugify( data.instructor ) }`}>
                                    <img src={`${process.env.PUBLIC_URL}/images/instructor/instructor-small/${instructorThumb}`} alt="Author Thumb" />
                                    <span className="author-title">{ data.instructor }</span>
                                </Link>
                            </div>
                        </div> */}
                        {/* <ul className="edu-meta meta-02">
                            <li><i className="icon-file-list-3-line"></i>{data.licoes} Lições</li>
                            <li><i className="icon-time-line"></i>{data.duracao}</li>
                            <li><i className="icon-group-line"></i>{data.alunos}</li>
                        </ul> */}
                    </div>
                    <div className="read-more-btn">
                        <Link className="edu-btn btn-medium btn-white" to={process.env.PUBLIC_URL + `/mentor/${data.slug}`}>
                            { data.preco == null ? 'Inscreva-se Agora' : 'Comprar Agora' }
                            <i className="icon-arrow-right-line-right"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CourseTypeOneAcademyTwo;