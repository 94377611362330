import ScrollAnimation from "react-animate-on-scroll";
import ImpulsoCardPlan from "./ImpulsoCardPlan";

const ImpulsoSection3 = () =>{
  return(
    <section  id="section-3">
      <div className="box-l">
        <div className="card-text-wrapp">
          <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeOutDown"
          animateOnce={ true }>
            <h3>Impulso: Seu Parceiro em Negócios</h3>
          </ScrollAnimation>
          <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeOutDown"
          animateOnce={ true }>
            <h4>Plano Standard</h4>
            <p>Nossa solução standard oferece 15 ferramentas padrões, impulsionadas pela tecnologia da OpenAI.</p><p>Elas abrangem funções como planejamento estratégico, execução de projetos, gestão financeira e relações de negócios, tudo para descomplicar a gestão da sua empresa através da inteligência artificial.</p>
          </ScrollAnimation>
          <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeOutDown"
          animateOnce={ true }>
            <h4>Plano Premium</h4>
            <p>Esta versão vai além das 15 ferramentas padrões do produto standard, oferecendo personalização de layout e até 10 ferramentas personalizadas.</p>
            <p>Projetado para atender necessidades específicas, ele melhora a eficiência e produtividade da sua empresa, sendo ideal para aqueles que buscam uma solução mais integrada e customizada.</p>
          </ScrollAnimation>
          
        </div>
      </div>
      <div className="box-r" id="planos">
        <div className="plans-container">
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeOutDown"
          animateOnce={ true }>
            <p>Descubra como a inteligência artificial pode aumentar a eficiência da sua empresa. Escolha o plano que melhor se adapta às suas necessidades e dê o primeiro passo para o futuro da gestão empresarial.</p>
          </ScrollAnimation>
          <ImpulsoCardPlan />
        </div>
      </div>
    </section>
  )
}

export default ImpulsoSection3;