import img1 from '../../assets/images/kenji.jpg'
import { FaArrowRight, FaCheck } from "react-icons/fa";

const AboutExperience = () => {
    return(
        <div className="container-fluid ptb--100">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className='experience-image-box mb-5 mb-lg-0'>
                        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/5x2jTLdNXCI?si=bTBSwkpcyEVQkwqU&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                        <img src={img1} alt='foto descritiva' />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <h5 className='text-primary'>
                            Bem-vindo ao programa "Experience Hunting" da Academy Abroad, sua porta de entrada para uma carreira médica internacional
                        </h5>
                        <p className='fw-bold'>Para quem é o "Experience Hunting"?</p>
                        <ul>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Oportunidades de Residência: Amplie seus horizontes com programas de residência em renomados hospitais internacionais.</div>
                            </li>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Estágios Desafiadores: Ganhe experiência prática em instituições médicas de classe mundial.</div>
                            </li>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Pesquisa Internacional: Colabore com especialistas globais em projetos de pesquisa médica inovadores.</div>
                            </li>
                            <li className='row'>
                                <div className='col-1'><FaCheck className='text-primary' /></div> 
                                <div className='col'>Trabalho em Hospitais no Exterior: Inicie sua carreira como médico em um ambiente internacional e diversificado.</div>
                            </li>
                        </ul>

                        <a href='https://wkf.ms/3PwhOCn' className='edu-btn'>Inscrever agora <FaArrowRight /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutExperience;