import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CourseTypeFilter from '../../components/course/CourseTypeFilter';
import MentorFilter from '../../components/course/MentorFilter';
import MentoriaGrupoFilter from '../../components/course/MentoriaGrupoFilter';

const MentoriasGrupo = () => {
    return (
        <>
            <SEO title="Mentorias em grupo" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Course Filter 1"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Course Filter 1"
                /> */}
                    <div className="edu-course-area course-three-wrapper edu-section-gap bg-color-white position-relative">
                        <div className="container">
                            <MentoriaGrupoFilter itemToShow="9" />
                        </div>
                    </div>
            </Layout>
        </>
    )
}

export default MentoriasGrupo;