import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, image, url }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title ? `${title} || Academy Abroad` : 'Academy Abroad'}</title>

            {/* Meta tags padrão */}
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

            {/* Open Graph (para redes sociais) */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            
        </Helmet>
    );
};

export default SEO;
