const CallToActionAbstrat = ({img, text}) => {
  return(
    <div className="container-fluid d-flex justify-content-center">
      <div className="container row d-flex justify-content-between ptb--120">
        <div className="col-12 col-lg-6">
          <div style={{height:'390px'}}>
            <img style={{width:'100%', height:'100%', objectFit:'cover', borderRadius:'15px'}} src={img} alt="imagem ilustrativa" />
          </div>
        </div>
        <div className="col-12 col-lg-5 mt-lg-0 mt-5">
          <h3 className="text-center text-lg-start" style={{color:'#093366'}}>{text}</h3>
        </div>
      </div>
    </div>
  )
}

export default CallToActionAbstrat