import imgHero from '../../../assets/images/imersao-jose/img-hero.png';

const HeroSection = () => {
    return(
        <div className="container-fluid ptb--50" id="imersao-jose-hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 pt--100">
                        <h1 className="h2">Os 4 segredos de uma comunicação de sucesso com José Bergossi</h1>
                        <p className="fs-1">Descubra os 4 Segredos de uma Comunicação de Sucesso!</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="img-box">
                            <img src={imgHero} alt='imagem hero section' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12' id='divider'></div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 pt-5'>
                        <h3 className='text-center lh-sm mt-5 px-5 mx-5'>Bem-vindo à Imersão "Os 4 Segredos de uma Comunicação de Sucesso" com José Bergossi!</h3>
                        <p className='text-center lh-base px-5 mx-5'>Nesta imersão, você terá a oportunidade de aprender estratégias comprovadas para melhorar suas habilidades de comunicação e impulsionar sua carreira.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;