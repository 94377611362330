import { FaCheckCircle } from "react-icons/fa"

const ImersaoInvest = ({action, data, title, valor}) =>{

  const parcela = valor / 12

  function formatarMoeda(numero) {
    return numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}


  return(
    <div className="bhi-how-it-works container-fluid d-flex flex-column align-items-center justify-content-around ptb--100">
      <div className="container text-center">
        <h2 className="h4 text-light">{title}</h2>
        <span className="radius py-3 px-4 text-white" style={{backgroundColor:'var(--color-gold)'}}>vagas limitadas</span>
      </div>
      <div className="container ptb--75">
        <div className="invest-card-container">
          <div className="invest-feats">
            {data && data.map((item, i) =>(
              <div key={i} className="row">
                <div className="col-1">
                  <FaCheckCircle className="fs-2" style={{color:'var(--color-gold)'}} />
                </div>
                <div className="col fw-normal" style={{color:'var(--color-secondary)'}}>
                  {item.vantagem}
                </div>
              </div>
            ))}
          </div>
          <div className="invest-cta">
            <h5 className="h5 text-white">Investimento</h5>
            <span className="fs-2 text-light">12x de</span>
            <h3 className="text-white mb-0">{formatarMoeda(parcela)}</h3>
            <p className="fs-4 mt-0 mb-1 text-light">{`Total de  ${formatarMoeda(valor)}`}</p>
            <p className="fs-5 mt-0 text-light">{`Ou R$21.800,00 à vista`}</p>
            <p className="fs-4 text-white mt-3">Café da manhã e almoços inclusos!</p>
            <a className="edu-btn" style={{backgroundColor:'var(--color-bhi-blue)'}} href={action}>Garanta sua vaga</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImersaoInvest