import { FaMedal, FaUsers, FaYoutube } from "react-icons/fa";
import { IoChatbubblesSharp } from "react-icons/io5";
import { GiBullseye } from "react-icons/gi";
import { HiChatAlt2 } from "react-icons/hi";
import { MdGroups } from "react-icons/md";

const ProgramSection = () => {
    return(
        <div className="program-section-container container-fluid ptb--100">
            <div className="container">
                <div className="row">
                    <h2 className="h3 text-primary text-center mb-0">Explore uma jornada transformadora</h2>
                    <p className="text-white fs-1 fw-normal text-center">Como serão seus encontros com a Patrícia:</p>
                </div>
                <div className='row gap-3 gap-sm-0'>
                    <div className='col-12 col-md-3'>
                        <div className='card' id='card-1'>
                            <div className='content'>
                                <FaUsers className='icon' />
                                <h4>Encontro 1: </h4>
                                <h5>Transição de Carreira: Vivendo Meu Propósito</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='card' id='card-2'>
                            <div className='content'>
                                <FaYoutube className='icon' />
                                <h4>Encontro 2: </h4>
                                <h5>Habilidades e Competências Necessárias para Realizar uma Transição de Carreira Suave</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='card' id='card-3'>
                            <div className='content'>
                                <IoChatbubblesSharp className='icon' />
                                <h4>Encontro 3:</h4>
                                <h5>Definindo as Novas Estratégias para uma Transição de Carreira de Sucesso</h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-3'>
                        <div className='card' id='card-4'>
                            <div className='content'>
                                <GiBullseye className='icon' />
                                <h4>Encontro 4: </h4>
                                <h5>Posicionando-se para Atrair Novos Clientes na Nova Profissão</h5>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="bonus-container ptb--50">
                        <h3 className="text-white text-center mb-0 lh-sm"><span className="text-primary">+</span>bonus<span className="text-primary">.</span></h3>
                        <p className="text-white fw-normal fs-1 text-center">Como serão seus encontros com a Patrícia:</p>

                        <div className="row d-flex justify-content-center gap-5">
                            <div className='col-12 col-md-3'>
                                <div className='card' id='card-5'>
                                    <div className='content'>
                                        <HiChatAlt2 className='icon' />
                                        <h4>1 encontro individual com a Patrícia</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div className='card' id='card-6'>
                                    <div className='content'>
                                        <FaMedal className='icon' />
                                        <h4>Plano de ação individualizado</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div className='card' id='card-7'>
                                    <div className='content'>
                                        <MdGroups className='icon' />
                                        <h4>Grupo de networking com os participantes</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-4 banner-2-container">
                        apenas 6 vagas
                    </div>
                </div>

                <div className="row ptb--100">
                    <h3 className="text-primary text-center mb-0">O que você está esperando para</h3>
                    <p className="p-name text-white text-center">Mudar de carreira?</p>
                    <div className="col-12 d-flex justify-content-center">
                        <span className="box-price mt-5">R$ 158,30/mês</span>
                    </div>
                    <p className="text-white text-center my-3 fs-1">ou</p>
                    <div className="col-12 d-flex justify-content-center">
                        <span className="box-price">R$ 1.900,00 à vista</span>
                    </div>
                    <div className="col-12 d-flex justify-content-center mt-5">
                        <span className="text-white text-center bg-primary rounded-pill py-2 px-4 fs-2" style={{width:'fit-content'}}>apenas 6 vagas</span>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <a href="https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/plano-de-voo-da-transicao-de-carreira" className="banner px-5">Quero transformar minha carreira</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramSection;