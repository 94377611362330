import SEO from "../../common/SEO";
import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import Banner1 from "./components/Banner1";
import HeroSection from "./components/HeroSection";
import ProgramSection from "./components/ProgramSection";

export default function ProgramaTransicaodeCarreira(){
    return(
        <>
            <SEO title={"Plano de Voo da Transição de Carreira"}/>
            <HeaderTwo styles=" header-style-2" />
            <HeroSection />
            <Banner1 />
            <ProgramSection />
            <FooterOne />
        </>
    )
}