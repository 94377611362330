import Slider from "react-slick"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

const ImersaoCtaSection = ({action, titulo, descritivo, button, slide}) =>{

  const PrevArrow = ( props ) => {
    const { onClick } = props;
    return (
        <button className="slide-arrow prev-arrow" onClick={ onClick } >
            <FaChevronLeft className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}

const NextArrow = (props) => {
    const { onClick } = props;
    return  (
        <button className="slide-arrow next-arrow" onClick={ onClick } >
            <FaChevronRight className="icon-arrow-left-line" style={{color:'var(--color-extra01)'}} />
        </button>
    )
}



  const slideImageList = slide.slice().reverse()

  const ThreeColumnCarousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
   
}

  return(
    <div className="container-fluid row mx-0 pt--50 pb--100 bg-white justify-content-center">
      <div className="container row justify-content-between align-items-center" style={{maxWidth:'1500px'}}>
        <div className="col-12 col-lg-6">
          <h2 className="h5" style={{color:'var(--color-gold)'}}>{titulo}</h2>
          <div className='row mb-5' dangerouslySetInnerHTML={{__html: descritivo}}></div>
          <a className="edu-btn" style={{backgroundColor:'var(--color-gold)'}} href={action}>{button}</a>
        </div>
        <div className="col-12 col-lg-6 align-items-center justify-content-lg-end justify-content-center">
          <Slider
          className="mt--40 mb--50 slick-arrow-style-3 px-5 ms-5"
          {...ThreeColumnCarousel}>
            
            {slideImageList.map((image, i) => (
              <div key={i}
              style={{
                width:'700px',
                height:'446px',
                borderRadius:'13px'
              }}>
                <img style={{
                  width:'100%',
                  height:'100%',
                  objectFit:'cover',
                  objectPosition:'center top',
                  borderRadius:'13px'}} 
                  src={image} 
                  alt="slider" />
              </div>
            ))}
            

          </Slider>
        </div>
      </div>
    </div>
  )
}

export default ImersaoCtaSection