import ScrollAnimation from 'react-animate-on-scroll';
import image1 from '../../../assets/images/bg/Seção 2 - Imagem 1.png'
import image2 from '../../../assets/images/bg/Seção 2 - Imagem 2.png'


const ImpulsoSection2 = () =>{
  return(
    <section id="section-2">
      <div className="box-l">
        <div className="image-container">
          <div className="image-box" id="image-1">
            <img src={image1} alt='imagem reunião' />
          </div>
          <div className="image-box" id="image-2">
          </div>
          <div className="image-box" id="image-3">
            <img src={image2} alt='imagem reunião' />
          </div>
      </div>
      </div>
      <div className="box-r">
        <div className="card-text">
          <div className="card-text-wrapp">
            <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeOutDown"
            animateOnce={ true }>
              <h3>Inovação e Crescimento para o seu Negócio!</h3>
            </ScrollAnimation>
            <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeOutDown"
            animateOnce={ true }>
              <p>Impulso não é apenas uma IA - é o seu consultor empresarial personalizado. Nossa tecnologia generativa aprende continuamente com suas interações, adaptando-se para fornecer insights e diretrizes estratégicas que se alinham com os objetivos da sua empresa. Com o Impulso, você tem uma ferramenta poderosa que redefine as regras do jogo no mundo empresarial.</p>
            </ScrollAnimation>
          </div>
          
            <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeOutDown"
            animateOnce={ true }>
              <div className="button-wrapp">
                <a href="#section-4">PARA STARTUP</a> 
                <a href="#section-5" id="second">PARA EMPRESA JR</a>
              </div>
            </ScrollAnimation>
          
        </div>
      </div>
    </section>
  )
}

export default ImpulsoSection2;