import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import SEO from "../../common/SEO";
import HeroSection from "./components/HeroSection";
import "../descubra-o-idioma-ideal-com-a-academy-abroad/styles.scss"
import FlagSection from "./components/FlagSection";
import PathSection from "./components/PathSection";
import BenefitsSection from "./components/BenefitsSection";
import TeachersSection from "./components/TeachersSection";
import Banner1 from "./components/Banner1";
import InvestSection from "./components/InvestSection";
const DescubraOIdiomaIdealComaAcademyAbroad = () => {
    return(
        <div id="imersao-trilingue-page">
            <SEO  title={"Descubra o idioma ideal com a Academy Abroad"}/>
            <HeaderTwo styles=" header-style-2" />
            <HeroSection />
            <Banner1 />
            <FlagSection />
            <PathSection />
            <BenefitsSection />
            <TeachersSection />
            <InvestSection />
            <FooterOne />
        </div>
    )
}

export default DescubraOIdiomaIdealComaAcademyAbroad;