const BhiHeader = ({logo}) => {
    return(
        <div className="container-fluid py-4 m-0 px-0 shadow" style={{backgroundColor:'var(--color-bhi-blue)'}}>
            <div className="d-flex justify-content-center align-items-center">
                <img src={logo} alt='logo bhi' style={{width:'250px'}} />
            </div>
        </div>
    )
}

export default BhiHeader;