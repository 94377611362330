
export default function CallToActionFour({title, description, link, button, img}){
  return(
    <div className="container-fluid d-flex justify-content-center call-to-action-four ptb--120">
      <div className="row container-fluid">
        <div className="col-12 col-lg-6 d-flex justify-content-center">
          <div className="box-image">
            <img className='call-to-action-four-img' src={img} alt="imagem cta"/>
          </div>
        </div>
        <div className="col-12 col-lg-4 d-flex flex-column align-items-center align-items-lg-start">
          <h3 className="text-primary text-center text-lg-start">{title}</h3>
          <p className='mb-5 text-center text-lg-start'>{description}</p>
          <a className="edu-btn cta-four-btn mt-5 fs-5 align-center" href={link}>{button}</a>
        </div>
      </div>
    </div>
  )
}