import img1 from '../../../assets/images/imersao-trilingue/Prancheta 1 1.png'
const HeroSection = () => {
    return(
        <div className="container-fluid pt--50" id="hero-section-imersao-trilingue">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className='pt-1 pt-md-5 pe-5'>
                            <h1>Programa de Imersão Trilíngue com a Academy Abroad: Descubra o Idioma Ideal para Você</h1>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='box-image'>
                            <img src={img1} alt='imagem imersão' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;