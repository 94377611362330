import { FaArrowCircleRight } from "react-icons/fa";

const BillingSection = () => {
    return(
        <div className="container-fluid ptb--50" id="comunicador-expressivo-billing-section">
            <div className="container">
                <div className="row">
                    <h4 className="text-center text-white">Investimento</h4>
                </div>
                <div className="row text-center">
                    <p className="fs-5 mb-0 fw-light">6x de</p>
                    <h3 className="mb-0">R$ 145,00</h3>
                    <p className="fs-5">ou R$ 840,00 à vista</p>
                    <p className="fw-light">Não perca essa oportunidade única de se transformar em um comunicador expressivo!</p>
                    <div className="btn-billing-box mx-auto">
                        <a className='billing-btn' href='https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/comunicador-expressivo'><FaArrowCircleRight /> Garanta a sua vaga</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingSection;