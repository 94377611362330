import { FaCalendar, FaClock } from 'react-icons/fa';
import imgBanner1 from '../../../assets/images/imersao-jose/img-program-banner-1.png';
import imgBanner2 from '../../../assets/images/imersao-jose/locker.png';
import { IoMdKey } from "react-icons/io";


const ProgramSection = () => {

    const data = [
        {
            date: '03/07/2024',
            hour: '19h30'
        },
        {
            date: '10/07/2024',
            hour: '19h30'
        },
        {
            date: '17/07/2024',
            hour: '19h30'
        },
        {
            date: '24/07/2024',
            hour: '19h30'
        }
    ]

    return(
        <div className="container-fluid ptb--100" id="program-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="banner-1 row">
                            <div className="col-12 col-md-5">
                                <div className="banner1-image-box">
                                    <img src={imgBanner1} alt='imagem banner 1' />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <h5 className='mt-5'>Por que participar:</h5>
                                <ul className='mt-5'>
                                    <li><IoMdKey className='icon fs-1' /> Aprenda com um especialista renomado em comunicação;</li>
                                    <li><IoMdKey className='icon fs-1' /> Descubra técnicas práticas para se comunicar de forma eficaz;</li>
                                    <li><IoMdKey className='icon fs-1' /> Impulsione sua carreira e conquiste seus objetivos profissionais;</li>
                                    <li><IoMdKey className='icon fs-1' /> Ganhe um e-book exclusivo sobre os segredos de uma comunicação assertiva;</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row pt--50'>
                    <span className='text-white text-center fs-4'>ANOTE NA AGENDA</span>
                    <h4 className='text-center'>Vamos nos encontrar nos dias:</h4>
                </div>

                <div className='row'>
                    {data && data.map((item, i) => (
                        <div className='col' key={i}>
                            <div className='card-program mt-5'>
                                <div className='title-box mb-5'>
                                    <h6 className='title-imersao fw-normal text-center mb-2'>{`ENCONTRO ${i+1}`}</h6>
                                </div>
                                <div className='text-center'><FaCalendar className='text-white fs-1'/></div>
                                <p className='title-imersao fw-light text-center mt-2'>{item.date}</p>
                                <div className='text-center'><FaClock className='text-white fs-1'/></div>
                                <p className='title-imersao fw-light text-center mt-2'>{item.hour}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='row mt--100'>
                    <div className='col-12 d-flex justify-content-center align-items-center'>
                        <div className='banner-2 row'>
                            <div className='col-7'>
                                <h5>Investimento</h5>
                                <span>12x de R$83,33</span>
                                <p className='mt-2 mb-0'>(R$247,50 por cada mentoria)</p>
                                <p>Valor total: R$ 900,00</p>
                                <a href='https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/4-segredos-de-uma-comunicacao-de-sucesso-com-jose-bergossi'>
                                    <div className='program-btn text-center'>INSCREVA-SE AGORA</div>
                                </a>
                            </div>
                            <div className='col-5'>
                                <div className='banner2-image-box'>
                                    <img src={imgBanner2} alt='cadeado' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramSection;