import img1 from '../../assets/images/inter-1.png'
import img2 from '../../assets/images/inter-2.png'


export default function HowItWorksThree(){
  return(
    <div className="container">
      <div className="row ptb--120 d-flex justify-content-between">
        
        <div className="col-12 col-lg-7 d-flex how-it-works-img-box">
          <div className="how-it-works-img align-self-start" id='image-3'>
            <img src={img1} alt="team"/>
          </div>
          <div className="how-it-works-img align-self-end" id='image-4'>
            <img src={img2} alt="team" />
          </div>
        </div>

        <div className="col-12 col-lg-4">
            <div className="row d-flex flex-column justify-content-between h-100 pt-5">
              <div>
                <p className="fs3 mb-0 fw-bolder">conheça nossos</p>
                <h3 className="h3 text-primary">Vejas as oportunidades de intercâmbio da Academy</h3>
              </div>
              <a className="edu-btn" style={{width:'295px'}} href="/editais-intercambio">Ver todas as vagas</a>
            </div>
        </div>
      </div>
    </div>
  )
}