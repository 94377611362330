import img1 from '../../../assets/images/comunicador-expressivo/lampada-2.svg'
const AboutSection = () => {

    const data = [
        {
            title: 'Autoconhecimento e Consciência Corporal:',
            description: 'Explore como suas expressões corporais afetam a comunicação e técnicas para alinhar corpo e mensagem.'
        },        
        {
            title: 'Voz e Dicção',
            description: 'Melhore a projeção vocal, entonação, clareza e controle da respiração.'
        },        
        {
            title: 'Gestão de Emoções',
            description: 'Controle o nervosismo e utilize emoções para envolver seu público.'
        },        
        {
            title: 'Comunicação Assertiva',
            description: 'Seja claro e objetivo com ferramentas de retórica, storytelling e argumentação.'
        },        
        {
            title: 'Comunicação Não-Verbal',
            description: 'Use gestos, expressões faciais e postura para reforçar sua mensagem.'
        },        
        {
            title: 'Estruturação de Discurso',
            description: 'Organize suas ideias de forma lógica e envolvente.'
        },        
        {
            title: 'Interação com o Público',
            description: 'Engaje e mantenha a atenção do     público, lidando com imprevistos.'
        },        
        {
            title: 'Estilos de Comunicação',
            description: 'Adapte seu estilo de comunicação para diferentes audiências.'
        },
    ]

    return(
        <div className="container-fluid ptb--50" id="comunicador-expressivo-about-section">
            <div className="container">
                <div className="row">
                    <h3 className="text-center text-white">O que você irá aprender:</h3>
                </div>
                <div className="row">
                    <div className="col-12 col-md-10 mx-auto">
                        <div className="row">
                            {data && data.map((item, i) => (
                                <div className="col-12 col-md-6 mb-4" key={i}>
                                    <div className="card-about">
                                        <div className="d-flex align-items-center justify-content-start">
                                            <div>
                                                <div className="img-box-about me-3">
                                                    <img src={img1} alt='imagem lampada' />
                                                </div>
                                            </div>
                                            <div>
                                                <h5>{item.title}</h5>
                                            </div>
                                        </div>
                                        <div className='description-box'>
                                            <p className='mt-5'>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection;