import bg from '../../../assets/images/bg/bg-patricia.png'
const HeroSection = () => {
    return(
        <div className="transicao-carreira-hero container-fluid">
            <div className="container">
                <div className="row ptb--100">
                    <div className="col-12 col-lg-5">
                        <h1>Plano de Voo da Transição de Carreira</h1>
                        <div className="pt--50">
                            <p className="text-white fs-1 mb-0 lh-sm fw-normal">Com a mentora</p>
                            <p className="p-name text-primary">Patrícia Cardoso</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className='box-image d-grid d-md-none'>
                            <img src={bg} alt='imagem patricia' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;