import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import { ThreeColumnCarousel3 } from '../../utils/script';
import axios from 'axios';
import _ from 'lodash';
import CourseTypeOneAcademy from '../../components/course/CourseTypeOneAcademy';
import { FaArrowRight } from "react-icons/fa";

const CourseCarouselTwo = ({title}) => {

  const [cursos, setCurso] = useState([]);

    useEffect( () => {
        axios.get('https://api.academyabroad.com.br/api/site/cursos')
            .then(response => {
                //setCurso(response.data.data);
                setCurso(_.shuffle(response.data.data));
            })
            .catch(error => {
                console.error(error);
            });
    }, [] );

    return (
      <div className="container-fluid ptb--120">
          <div className="row g-5">
              <div className="col-lg-12">
                  <div className="section-title text-center">
                      <p className="fs-3 mb-0">conheça nossos</p>
                      <h3 className="text-primary mb-0">{title}</h3>
                  </div>
                  
                  <Slider 
                      className="mt--40 mb--50 edu-slick-button slick-activation-wrapper eduvibe-course-one-carousel"
                      { ...ThreeColumnCarousel3 }
                  >
                      { 
                          cursos.map((item) => (
                              <div className="single-slick-card" key={ item.slug }>
                                  <CourseTypeOneAcademy data={item} />
                              </div>
                          ) )
                      }
                  </Slider>

                  <div className="col d-flex justify-content-center align-items-center">
                    <a href="/cursos" className="edu-btn btn-medium">Conheça todos nossos cursos        <FaArrowRight /></a>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default CourseCarouselTwo;